// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from 'grommet/components/Box';
import FormattedMessage from 'grommet/components/FormattedMessage';
import DebouncedTextInputField from './DebouncedTextInputField';
import OperatorSelect from './OperatorSelect';

export class FilterBuilderNumericValue extends PureComponent {
  constructor() {
    super();
    this.onInputChange = this.onInputChange.bind(this);
    this.onOperatorChange = this.onOperatorChange.bind(this);
  }

  componentWillMount() {
    const { onChange, operatorVal, valueVal } = this.props;
    // If the operator is empty to begin with, default it to '='.
    // Otherwise, the filter will not be able to save until the user changes the operator.
    if (operatorVal === '') {
      onChange('=', valueVal);
    }
  }

  onInputChange(name, val) {
    const { onChange, operatorVal } = this.props;
    onChange(operatorVal, val);
  }

  onOperatorChange(changedOperatorVal) {
    const { onChange, valueVal } = this.props;
    onChange(changedOperatorVal, valueVal);
  }

  render() {
    const { path, disabled, operatorVal, valueVal } = this.props;

    return (
      <Box direction="row" pad={{ between: 'small' }}>
        <OperatorSelect
          onOperatorChange={this.onOperatorChange}
          path={path}
          operatorVal={operatorVal}
          disabled={disabled}
        />
        <DebouncedTextInputField
          id={`${path}.value`}
          name="value"
          value={valueVal}
          label={<FormattedMessage id="Value" />}
          onChange={this.onInputChange}
          disabled={disabled}
        />
      </Box>
    );
  }
}

FilterBuilderNumericValue.displayName = 'FilterBuilderNumericValue';

FilterBuilderNumericValue.propTypes = {
  onChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  operatorVal: PropTypes.string.isRequired,
  valueVal: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

FilterBuilderNumericValue.defaultProps = {
  disabled: false,
};

export default FilterBuilderNumericValue;
