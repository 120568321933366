// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import bowser from 'bowser';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Anchor from 'grommet/components/Anchor';
import Box from 'grommet/components/Box';
import Button from 'grommet/components/Button';
import Footer from 'grommet/components/Footer';
import LoginForm from 'grommet/components/LoginForm';
import Split from 'grommet/components/Split';
import FormattedMessage from 'grommet/components/FormattedMessage';
import { login } from '../actions/session';
import LoginMenu from './LoginMenu';
import { INFOSIGHT_APP_ID, PASSPORT_BASE_URL, PASSPORT_REDIRECT_URL } from '../config/passport';

export class Login extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onResponsive = this.onResponsive.bind(this);
    this.learnMore = this.learnMore.bind(this);
    this.state = {
      busy: false,
      loginScreen: 'img/login/hpe-infosight-background-art-matrix-min.jpg',
      shortWarning: false,
      longWarning: false,
      columns: null,
      username: '',
    };
  }

  componentWillReceiveProps() {
    if (this.state.busy) {
      this.setState({ busy: false });
    }
  }

  onSubmit(fields) {
    this.setState({ busy: true });
    this.props.dispatch(login(fields.username, fields.password));
  }

  onFieldChange(fields) {
    const username = fields.username;

    if (typeof username === 'string') {
      if (username.indexOf('@hpe.com') !== -1 || username.indexOf('@hp.com') !== -1) {
        this.setState({ shortWarning: true, username });
      } else {
        this.setState({ shortWarning: false, longWarning: false, username });
      }
    }
  }

  onResponsive(columns) {
    this.setState({
      columns,
    });
  }

  learnMore() {
    this.setState({ longWarning: !this.state.longWarning });
  }

  render() {
    const { session, loginScreen, intl, testTimestamp } = this.props;
    const { username } = this.state;
    const passportResetLink = `${PASSPORT_BASE_URL}/forgotpwd.do?hpappid=${INFOSIGHT_APP_ID}&email=${username}&lang=en&cc=us&applandingpage=${PASSPORT_REDIRECT_URL}`;
    const passportCreateAccountLink = `${PASSPORT_BASE_URL}/createuser.do?hpappid=${INFOSIGHT_APP_ID}&email=${username}&lang=en&cc=us&applandingpage=${PASSPORT_REDIRECT_URL}`;
    const productName = 'InfoSight';
    const productSubtitle = 'InfoSight Subtitle';

    const loginError = session.error;
    const errors = [];
    if (loginError && !this.state.busy) {
      const message = loginError.message;
      errors.push(message);
      if (loginError.resolution) {
        if (loginError.link && loginError.linkText) {
          const resolution = (
            <FormattedMessage
              id={loginError.resolution}
              defaultMessage={loginError.resolution}
              values={{
                link: (
                  <a
                    href={loginError.link}
                  >
                    <FormattedMessage
                      id={loginError.linkText}
                      defaultMessage={loginError.linkText}
                    />
                  </a>
                ),
              }}
            />
          );
          errors.push(resolution);
        } else {
          const resolution = loginError.resolution;
          errors.push(resolution);
        }
      }
    }

    const titleText = (intl) ?
      intl.formatMessage({ id: productName }) : productName;
    const secondaryText = (intl) ? (<div>
      <span className="subtitle">
        {intl.formatMessage({ id: productSubtitle })}
      </span>
    </div>) : 'AI-Driven Operations for Hybrid Cloud';
    const loginScreenUrl = `url(${loginScreen || this.state.loginScreen})`;

    const forgotPassword = [
      <a
        key="forgotPassword"
        href={passportResetLink}
      >
        Forgot Password?
      </a>,
    ];

    if (this.state.longWarning) {
      forgotPassword.unshift(
        <div key="longWarning">
          <FormattedMessage id="Employee Login LDAP Warning" />
        </div>,
      );
    }

    if (this.state.shortWarning) {
      forgotPassword.unshift(
        <div className="sfrm-login-short-warning" key="shortWarning">
          <strong>
            <FormattedMessage
              id="Note to Internal Users"
              values={{
                link: (
                  <a
                    onClick={this.learnMore}
                    tabIndex={0}
                    role="button"
                  >
                    <FormattedMessage id="Learn More" />
                  </a>),
              }}
            />
          </strong>
        </div>,
      );
    }

    const emailBody = encodeURIComponent(`
  Please include the following debug information in order to make it easier and faster for the HPE InfoSight Portal Support team to help.

  URL: ${window.location.href}
  Username: ${username}
  Browser: ${bowser.name} ${bowser.version}
  Timestamp: ${testTimestamp || new Date()}

  Please fill out the below fields.

  Region (Americas/EMEA/Asia-Pacific):
  HPE Partner? (yes/no): `);

    const createAccountLinkMessage = (intl) ?
      intl.formatMessage({ id: 'Create Account' }) : 'Create Account';

    const footerComponent = (
      <Footer
        direction="row"
        size="small"
        justify="end"
        className="application-menu"
      >
        <LoginMenu />
      </Footer>);

    return (
      <Box full texture={loginScreenUrl} className="header-height-correction">
        <span />
        <Split priority="left" className="visible-overflow" onResponsive={this.onResponsive}>
          <Box align="center">
            <LoginForm
              logo={<img src={`${process.env.PUBLIC_PATH}img/hpe-infosight-logo.svg`} alt="HPE InfoSight Logo" />}
              title={titleText}
              secondaryText={secondaryText}
              onSubmit={this.state.busy ? null : this.onSubmit}
              errors={errors}
              onChange={this.onFieldChange}
              forgotPassword={forgotPassword}
              usernameType="text"
              align="center"
            />

            <Anchor className="login-screen" href={`mailto:infosightsupport@hpe.com?subject=%5BInfoSight%20Portal%20Support%5D%20Login%20Help%20Request&body=${emailBody}`}>
              <FormattedMessage id="Having problems logging in?" />
            </Anchor>

            <Button
              label={createAccountLinkMessage}
              href={passportCreateAccountLink}
              className="login-screen"
            />

            {this.state.columns === 'single' && footerComponent}
          </Box>
          <Box full="vertical" className="double-scroll-correction">
            <Box full />
            {this.state.columns !== 'single' && footerComponent}
          </Box>
        </Split>
      </Box>
    );
  }
}

Login.propTypes = {
  session: PropTypes.shape({
    error: PropTypes.shape({
      message: PropTypes.string.isRequired,
      resolution: PropTypes.string.isRequired,
    }),
  }).isRequired,
  loginScreen: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  testTimestamp: PropTypes.string,
};

Login.defaultProps = {
  loginScreen: null,
  testTimestamp: undefined,
};

const select = state => ({
  session: state.session,
});

export default connect(select)(injectIntl(Login));
