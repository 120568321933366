// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from 'grommet/components/Box';
import FormattedMessage from 'grommet/components/FormattedMessage';
import FilterBuilderFacetSelector from './FilterBuilderFacetSelector';
import DebouncedTextInputField from './DebouncedTextInputField';
import OperatorSelect from './OperatorSelect';
import FilterUtils from '../utils/FilterUtils';

export class FilterBuilderVersionRangeValue extends PureComponent {
  constructor() {
    super();
    this.onVersionRangeChange = this.onVersionRangeChange.bind(this);
    this.onOperatorChange = this.onOperatorChange.bind(this);

    this.state = { userTypedValue: '' };
  }

  componentWillMount() {
    const { valueVal } = this.props;
    // If there was a (normalized) value saved, we want to display a denormalized version of it.
    this.setState({ userTypedValue: FilterUtils.denormalizeVersionString(valueVal) });
  }

  // We want to give option of facet selector only when operator = is selected
  // or else we want it to behave as numeric version filter.
  // Also when we want to use a version range filter, we need to use normalized
  // version of the fields, and thus we are changing the dataProp with
  // corresponding normalized dataProp. This is all metadata driven.
  onOperatorChange(changedOperatorVal) {
    const { onFilterChange, columnMeta, valueVal, currentDataProp } = this.props;

    const newDataProp = (changedOperatorVal === '=' ? columnMeta.dataProp : columnMeta.versionRangeDataProp);

    // clear value (also in state) if we are switching between facet filter and numeric value filter
    const newValue = (newDataProp === currentDataProp ? valueVal : '');
    this.setState({ userTypedValue: FilterUtils.denormalizeVersionString(newValue) });

    onFilterChange(newDataProp, changedOperatorVal, newValue);
  }

  // In version range we want to normalize the version value so that we can do a proper comparison.
  // However, we don't want to have to normalize/denormalize from props on re-render, and change
  // the input value while the user types, so we keep the typed value in local state.
  onVersionRangeChange(name, value) {
    const { operatorVal, onValueChange } = this.props;
    this.setState({ userTypedValue: value });
    onValueChange(operatorVal, FilterUtils.normalizeVersionString(value));
  }

  render() {
    const { path, disabled, operatorVal, valueVal, facetValues, onValueChange } = this.props;
    const { userTypedValue } = this.state;

    let valueComponent;

    if (operatorVal === '=') {
      valueComponent = (
        <FilterBuilderFacetSelector
          path={path}
          onChange={onValueChange}
          filterValue={valueVal}
          facetValues={facetValues}
          disabled={disabled}
        />
      );
    } else {
      // For comparison purposes, we need normalized values, so in onVersionRangeChange we do that.
      // but while displaying to user, we don't want it to be confusing to have value change on
      // each keystroke, normalized or not. So we use the userTypedValue from state when rendering.
      valueComponent = (
        <DebouncedTextInputField
          id={`${path}.value`}
          name="value"
          value={userTypedValue}
          label={<FormattedMessage id="Value" />}
          onChange={this.onVersionRangeChange}
          disabled={disabled}
        />
      );
    }

    return (
      <Box direction="row" pad={{ between: 'small' }}>
        <OperatorSelect
          onOperatorChange={this.onOperatorChange}
          path={path}
          operatorVal={operatorVal}
          disabled={disabled}
        />
        <Box justify="center" flex>
          {valueComponent}
        </Box>
      </Box>
    );
  }
}

FilterBuilderVersionRangeValue.displayName = 'FilterBuilderVersionRangeValue';

FilterBuilderVersionRangeValue.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  facetValues: PropTypes.arrayOf(PropTypes.shape({
    term: PropTypes.string,
    count: PropTypes.number,
  })),
  columnMeta: PropTypes.object.isRequired,
  currentDataProp: PropTypes.string.isRequired,
  operatorVal: PropTypes.string.isRequired,
  valueVal: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

FilterBuilderVersionRangeValue.defaultProps = {
  facetValues: [],
  disabled: false,
};

export default FilterBuilderVersionRangeValue;
