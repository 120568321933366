// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import 'core-js/shim';

import '../scss/index.scss';

import InfoSight from './components/InfoSight';
import DevTools from './components/DevTools';
import store from './store';
import history from './history';
import { init as sessionInit } from './actions/session';
import localeData from './localeData';

// jquery-can pulled in by oculus-grommet index page script tag is
// hooking into history api and
// inserting hash-bang into hash-based routes.
// Turn the listener off since it's not being used anyway.
if (window.can && window.can.route) {
  window.can.route.unbind();
}

// Get session immediately to start network call
store.dispatch(sessionInit());

const element = document.getElementById('body');

ReactDOM.render((
  <div>
    <Provider store={store}>
      <IntlProvider locale={localeData.locale} messages={localeData.messages} defaultLocale="en-US">
        <Router history={history}>
          <InfoSight />
        </Router>
      </IntlProvider>
    </Provider>
    <Provider store={store}>
      <IntlProvider locale={localeData.locale} messages={localeData.messages}>
        <DevTools />
      </IntlProvider>
    </Provider>
  </div>
), element);

document.body.classList.remove('loading');
