// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

/*
 * The metadata file containing all the important info about systems view columns
 */
import React from 'react';
import FlagIcon from 'grommet/components/icons/base/Flag';
import ScoreIcon from 'grommet/components/icons/base/Scorecard';

import ConfigUtils from '../utils/ConfigUtils';
import SystemsRenderer from '../utils/SystemsRenderer';
import ValueRenderer from '../utils/ValueRenderer';
import * as actions from '../actions/systemDetails';

export const initialOrder = [
  'wellnessScore',
  'opportunityScore',
  'serialNumber',
  'companyName',
  'systemName',
  'freeCapacityPercent',
];

// These properties will each be applied to one field of the uiMetadata based on the user's roles.
export const roleBasedProps = {
  fatRowPrimary: {
    value: true,
    defaultField: 'companyName',
    roleBased: [{
      roles: ['customer', 'partner'],
      field: 'systemName',
    }],
  },
};

// exported for testing, should never be used directly otherwise
export const uiMetadata = {
  lastUpdated: {
    title: 'Last Updated',
    render: SystemsRenderer.renderDate,
    dataProp: 'updated',
    overviewSubtitle: true,
  },

  wellnessScore: {
    title: 'Wellness Score',
    header: <ScoreIcon colorIndex="#ff8d6d" />,
    render: SystemsRenderer.renderWellnessScoreIcon,
    dataProp: 'status.score',
    relatedData: ['status.level'],
    showInMobile: true,
  },

  performanceScore: {
    title: 'Performance Score',
    dataProp: 'status.scoreByCategory.performance',
  },

  oversubscriptionScore: {
    title: 'Oversubscription Score',
    dataProp: 'status.scoreByCategory.oversubscription',
  },

  capacityScore: {
    title: 'Capacity Score',
    dataProp: 'status.scoreByCategory.capacity',
  },

  softwareScore: {
    title: 'Software Score',
    dataProp: 'status.scoreByCategory.software',
  },

  deviceAlertsScore: {
    title: 'Device Alerts Score',
    dataProp: 'status.scoreByCategory.deviceAlerts',
  },

  supportScore: {
    title: 'Support Score',
    dataProp: 'status.scoreByCategory.support',
  },

  wellnessTypes: {
    title: 'Wellness Types',
    dataProp: 'status.statusTypes',
    renderFacetValue: ValueRenderer.renderSingleStatusType,
  },

  opportunityScore: {
    title: 'Opportunity Score',
    header: <FlagIcon />,
    dataProp: 'sales.prospect.score',
    relatedData: ['status.level'],
    render: SystemsRenderer.renderStoreServOpportunityScoreIcon,
  },

  campaign: {
    title: 'Campaign',
    dataProp: 'sales.prospect.campaign',
  },

  serialNumber: {
    title: 'HPE Serial Number',
    dataProp: 'serialNumberHP',
    relatedData: ['serialNumberInserv'],
    render: SystemsRenderer.renderStoreServSerialNumber,
    fatRowSecondary: true,
    excludeFromFacets: true,
  },

  // NOTE: companyName is the default recipient of the property fatRowPrimary: true.
  // See roleBasedProps at the top
  companyName: {
    title: 'Company Name',
    dataProp: 'system.companyName',
    excludeFromFacets: true,
  },

  // NOTE: systemName is a potential recipient of the property fatRowPrimary: true.
  // See roleBasedProps at the top
  systemName: {
    title: 'System Name',
    dataProp: 'system.systemName',
    excludeFromFacets: true,
  },

  model: {
    title: 'Model',
    dataProp: 'system.model',

  },

  familyName: {
    title: 'Family Name',
    dataProp: 'system.productFamily',
  },

  productSKU: {
    title: 'Product SKU',
    dataProp: 'system.productSKU',
  },

  region: {
    title: 'Region',
    dataProp: 'system.location.region',
  },

  country: {
    title: 'Country',
    dataProp: 'system.location.country',
    relatedData: ['system.location.city', 'system.location.state', 'system.location.region'],
    render: SystemsRenderer.renderStoreServLocation,
  },

  state: {
    title: 'State',
    dataProp: 'system.location.state',
  },

  city: {
    title: 'City',
    dataProp: 'system.location.city',
  },

  contactLastName: {
    title: 'Contact Last Name',
    dataProp: 'customer.contactInfo.contactLastName',
    excludeFromFacets: true,
  },

  contactFirstName: {
    title: 'Contact First Name',
    dataProp: 'customer.contactInfo.contactFirstName',
    excludeFromFacets: true,
  },

  contactCompany: {
    title: 'Contact Company',
    dataProp: 'customer.contactInfo.company',
    excludeFromFacets: true,
  },

  contactPhone: {
    title: 'Contact Phone Number',
    dataProp: 'customer.contactInfo.phone',
    excludeFromFacets: true,
  },

  contactEmail: {
    title: 'Contact Email Address',
    dataProp: 'customer.contactInfo.email',
    excludeFromFacets: true,
  },

  contactFax: {
    title: 'Contact Fax Number',
    dataProp: 'customer.contactInfo.fax',
    excludeFromFacets: true,
  },

  contactStreet: {
    title: 'Contact Street',
    dataProp: 'customer.contactInfo.street',
    excludeFromFacets: true,
  },

  contactCity: {
    title: 'Contact City',
    dataProp: 'customer.contactInfo.city',
    excludeFromFacets: true,
  },

  contactState: {
    title: 'Contact State',
    dataProp: 'customer.contactInfo.state',
    excludeFromFacets: true,
  },

  contactPostalCode: {
    title: 'Contact Postal Code',
    dataProp: 'customer.contactInfo.postalCode',
    excludeFromFacets: true,
  },

  contactCountry: {
    title: 'Contact Country Code',
    dataProp: 'customer.contactInfo.countryCode',
    excludeFromFacets: true,
  },

  siteStatus: {
    title: 'Site Status',
    dataProp: 'customer.siteStatus',
  },

  installDate: {
    title: 'Install Date',
    dataProp: 'system.installDate',
    render: SystemsRenderer.renderDate,

  },

  // Need to implement Accordion when Supported By Partner is 'YES' to display the email Ids
  supportedByPartner: {
    title: 'Supported By Partner',
    dataProp: 'authorized.hasPartners',
    render: SystemsRenderer.renderBooleanAsYesNo,

  },

  partnersEmailList: {
    title: 'Partner Email Addresses',
    dataProp: 'authorized.partnerEmailIds',
  },

  registeredSystems: {
    title: 'Registered System',
    dataProp: 'authorized.tokenCount',
    relatedData: ['authorized.tokens'],
    render: SystemsRenderer.renderSystemsPerToken,
    dynamicActionCreator: actions.dynamicallyLoadSystemsPerToken,
  },

  tenants: {
    title: 'System Group',
    dataProp: 'authorized.tenants',
    excludeFromFacets: true,
  },

  fullCapacityPredictedDate: {
    title: 'Total Capacity Predicted Full',
    dataProp: 'capacity.total.freePctZeroPredictedDate',
    render: SystemsRenderer.renderMonthAndYear,
  },

  fcFullCapacityPredictedDate: {
    title: 'Total FC Capacity Predicted Full',
    dataProp: 'capacity.byType.fc.freePctZeroPredictedDate',
  },

  nlFullCapacityPredictedDate: {
    title: 'Total NL Capacity Predicted Full',
    dataProp: 'capacity.byType.nl.freePctZeroPredictedDate',
  },

  ssdFullCapacityPredictedDate: {
    title: 'Total SSD Capacity Predicted Full',
    dataProp: 'capacity.byType.ssd.freePctZeroPredictedDate',
  },

  aoEnabled: {
    title: 'Adaptive Optimization',
    dataProp: 'cpgs.aoEnabled',
    render: SystemsRenderer.renderAdaptiveOptimization,

  },

  minimumSsdLifeLeftPercent: {
    title: 'SSD Minimum % Life Left',
    dataProp: 'capacity.byType.ssd.lifeLeftPctMin',
    render: SystemsRenderer.renderMinimumSsdLifeLeftPercent,
  },

  osVersion: {
    title: 'OS Version',
    dataProp: 'system.osVersion',
    versionRangeDataProp: 'system.osVersionNormalized',
  },

  patches: {
    title: 'Patches',
    dataProp: 'system.patches',
    render: SystemsRenderer.renderCommaSeparatedStrings,
  },

  spId: {
    title: 'SP ID',
    dataProp: 'system.sp.spId',
    excludeFromFacets: true,
  },

  spVersion: {
    title: 'SP Version',
    dataProp: 'system.sp.spVersion',
    versionRangeDataProp: 'system.sp.spVersionNormalized',
  },

  spPatches: {
    title: 'SP Patch',
    dataProp: 'system.sp.spPatches',
  },

  spInstallVersion: {
    title: 'SP Install Version',
    dataProp: 'system.sp.spInstallVersion',
    versionRangeDataProp: 'system.sp.spInstallVersionNormalized',
  },

  spModel: {
    title: 'SP Model',
    dataProp: 'system.sp.spModel',
  },

  freeCapacityPercent: {
    title: 'Free Capacity %',
    dataProp: 'capacity.total.freePct',
    render: SystemsRenderer.renderStoreServFreeCapacityPerct,
  },

  fcFreeCapacityPercent: {
    title: 'FC Free Capacity %',
    dataProp: 'capacity.byType.fc.freePct',
  },

  nlFreeCapacityPercent: {
    title: 'NL Free Capacity %',
    dataProp: 'capacity.byType.nl.freePct',
  },

  ssdFreeCapacityPercent: {
    title: 'SSD Free Capacity %',
    dataProp: 'capacity.byType.ssd.freePct',
  },

  freeCapacity: {
    title: 'Free Capacity',
    dataProp: 'capacity.total.freeTiB',
  },

  fcFreeCapacity: {
    title: 'FC Free Capacity',
    dataProp: 'capacity.byType.fc.freeTiB',
  },

  nlFreeCapacity: {
    title: 'NL Free Capacity',
    dataProp: 'capacity.byType.nl.freeTiB',
  },

  ssdFreeCapacity: {
    title: 'SSD Free Capacity',
    dataProp: 'capacity.byType.ssd.freeTiB',
  },

  totalCapacity: {
    title: 'Total Capacity',
    dataProp: 'capacity.total.sizeTiB',
  },

  fcTotalCapacity: {
    title: 'FC Total Capacity',
    dataProp: 'capacity.byType.fc.sizeTiB',
  },

  nlTotalCapacity: {
    title: 'NL Total Capacity',
    dataProp: 'capacity.byType.nl.sizeTiB',
  },

  ssdTotalCapacity: {
    title: 'SSD Total Capacity',
    dataProp: 'capacity.byType.ssd.sizeTiB',
  },

  allocatedCapacity: {
    title: 'Total Allocated Capacity',
    dataProp: 'capacity.total.allocatedCapacityTiB',
  },

  allocatedSystemCapacity: {
    title: 'Allocated System Capacity',
    dataProp: 'capacity.total.systemAllocatedTiB',
  },

  allocatedVolumesCapacity: {
    title: 'Allocated Volumes Capacity',
    dataProp: 'capacity.total.volumesAllocatedTiB',
  },

  allocatedOtherCapacity: {
    title: 'Other Allocated Capacity',
    dataProp: 'capacity.total.otherAllocatedTiB',
  },

  failedCapacity: {
    title: 'Failed Capacity',
    dataProp: 'capacity.total.failedCapacityTiB',
  },

  capacityEfficiency: {
    title: 'Compaction Ratio',
    dataProp: 'capacity.total.compactionRatio',
  },

  compressionRatio: {
    title: 'Compression Ratio',
    dataProp: 'capacity.total.compressionRatio',
  },

  deduplicationRatio: {
    title: 'Dedupe Ratio',
    dataProp: 'capacity.total.dedupeRatio',
    render: SystemsRenderer.renderStoreServDedupeRatio,
  },

  overprovisioningRatio: {
    title: 'Overprovisioning Ratio',
    dataProp: 'capacity.total.overprovisioningRatio',
  },

  cpgs: {
    title: 'CPGs',
    dataProp: 'cpgs.cpgCount',

  },

  fcRaidLevels: {
    title: 'FC RAID Levels',
    dataProp: 'cpgs.byType.fc.raidLevels',
    renderFacetValue: ValueRenderer.renderRaidLevel,
  },

  nlRaidLevels: {
    title: 'NL RAID Levels',
    dataProp: 'cpgs.byType.nl.raidLevels',
    renderFacetValue: ValueRenderer.renderRaidLevel,
  },

  ssdRaidLevels: {
    title: 'SSD RAID Levels',
    dataProp: 'cpgs.byType.ssd.raidLevels',
    renderFacetValue: ValueRenderer.renderRaidLevel,
  },

  // TODO - Write render function to do node formatting for details pane.
  nodes: {
    title: 'Nodes',
    dataProp: 'nodes.nodeCount',
  },

  offlineNodes: {
    title: 'Offline Nodes',
    dataProp: 'nodes.nodeCountOffline',
  },

  nodeTimeSkew: {
    title: 'Node Time Skew',
    dataProp: 'nodes.nodeTimeSkewSecs',
  },

  cages: {
    title: 'Cages',
    dataProp: 'nodes.cageCount',

  },

  cpuAvgMax: {
    title: 'Average CPU',
    dataProp: 'nodes.cpuAvgMax',
  },

  cpuMedianMax: {
    title: 'Median CPU',
    dataProp: 'nodes.cpuMedianMax',
  },

  batteryExpiry: {
    title: 'Battery Expiry',
    dataProp: 'nodes.batteryExpiry',
  },

  domains: {
    title: 'Domains',
    dataProp: 'domains.domainCount',
  },

  disks: {
    title: 'Disks',
    dataProp: 'disks.total.diskCount',
  },

  disksNormal: {
    title: 'Normal Disks',
    dataProp: 'disks.total.diskCountNormal',
  },

  disksDegraded: {
    title: 'Degraded Disks',
    dataProp: 'disks.total.diskCountDegraded',
  },

  disksFailed: {
    title: 'Failed Disks',
    dataProp: 'disks.total.diskCountFailed',
  },

  ssdDisks: {
    title: 'SSD Disks',
    dataProp: 'disks.byType.ssd.diskCount',
  },

  ssdDisksNormal: {
    title: 'Normal SSD Disks',
    dataProp: 'disks.byType.ssd.diskCountNormal',
  },

  ssdDisksDegraded: {
    title: 'Degraded SSD Disks',
    dataProp: 'disks.byType.ssd.diskCountDegraded',
  },

  ssdDisksFailed: {
    title: 'Failed SSD Disks',
    dataProp: 'disks.byType.ssd.diskCountFailed',
  },

  fcDisks: {
    title: 'FC Disks',
    dataProp: 'disks.byType.fc.diskCount',
  },

  fcDisksNormal: {
    title: 'Normal FC Disks',
    dataProp: 'disks.byType.fc.diskCountNormal',
  },

  fcDisksDegraded: {
    title: 'Degraded FC Disks',
    dataProp: 'disks.byType.fc.diskCountDegraded',
  },

  fcDisksFailed: {
    title: 'Failed FC Disks',
    dataProp: 'disks.byType.fc.diskCountFailed',
  },

  nlDisks: {
    title: 'NL Disks',
    dataProp: 'disks.byType.nl.diskCount',
  },

  nlDisksNormal: {
    title: 'Normal NL Disks',
    dataProp: 'disks.byType.nl.diskCountNormal',
  },

  nlDisksDegraded: {
    title: 'Degraded NL Disks',
    dataProp: 'disks.byType.nl.diskCountDegraded',
  },

  nlDisksFailed: {
    title: 'Failed NL Disks',
    dataProp: 'disks.byType.nl.diskCountFailed',
  },

  fcDiskCountBalancedPct: {
    title: 'FC Disk Count Balance by Nodes',
    dataProp: 'disks.byType.fc.diskCountBalancedPct',
  },

  nlDiskCountBalancedPct: {
    title: 'NL Disk Count Balance by Nodes',
    dataProp: 'disks.byType.nl.diskCountBalancedPct',
  },

  ssdDiskCountBalancedPct: {
    title: 'SSD Disk Count Balance by Nodes',
    dataProp: 'disks.byType.ssd.diskCountBalancedPct',
  },

  fcUsedBalancedPct: {
    title: 'FC Used Balance by Nodes',
    dataProp: 'capacity.byType.fc.usedBalancedPct',
  },

  nlUsedBalancedPct: {
    title: 'NL Used Balance by Nodes',
    dataProp: 'capacity.byType.nl.usedBalancedPct',
  },

  ssdUsedBalancedPct: {
    title: 'SSD Used Balance by Nodes',
    dataProp: 'capacity.byType.ssd.usedBalancedPct',
  },

  fcSizeBalancedPct: {
    title: 'FC Capacity Balance by Nodes',
    dataProp: 'capacity.byType.fc.sizeBalancedPct',
  },

  nlSizeBalancedPct: {
    title: 'NL Capacity Balance by Nodes',
    dataProp: 'capacity.byType.nl.sizeBalancedPct',
  },

  ssdSizeBalancedPct: {
    title: 'SSD Capacity Balance by Nodes',
    dataProp: 'capacity.byType.ssd.sizeBalancedPct',
  },

  diskTypes: {
    title: 'Disk Types',
    dataProp: 'disks.diskModels.type',
    renderFacetValue: ValueRenderer.uppercase,
  },

  diskManufacturers: {
    title: 'Disk Manufacturers',
    dataProp: 'disks.diskModels.manufacturer',
  },

  diskModels: {
    title: 'Disk Models',
    dataProp: 'disks.diskModels.model',
  },

  diskDescriptions: {
    title: 'Disk Descriptions',
    dataProp: 'disks.diskModels.description',
  },

  vluns: {
    title: 'Vluns',
    dataProp: 'vluns.vlunCount',
    relatedData: ['vluns.activeVlunCount'],
    render: SystemsRenderer.renderStoreServVlunCount,
  },

  activeVlunCount: {
    title: 'Active VLUNs',
    dataProp: 'vluns.activeVlunCount',
  },

  csiStatus: {
    title: 'SmartStart Status',
    dataProp: 'system.sp.ssswStatus',
    render: SystemsRenderer.renderSmartStartStatus,

  },

  entitlementEndDate: {
    title: 'Entitlement Expiration Date',
    dataProp: 'customer.entitlement.entitlementEndDate',
    render: SystemsRenderer.renderDate,
  },

  entitlementStatus: {
    title: 'Entitlement:',
    dataProp: 'customer.entitlement.activeContractEntitlement',
  },

  contractStartDate: {
    title: 'Contract Start',
    dataProp: 'customer.entitlement.contractStartDate',
  },

  contractEndDate: {
    title: 'Contract Expiry',
    dataProp: 'customer.entitlement.contractEndDate',
  },

  warrantyStartDate: {
    title: 'Warranty Start',
    dataProp: 'customer.entitlement.warrantyStartDate',
  },

  warrantyEndDate: {
    title: 'Warranty Expiry',
    dataProp: 'customer.entitlement.warrantyEndDate',
  },

  topOfferPriority: {
    title: 'Entitlement Level',
    dataProp: 'customer.entitlement.topOfferPriority',
  },

  topOfferCode: {
    title: 'Entitlement Code',
    dataProp: 'customer.entitlement.topOfferCode',
  },

  topOfferDescription: {
    title: 'Entitlement Description',
    dataProp: 'customer.entitlement.topOfferDescription',
  },

  svcAgreementID: {
    title: 'SAID',
    dataProp: 'customer.entitlement.svcAgreementID',
    excludeFromFacets: true,
  },

  disksState: {
    title: 'Disks State',
    dataProp: 'disks.state',
  },

  initiatorCount: {
    title: 'Total Initiators',
    dataProp: 'initiators.total.initiatorCount',
  },

  fcInitiatorCount: {
    title: 'FC Initiators',
    dataProp: 'initiators.byType.fc.initiatorCount',
  },

  fcoeInitiatorCount: {
    title: 'FCoE Initiators',
    dataProp: 'initiators.byType.fcoe.initiatorCount',
  },

  iscsiInitiatorCount: {
    title: 'iSCSI Initiators',
    dataProp: 'initiators.byType.iscsi.initiatorCount',
  },

  initiatorCountBalancedPercent: {
    title: 'Initiator Count Balance by Nodes',
    dataProp: 'initiators.total.initiatorCountBalancedPct',
  },

  bandwidthAvg: {
    title: 'Average Total Bandwidth',
    dataProp: 'performance.portBandwidthData.total.dataRateKBPSAvg',
  },

  bandwidthMax: {
    title: 'Maximum Total Bandwidth',
    dataProp: 'performance.portBandwidthData.total.dataRateKBPSMax',
  },

  iopsAvg: {
    title: 'Average IOPS',
    dataProp: 'performance.portBandwidthData.total.iopsAvg',
  },

  iopsMax: {
    title: 'Maximum IOPS',
    dataProp: 'performance.portBandwidthData.total.iopsMax',
  },

  totSvcMax: {
    title: 'Maximum Total Service Time',
    dataProp: 'performance.portBandwidthData.total.serviceTimeMSMax',
  },

  totSvcAvg: {
    title: 'Average Total Service Time',
    dataProp: 'performance.summary.portInfo.totalServiceTimeColMillis',
  },

  readSvcMax: {
    title: 'Maximum Read Service Time',
    dataProp: 'performance.portBandwidthData.read.serviceTimeMSMax',
  },

  readSvcAvg: {
    title: 'Average Read Service Time',
    dataProp: 'performance.summary.portInfo.readServiceTimeColMillis',
  },

  writeSvcMax: {
    title: 'Maximum Write Service Time',
    dataProp: 'performance.portBandwidthData.write.serviceTimeMSMax',
  },

  writeSvcAvg: {
    title: 'Average Write Service Time',
    dataProp: 'performance.summary.portInfo.writeServiceTimeColMillis',
  },

  fcDisksLowWaterPercent: {
    title: 'Low Water FC Disks Percent',
    dataProp: 'performance.disk.byType.fc.overloadedDiskPct',
  },

  fcDisksHighWaterPercent: {
    title: 'High Water FC Disks Percent',
    dataProp: 'performance.disk.byType.fc.overloadedDiskHighThresholdPct',
  },

  nlDisksLowWaterPercent: {
    title: 'Low Water NL Disks Percent',
    dataProp: 'performance.disk.byType.nl.overloadedGt75DiskPct',
  },

  nlDisksHighWaterPercent: {
    title: 'High Water NL Disks Percent',
    dataProp: 'performance.disk.byType.nl.overloadedGt120DiskPct',
  },

  delAckPercentRollingAvg7Day: {
    title: 'Del Ack %',
    dataProp: 'performance.summary.delAckPctRollingAvg7Day',
  },

  nlReadBandwidthMBPSRollingAvg7Day: {
    title: 'NL Read Bandwidth',
    dataProp: 'performance.summary.vvInfo.vvsByType.nl.readBandwidthMBPSRollingAvg7Day',
  },

  fcReadBandwidthMBPSRollingAvg7Day: {
    title: 'FC Read Bandwidth',
    dataProp: 'performance.summary.vvInfo.vvsByType.fc.readBandwidthMBPSRollingAvg7Day',
  },

  ssdReadBandwidthMBPSRollingAvg7Day: {
    title: 'SSD Read Bandwidth',
    dataProp: 'performance.summary.vvInfo.vvsByType.ssd.readBandwidthMBPSRollingAvg7Day',
  },

  nlWriteBandwidthMBPSRollingAvg7Day: {
    title: 'NL Write Bandwidth',
    dataProp: 'performance.summary.vvInfo.vvsByType.nl.writeBandwidthMBPSRollingAvg7Day',
  },

  fcWriteBandwidthMBPSRollingAvg7Day: {
    title: 'FC Write Bandwidth',
    dataProp: 'performance.summary.vvInfo.vvsByType.fc.writeBandwidthMBPSRollingAvg7Day',
  },

  ssdWriteBandwidthMBPSRollingAvg7Day: {
    title: 'SSD Write Bandwidth',
    dataProp: 'performance.summary.vvInfo.vvsByType.ssd.writeBandwidthMBPSRollingAvg7Day',
  },

  nlReadServiceTimeMillisRollingAvg7Day: {
    title: 'NL Read Service Time',
    dataProp: 'performance.summary.vvInfo.vvsByType.nl.readServiceTimeMillisRollingAvg7Day',
  },

  fcReadServiceTimeMillisRollingAvg7Day: {
    title: 'FC Read Service Time',
    dataProp: 'performance.summary.vvInfo.vvsByType.fc.readServiceTimeMillisRollingAvg7Day',
  },

  ssdReadServiceTimeMillisRollingAvg7Day: {
    title: 'SSD Read Service Time',
    dataProp: 'performance.summary.vvInfo.vvsByType.ssd.readServiceTimeMillisRollingAvg7Day',
  },

  nlWriteServiceTimeMillisRollingAvg7Day: {
    title: 'NL Write Service Time',
    dataProp: 'performance.summary.vvInfo.vvsByType.nl.writeServiceTimeMillisRollingAvg7Day',
  },

  fcWriteServiceTimeMillisRollingAvg7Day: {
    title: 'FC Write Service Time',
    dataProp: 'performance.summary.vvInfo.vvsByType.fc.writeServiceTimeMillisRollingAvg7Day',
  },

  ssdWriteServiceTimeMillisRollingAvg7Day: {
    title: 'SSD Write Service Time',
    dataProp: 'performance.summary.vvInfo.vvsByType.ssd.writeServiceTimeMillisRollingAvg7Day',
  },

  num16sWrites7Days: {
    title: 'Recent 16 Second Writes',
    dataProp: 'performance.summary.num16sWrites7Days',
  },

  alertsFatalNew: {
    title: 'New Fatal Alerts',
    dataProp: 'system.alertSummary.severity.fatalByType.newCount',
  },

  alertsCriticalNew: {
    title: 'New Critical Alerts',
    dataProp: 'system.alertSummary.severity.criticalByType.newCount',
  },

  alertsMajorNew: {
    title: 'New Major Alerts',
    dataProp: 'system.alertSummary.severity.majorByType.newCount',
  },

  alertsMinorNew: {
    title: 'New Minor Alerts',
    dataProp: 'system.alertSummary.severity.minorByType.newCount',
  },

  alertsDegradedNew: {
    title: 'New Degraded Alerts',
    dataProp: 'system.alertSummary.severity.degradedByType.newCount',
  },

  alertsInformationalNew: {
    title: 'New Informational Alerts',
    dataProp: 'system.alertSummary.severity.informationalByType.newCount',
  },

  recommendedOsVersion: {
    title: 'Recommended OS Version',
    dataProp: 'system.recommended.osVersion',
  },

  recommendedCriticalPatches: {
    title: 'Recommended Critical Patches',
    dataProp: 'system.recommended.criticalPatches',
  },

  recommendedConditionalPatches: {
    title: 'Recommended Conditional Patches',
    dataProp: 'system.recommended.conditionalPatches',
  },

  recommendedSpVersion: {
    title: 'Recommended SP Version',
    dataProp: 'system.recommended.spVersion',
  },

  recommendedSpPatches: {
    title: 'Recommended SP Patches',
    dataProp: 'system.recommended.spPatches',
  },

  vvCount: {
    title: 'Virtual Volumes',
    dataProp: 'virtualCapacity.total.vvCount',
  },

  tpvvCount: {
    title: 'TPVV Count',
    dataProp: 'virtualCapacity.byType.tpvv.vvCount',
  },

  fullCount: {
    title: 'Full Count',
    dataProp: 'virtualCapacity.byType.full.vvCount',
  },

  tdvvCount: {
    title: 'TDVV Count',
    dataProp: 'virtualCapacity.byType.tdvv.vvCount',
  },
  tdvvVersions: {
    title: 'TDVV Versions',
    dataProp: 'cpgs.tdvvVersions',
    forceAsFacetField: true,
  },

  cpvvCount: {
    title: 'CPVV Count',
    dataProp: 'virtualCapacity.byType.cpvv.vvCount',
  },

  tpsdCount: {
    title: 'TPSD Count',
    dataProp: 'virtualCapacity.byType.tpsd.vvCount',
  },

  peerCount: {
    title: 'Peer Persist Count',
    dataProp: 'virtualCapacity.byType.peer.vvCount',
  },

  snpCount: {
    title: 'Snapshot Count',
    dataProp: 'virtualCapacity.byType.snp.vvCount',
  },

  totalVirtualSize: {
    title: 'Total Virtual Size',
    dataProp: 'virtualCapacity.total.sizeTiB',
  },

  tpvvSize: {
    title: 'TPVV Size',
    dataProp: 'virtualCapacity.byType.tpvv.sizeTiB',
  },

  fullSize: {
    title: 'Full Size',
    dataProp: 'virtualCapacity.byType.full.sizeTiB',
  },

  tdvvSize: {
    title: 'TDVV Size',
    dataProp: 'virtualCapacity.byType.tdvv.sizeTiB',
  },

  cpvvSize: {
    title: 'CPVV Size',
    dataProp: 'virtualCapacity.byType.cpvv.sizeTiB',
  },

  tpsdSize: {
    title: 'TPSD Size',
    dataProp: 'virtualCapacity.byType.tpsd.sizeTiB',
  },

  peerSize: {
    title: 'Peer Persist Size',
    dataProp: 'virtualCapacity.byType.peer.sizeTiB',
  },

  snpSize: {
    title: 'Snapshot Size',
    dataProp: 'virtualCapacity.byType.snp.sizeTiB',
  },

  rcopyTargetCount: {
    title: 'Remote Copy Target Count',
    dataProp: 'services.remoteCopy.targetCount',
  },

  rcopyGroupCount: {
    title: 'Remote Copy Group Count',
    dataProp: 'services.remoteCopy.groupCount',
  },

  rcopyStatus: {
    title: 'Remote Copy Status',
    dataProp: 'services.remoteCopy.status',
  },

  filePersonaNodeCount: {
    title: 'File Services Nodes',
    dataProp: 'services.filePersona.nodeCount',
  },

  isJoinedToAD: {
    title: 'Joined to AD',
    dataProp: 'services.filePersona.isJoinedToAD',
  },

  isJoinedToLDAP: {
    title: 'Joined to LDAP',
    dataProp: 'services.filePersona.isJoinedToLDAP',
  },

  isRFC2307Enabled: {
    title: 'RFC2307',
    dataProp: 'services.filePersona.isRFC2307Enabled',
  },

  isNDMPStarted: {
    title: 'NDMP',
    dataProp: 'services.filePersona.isNDMPStarted',
  },

  hasFPReplication: {
    title: 'Has FP Replication',
    dataProp: 'services.filePersona.hasFPReplication',
  },

  isCrossProtocol: {
    title: 'Cross Protocol',
    dataProp: 'services.filePersona.isCrossProtocol',
  },

  authenticationOrder: {
    title: 'Authentication Order',
    dataProp: 'services.filePersona.authenticationOrder',
  },

  avVendor: {
    title: 'Anti-virus Vendor',
    dataProp: 'services.filePersona.avVendor',
  },

  fpgCount: {
    title: 'FPGs',
    dataProp: 'services.filePersona.fpgCount',
  },

  vfsCount: {
    title: 'VFSs',
    dataProp: 'services.filePersona.vfsCount',
  },

  fStoreCount: {
    title: 'FStores',
    dataProp: 'services.filePersona.fStoreCount',
  },

  shareCountSMB: {
    title: 'SMB Shares',
    dataProp: 'services.filePersona.shareCountSMB',
  },

  shareCountNFS: {
    title: 'NFS Shares',
    dataProp: 'services.filePersona.shareCountNFS',
  },

  shareCountObj: {
    title: 'OBJ Shares',
    dataProp: 'services.filePersona.shareCountObj',
  },

  snapCount: {
    title: 'Snaps',
    dataProp: 'services.filePersona.snapCount',
  },

  quotaCount: {
    title: 'Quotas',
    dataProp: 'services.filePersona.quotaCount',
  },

  licensedCapacity: {
    title: 'Licensed Capacity',
    dataProp: 'services.filePersona.licensedCapacityTiB',
  },

  fpgCapacity: {
    title: 'FPG Capacity',
    dataProp: 'services.filePersona.fpgSizeTiB',
  },

  availableFPGCapacity: {
    title: 'Available FPG Capacity',
    dataProp: 'services.filePersona.fpgFreeTiB',
  },

  usedFPGCapacity: {
    title: 'Used FPG Capacity',
    dataProp: 'services.filePersona.fpgUsedTiB',
  },
};

function storeServSystems(order = [], roles, meta) {
  const restrictedOrder = ConfigUtils.roleRestrictOrder(order, roles, uiMetadata, meta);
  const processedMetadata = ConfigUtils.processMetadata(roles, uiMetadata, meta, roleBasedProps);
  return {
    order: restrictedOrder,
    metadata: processedMetadata,
  };
}

export default storeServSystems;
