// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Article from 'grommet/components/Article';
import Table from 'grommet/components/Table';
import TableRow from 'grommet/components/TableRow';
import MDTableHeader from './MDTableHeader';
import MDTableSectionHeading from './MDTableSectionHeading';
import MDFatRow from './MDFatRow';

import AppUtils from '../utils/AppUtils';

export class MDTable extends Component {
  constructor() {
    super();
    this.onSelect = this.onSelect.bind(this);
    this.getRowClickHandler = this.getRowClickHandler.bind(this);
  }

  onSelect(item) {
    const { category, history } = this.props;
    history.push(`/${category}/systems/${item.id}`);
  }

  getRowClickHandler(item) {
    const { selectionDisabled } = this.props;
    if (selectionDisabled) return null;
    return () => { this.onSelect(item); };
  }

  render() {
    const { data, order, columns, sections, selectionDisabled } = this.props;

    let tableHeader = null;
    const shownColumns = order;
    const mobile = false;

    // If this MDTable has sections, break the data down by which section each member belongs in.
    let dataSections = [{ members: data.members }];
    if (sections) {
      // Give each dataSection an empty members array to be populated
      dataSections = sections.map(section => ({ ...section, members: [] }));
      // Find the default section that will be used if a member matches no sections
      const defaultSectionIndex = sections.findIndex(section => section.default);
      data.members.forEach((member) => {
        const sectionIndex = sections.findIndex(
          section => section.test && section.test(member));
        if (sectionIndex !== -1) {
          // This member belongs in the first section that tested it positively
          dataSections[sectionIndex].members.push(member);
        } else if (defaultSectionIndex !== -1) {
          // This member needs to go to the default section
          dataSections[defaultSectionIndex].members.push(member);
        }
      });
      // Filter out any empty sections
      dataSections = dataSections.filter(section => section.members.length > 0);
    }

    // Check if we need to render as a mobile view
    const tHeaderElems = shownColumns.map(colId => (
      <MDTableHeader key={colId} title={columns[colId].title} header={columns[colId].header} />
    ));
    tableHeader = (
      <thead>
        <tr>
          {tHeaderElems}
        </tr>
      </thead>
    );

    const tableSectionElems = dataSections.map((section) => {
      const sectionHeading = (
        <MDTableSectionHeading
          colSpan={mobile ? shownColumns.length + 1 : shownColumns.length} // + 1 for fatRowElem
          title={section.title}
        />
      );
      const tableRowElems = section.members.map((member) => {
        const tColElems = shownColumns.map((colId) => {
          const col = columns[colId];
          // keys only need to be unique locally, so within each row, just the column id is unique
          let entry = AppUtils.getNestedProperty(member, col.dataProp);

          if (col.render) {
            // function render used to render a custom rendered data entry
            entry = col.render(member, entry);
          }
          return (
            <td key={colId}>{entry}</td>
          );
        });
        let fatRowElem = null;
        if (mobile) {
          fatRowElem = (<td>
            <MDFatRow
              systemData={member}
              columns={columns}
              order={order}
            />
          </td>);
        }
        const rowElem = (
          <TableRow key={member.id} onClick={this.getRowClickHandler(member)}>
            {tColElems}
            {fatRowElem}
          </TableRow>
        );
        return rowElem;
      }); // End tableRowElems map

      return (
        <tbody key={section.title}>
          {dataSections.length > 1 ? sectionHeading : null}
          {tableRowElems}
        </tbody>
      );
    }); // End tableSectionElems map

    return (
      <Article pad="none">
        <Table selectable={!selectionDisabled}>
          {tableHeader}
          {tableSectionElems}
        </Table>
      </Article>
    );
  }
}

MDTable.propTypes = {
  data: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
  order: PropTypes.array.isRequired,
  columns: PropTypes.object.isRequired,
  selectionDisabled: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

MDTable.defaultProps = {
  selectionDisabled: false,
};

export default withRouter(MDTable);
