// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from 'grommet/components/Box';
import FormattedMessage from 'grommet/components/FormattedMessage';
import DebouncedTextInputField from './DebouncedTextInputField';

export class FilterBuilderRawValue extends PureComponent {
  constructor() {
    super();
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(name, val) {
    const { onChange, operatorVal, valueVal } = this.props;
    const operator = (name === 'operator' ? val : operatorVal);
    const value = (name === 'value' ? val : valueVal);
    onChange(operator, value);
  }

  render() {
    const { path, disabled, operatorVal, valueVal } = this.props;

    return (
      <Box direction="row" pad={{ between: 'small' }}>
        <Box size="small">
          <DebouncedTextInputField
            id={`${path}.operator`}
            name="operator"
            value={operatorVal}
            label={<FormattedMessage id="Operator" />}
            onChange={this.onInputChange}
            disabled={disabled}
          />
        </Box>
        <DebouncedTextInputField
          id={`${path}.value`}
          name="value"
          value={valueVal}
          label={<FormattedMessage id="Value" />}
          onChange={this.onInputChange}
          disabled={disabled}
        />
      </Box>
    );
  }
}

FilterBuilderRawValue.displayName = 'FilterBuilderRawValue';

FilterBuilderRawValue.propTypes = {
  onChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  operatorVal: PropTypes.string.isRequired,
  valueVal: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

FilterBuilderRawValue.defaultProps = {
  disabled: false,
};

export default FilterBuilderRawValue;
