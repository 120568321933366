// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import FilterBuilder from './FilterBuilder';
import columnConfig from '../config/storeServSystems';

export const StoreServFilterBuilder = props => (
  <FilterBuilder
    category="storeserv"
    columnConfig={columnConfig}
    filterId={props.match.params.id}
    location={props.location}
  />
);

StoreServFilterBuilder.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default StoreServFilterBuilder;
