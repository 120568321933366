// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

export default {
  entitlementExpired: 'The contract on this system has expired',

  entitlementWarning: 'This system will have an expired contract within 3 months',

  notUpdated: 'Last system update was detected on {lastUpdated}',

  upgrade: 'Proposed upgrade prospect',
  upgradeGood: 'Not an upgrade prospect',

  refresh: 'Proposed refresh prospect',
  refreshGood: 'Not a refresh prospect',

  endOfServiceLife: 'System model is at end-of-life (EOL)',
  endOfServiceLifeGood: 'System model is still in service and supported',

  upgradeNoLongerAvail: 'System model no longer supports hardware upgrades',
  upgradeNoLongerAvailGood: 'System model still supports hardware upgrades',

  freeCapacity: 'Free storage capacity is low',
  freeCapacityGood: 'Free storage capacity is OK',

  statusOsVersionPatch: 'New HPE 3PAR OS Critical Patch available for install',
  statusOsVersionPatchGood: 'Latest HPE 3PAR OS Critical Patch installed',

  statusOsVersion: 'New HPE 3PAR OS Version available for install',
  statusOsVersionGood: 'Latest HPE 3PAR OS Version installed',

  statusOsVersionConditionalPatch: 'New HPE 3PAR OS Conditional Patch available for install',
  statusOsVersionConditionalPatchGood: 'Latest HPE 3PAR OS Conditional Patch installed',

  statusOsVersionInactive: 'Installed HPE 3PAR OS Version is not supported',
  statusOsVersionInactiveGood: 'Installed HPE 3PAR OS Version is OK',

  statusSp: 'New SP Version available for install',
  statusSpGood: 'Latest SP Version installed',

  statusSpPatch: 'New SP Patch available for install',
  statusSpPatchGood: 'Latest SP Patch installed',

  alertsDegradedNew: 'The system has reported degraded alerts',

  alertsMinorNew: 'The system has reported minor alerts',

  alertsMajorNew: 'The system has reported major alerts',

  alertsCriticalNew: 'The system has reported critical alerts',

  alertsFatalNew: 'The system has reported fatal alerts',

  alertsDegradedStale: 'The system has unresolved degraded alerts that are over a month old',

  alertsMinorStale: 'The system has unresolved minor alerts that are over a month old',

  alertsMajorStale: 'The system has unresolved major alerts that are over a month old',

  alertsCriticalStale: 'The system has unresolved critical alerts that are over a month old',

  alertsFatalStale: 'The system has unresolved fatal alerts that are over a month old',

  cpuLoad: 'High CPU utilization of system node(s)',
  cpuLoadGood: 'Acceptable CPU utilization of system node(s)',

  maxVLUNs: 'Maximum Active VLUNs exceeded',
  maxVLUNsGood: 'Active VLUNs are below maximum',

  initiatorDistributionPerNode: 'Initiators are not balanced between nodes',
  initiatorDistributionPerNodeGood: 'Initiators are balanced between nodes',

  initiatorsPerSystem: 'Maximum initiators per system exceeded',
  initiatorsPerSystemGood: 'Initiators per system are below maximum',

  badDisks: 'Disks in {disksState} state',

  offlineNodes: 'Offline nodes',

  nodeTimeSkew: 'Node time skew',

  ssdLifeLeftPctLow: 'At least one SSD disk reports less than 50% life left.',
  ssdLifeLeftPctLowGood: 'All reporting SSD disks have 50% or more life left.',

  performDelAcks: 'A high percentage of writes have incurred delayed write acknowledgements.',

  performDelAcksExcessive: 'An unexpected number of writes have incurred delayed write acknowledgements.',

  performSlowNLWriteServiceTime: 'Average NL write service time is higher than expected.',
  performSlowFCWriteServiceTime: 'Average FC write service time is higher than expected.',
  performSlowSSDWriteServiceTime: 'Average SSD write service time is higher than expected.',
  performSlowWriteServiceTime: 'Average host port write service time is higher than expected.',

  performSlowNLReadServiceTime: 'Average NL read service time is higher than expected.',
  performSlowFCReadServiceTime: 'Average FC read service time is higher than expected.',
  performSlowSSDReadServiceTime: 'Average SSD read service time is higher than expected.',
  performSlowReadServiceTime: 'Average host port read service time is higher than expected.',

  performSlowWriteDedupe: 'System flagged for non-optimal data deduplication performance',

  performWritesGt16s: 'Recent instances of port host write I/Os exceeding 16 seconds',

  performMaxNL3xAvgNLWriteSvcTime: 'Worst NL writes are significantly slower than average',
  performMaxFC3xAvgFCWriteSvcTime: 'Worst FC writes are significantly slower than average',
  performMaxSSD3xAvgSSDWriteSvcTime: 'Worst SSD writes are significantly slower than average',
  performMaxVV3xPortWriteSvcTime: 'Worst VV writes are significantly slower than port writes',

  performMaxNL3xAvgNLReadSvcTime: 'Worst NL reads are significantly slower than average',
  performMaxFC3xAvgFCReadSvcTime: 'Worst FC reads are significantly slower than average',
  performMaxSSD3xAvgSSDReadSvcTime: 'Worst SSD reads are significantly slower than average',
  performMaxVV3xPortReadSvcTime: 'Worst VV reads are significantly slower than port reads',

  fcDiskCountUnbalancedByNodePair: 'FC disk count is not balanced between node pairs',
  nlDiskCountUnbalancedByNodePair: 'NL disk count is not balanced between node pairs',
  ssdDiskCountUnbalancedByNodePair: 'SSD disk count is not balanced between node pairs',

  fcDiskCountUnbalancedByNodePairGood: 'FC disk count is balanced between node pairs',
  nlDiskCountUnbalancedByNodePairGood: 'NL disk count is balanced between node pairs',
  ssdDiskCountUnbalancedByNodePairGood: 'SSD disk count is balanced between node pairs',

  fcUsedUnbalancedByNodePair: 'FC used capacity is not balanced between node pairs',
  nlUsedUnbalancedByNodePair: 'NL used capacity is not balanced between node pairs',
  ssdUsedUnbalancedByNodePair: 'SSD used capacity is not balanced between node pairs',

  fcUsedUnbalancedByNodePairGood: 'FC used capacity is balanced between node pairs',
  nlUsedUnbalancedByNodePairGood: 'NL used capacity is balanced between node pairs',
  ssdUsedUnbalancedByNodePairGood: 'SSD used capacity is balanced between node pairs',

  fcSizeUnbalancedByNodePair: 'FC storage capacity is not balanced between node pairs',
  nlSizeUnbalancedByNodePair: 'NL storage capacity is not balanced between node pairs',
  ssdSizeUnbalancedByNodePair: 'SSD storage capacity is not balanced between node pairs',

  fcSizeUnbalancedByNodePairGood: 'FC storage capacity is balanced between node pairs',
  nlSizeUnbalancedByNodePairGood: 'NL storage capacity is balanced between node pairs',
  ssdSizeUnbalancedByNodePairGood: 'SSD storage capacity is balanced between node pairs',

  fcFreeCapacity: 'FC free storage capacity is low',
  fcFreeCapacityGood: 'FC free storage capacity is OK',

  nlFreeCapacity: 'NL free storage capacity is low',
  nlFreeCapacityGood: 'NL free storage capacity is OK',

  ssdFreeCapacity: 'SSD free storage capacity is low',
  ssdFreeCapacityGood: 'SSD free storage capacity is OK',

  forecastCapacityParent: 'Some free storage is predicted to run out within a year',
  forecastCapacityParentGood: 'No type of free storage is predicted to run out within a year',
  forecastCapacityTotal: 'Total free storage is predicted to run out within a year',
  forecastCapacityTotalGood: 'Total free storage is not predicted to run out within a year',

  fcForecastCapacityTotal: 'Total FC free storage is predicted to run out within a year',
  fcForecastCapacityTotalGood: 'Total FC free storage is not predicted to run out within a year',

  nlForecastCapacityTotal: 'Total NL free storage is predicted to run out within a year',
  nlForecastCapacityTotalGood: 'Total NL free storage is not predicted to run out within a year',

  ssdForecastCapacityTotal: 'Total SSD free storage is predicted to run out within a year',
  ssdForecastCapacityTotalGood: 'Total SSD storage is not predicted to run out within a year',

  alertsDegradedStaleLabel: 'Unresolved Degraded Alerts Over a Month Old',
  alertsMinorStaleLabel: 'Unresolved Minor Alerts Over a Month Old',
  alertsMajorStaleLabel: 'Unresolved Major Alerts Over a Month Old',
  alertsCriticalStaleLabel: 'Unresolved Critical Alerts Over a Month Old',
  alertsFatalStaleLabel: 'Unresolved Fatal Alerts Over a Month Old',

  tpdKernelCode: 'TPD Kernel Code',
  tpdKernelPatch: 'TPD Kernel Patch',
  spPatches: 'SP Patches',

  instantOnExpiring: 'Instant On Expiring',
  instantOnExpiring0to10: 'Instant On Expiring between 0 to 10 days',
  instantOnExpiring10to20: 'Instant On Expiring between 10 to 20 days',
  instantOnExpiring20to30: 'Instant On Expiring between 20 to 30 days',
  instantOnExpiringStatusAlert: 'Instant On License is going to expire within 30 days',
  freeSpacePredictionFileSystem: 'Total Capacity Predicted Full for a file system',
  freeCapacityPercentage: 'SSD free capacity for a file system',
  expires: 'Expiry',
  licenseType: 'License Type',

  fc10kHighWaterPerformance: 'FC-10k tier has disks exceeding high water mark of recommended IOPS',
  fc15kHighWaterPerformance: 'FC-15k tier has disks exceeding high water mark of recommended IOPS',
  nlHighWaterPerformance: 'NL tier has disks exceeding high water mark of recommended IOPS',

  fc10kLowWaterPerformance: 'FC-10k tier has disks exceeding low water mark of recommended IOPS',
  fc15kLowWaterPerformance: 'FC-15k tier has disks exceeding low water mark of recommended IOPS',
  nlLowWaterPerformance: 'NL tier has disks exceeding low water mark of recommended IOPS',

  fc10kPerformanceGood: 'FC-10k tier has no disks exceeding recommended IOPS',
  fc15kPerformanceGood: 'FC-15k tier has no disks exceeding recommended IOPS',
  nlPerformanceGood: 'NL tier has no disks exceeding recommended IOPS',

  neverPurchasedExtendedContract: 'Never Purchased Extended Contract',
  allFlash: 'All Flash Systems',
  aoEnabled: 'Systems Utilizing Adaptive Optimization',
  usingFilePersona: 'Systems Utilizing File Persona',
  licensedNotUsingFilePersona: 'Systems Licensed But Not Utilizing File Persona',

  noStatus: 'This system currently has no status notes.',

  noContract: 'Device details are not available unless the device is under a support contract. Please contact your HPE Sales Team to purchase a support contract for this system.',
  noContractAuth: 'Customer consent is needed to view this system. Please contact customer to login to storefrontremote.com to accept consent agreement OR check back later.',

  notUpdatedThreeMonths: 'No Update in 3 Months',
  notUpdatedFourDays: 'No Update in 4 Days',
  batteryExpiresLessThanThreeMonths: 'Battery expires in less than 3 Months',
  forecast: 'Total free storage is predicted to run out within a year',
  checkAndPredictForFileSystem: 'Check And predict for file system',
  checkFreeCapacityForFileSystem: 'Check free capacity for file system',

  // This status ID is deprecated, but we are leaving it here so that we can display something for
  // it on beta and www until we release and so we can display something for this string in a
  // status history chart
  performInstanceWriteGt16s: 'Recent instances of port host write I/Os exceeding 16 seconds',

  badComponents: 'Failed or Degraded Components',
  opportunity: 'Opportunity',
  oversubscribed: 'Oversubscribed',
  lowResources: 'Low Resources',
  supportIssue: 'Support Issues',
  health: 'Health',
  optimization: 'Best Practices',
  serviceability: 'Supportability',
  iscsi: 'iSCSI',
  fcoe: 'FCoE',
  fc: 'FC',
  nl: 'NL',
  ssd: 'SSD',
  storage: 'Storage',
  compute: 'Compute',
  network: 'Network',
  capacity: 'Capacity',
  performance: 'Performance',
  load: 'Load',
  vluns: 'VLUNs',
  initiators: 'Initiators',
  other: 'Other',
  parent: 'Parent',
  statusDetails: 'Status Details',
};
