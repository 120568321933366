// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

import { FILTER_LOAD,
  FILTER_LOAD_SUCCESS,
  FILTER_LOAD_FAILURE,
  FILTER_CREATE,
  FILTER_CREATE_SUCCESS,
  FILTER_CREATE_FAILURE,
  FILTER_EDIT,
  FILTER_SAVE,
  FILTER_SAVE_SUCCESS,
  FILTER_SAVE_FAILURE,
  FILTER_DELETE,
  FILTER_DELETE_SUCCESS,
  FILTER_DELETE_FAILURE,
  FILTERS_UNLOAD,
  FILTER_CLEAR_ERRORS } from '../actions/actions';

import FilterUtils from '../utils/FilterUtils';

const initialState = {
  newFilterError: null,
  creating: false,
  // Filter state objects will populate here with filterId as key
};

export const filterInitialState = {
  filterData: null,
  hasUnsavedEdits: false, // TODO prevent user from leaving the page if there are unsaved edits?
  saving: false,
  deleted: false,
  error: null,
};

// NOTE: We do NOT automatically merge existing state
// so you must explicitely add ...state if you are not
// intending to blow away all state
const handlers = {

  [FILTERS_UNLOAD]: () => ({ ...initialState }),

  // TODO maybe prevent a load if there are unsaved changes?
  [FILTER_LOAD]: (state, action) => ({
    ...state,
    [action.filterId]: { ...filterInitialState },
  }),

  [FILTER_LOAD_SUCCESS]: (state, action) => ({
    ...state,
    [action.filterId]: {
      ...filterInitialState,
      filterData: FilterUtils.populateReportObjectTermIds(action.filterData),
    },
  }),

  [FILTER_LOAD_FAILURE]: (state, action) => ({
    ...state,
    [action.filterId]: {
      ...filterInitialState,
      error: { ...action.error },
    },
  }),

  [FILTER_CREATE]: state => ({
    ...state,
    newFilterError: null,
    creating: true,
  }),

  [FILTER_CREATE_SUCCESS]: (state, action) => ({
    ...state,
    newFilterError: null,
    creating: false,
    [action.filterId]: {
      ...filterInitialState,
      filterData: { ...action.filterData },
    },
  }),

  [FILTER_CREATE_FAILURE]: (state, action) => ({
    ...state,
    newFilterError: { ...action.error },
    creating: false,
  }),

  [FILTER_EDIT]: (state, action) => ({
    ...state,
    [action.filterId]: {
      ...state[action.filterId],
      filterData: { ...action.filterData },
      hasUnsavedEdits: true,
      // TODO maybe keep track of edits in an undo stack
      // (reference http://redux.js.org/docs/recipes/ImplementingUndoHistory.html
      // and probably just use https://github.com/omnidan/redux-undo)
    },
  }),

  [FILTER_SAVE]: (state, action) => ({
    ...state,
    [action.filterId]: {
      ...state[action.filterId],
      saving: true,
    },
  }),

  [FILTER_SAVE_SUCCESS]: (state, action) => ({
    ...state,
    [action.filterId]: {
      ...state[action.filterId],
      filterData: { ...action.filterData },
      saving: false,
      hasUnsavedEdits: false,
      error: null,
    },
  }),

  [FILTER_SAVE_FAILURE]: (state, action) => ({
    ...state,
    [action.filterId]: {
      ...state[action.filterId],
      saving: false,
      error: { ...action.error },
    },
  }),

  [FILTER_DELETE]: (state, action) => ({
    ...state,
    [action.filterId]: {
      ...state[action.filterId],
      saving: true,
    },
  }),

  [FILTER_DELETE_SUCCESS]: (state, action) => ({
    ...state,
    [action.filterId]: {
      ...filterInitialState,
      deleted: true,
    },
  }),

  [FILTER_DELETE_FAILURE]: (state, action) => ({
    ...state,
    [action.filterId]: {
      ...state[action.filterId],
      saving: false,
      error: { ...action.error },
    },
  }),

  [FILTER_CLEAR_ERRORS]: (state, action) => {
    if (action.filterId) {
      return {
        ...state,
        newFilterError: null,
        [action.filterId]: {
          ...state[action.filterId],
          error: null,
        },
      };
    }
    return {
      ...state,
      newFilterError: null,
    };
  },
};

export default function filterReducer(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...handler(state, action) };
}
