// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from 'grommet/components/Box';
import Heading from 'grommet/components/Heading';
import FormattedMessage from 'grommet/components/FormattedMessage';
import FilterBuilderObject from './FilterBuilderObject';
import FilterUtils from '../utils/FilterUtils';

export class FilterBuilderNot extends Component {
  constructor() {
    super();
    this.onChildChange = this.onChildChange.bind(this);
  }

  onChildChange(newChildFilterObject) {
    const { filterObject, onChange } = this.props;
    onChange({ ...filterObject,
      not: { ...newChildFilterObject },
    });
  }

  render() {
    const { filterObject, onRemove, onNegate, path, disabled, columns, meta, facets } = this.props;

    const childFilterObject = filterObject.not;
    const childIsProperty = FilterUtils.isF(childFilterObject);

    const innerObjectElement = (
      <FilterBuilderObject
        filterObject={childFilterObject}
        onChange={this.onChildChange}
        onRemove={onRemove}
        onNegate={onNegate}
        path={path}
        disabled={disabled}
        columns={columns}
        meta={meta}
        facets={facets}
      />
    );

    const notBox = (
      <Box colorIndex="accent-3" pad="small" justify="center">
        <Heading tag="h4" margin="none">
          <FormattedMessage id="NOT" />
        </Heading>
      </Box>
    );

    // If the child is a single property, just put a NOT box to the left of it.
    if (childIsProperty) {
      return (
        <Box direction="row" pad={{ between: 'small' }} className="filter-builder-group group-not filter-builder-object center-group-dash">
          <Box direction="column" justify="center">
            {notBox}
          </Box>
          <Box flex>
            {innerObjectElement}
          </Box>
        </Box>
      );
    }

    // If the child is a group, wrap a NOT bracket around it.
    return (
      <Box className="filter-builder-group filter-builder-object group-not">
        <Box direction="row">
          {notBox}
        </Box>
        <Box margin={{ left: 'medium' }} separator="left" className="bracket-separator">
          <Box margin={{ left: 'medium', vertical: 'medium' }}>
            {innerObjectElement}
          </Box>
        </Box>
        <Box
          margin={{ left: 'medium', bottom: 'small' }}
          separator="top"
          className="bracket-separator"
          size="small"
          responsive={false}
        />
      </Box>
    );
  }
}

FilterBuilderNot.displayName = 'FilterBuilderNot';

FilterBuilderNot.propTypes = {
  filterObject: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onNegate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  columns: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired,
};

FilterBuilderNot.defaultProps = {
  disabled: false,
  onRemove: null,
};

export default FilterBuilderNot;
