// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

// TODO: Replace with whatwg fetch
import Rest from 'grommet/utils/Rest';
import { termsOfUseVersion as requiredTermsOfUseVersion } from '../components/BaseTermsOfUse';

let pageSize = 20;
let urlPrefix = '';

Rest.setTimeout(60000);

export function setHeaders(headers) {
  Rest.setHeaders(headers);
}

export function configure(options) {
  pageSize = options.pageSize || pageSize;
  urlPrefix = options.urlPrefix || urlPrefix;
}

export function getPageSize() { return pageSize; }
export function getUrlPrefix() { return urlPrefix; }

// exported for testing purposes
export function apiCall(req) {
  return new Promise((resolve, reject) => req.end((err, res) => {
    if (err) {
      if (res && res.unauthorized && !req.url.startsWith(`${urlPrefix}/auth/authenticate`) && !req.url.startsWith(`${urlPrefix}/InfoSight/api/iam/graphql`)) {
        window.location.assign(`${process.env.PUBLIC_PATH}login?logout`);
      }
      // even though we check for the error object, the response may have all the data we need
      if (res) {
        reject(res);
      } else {
        reject({ error: err });
      }
    } else if (res.body && res.body.errorCode && res.body.errorMessage) {
      // Special case for the ES write-lock error, which is reported with an HTTP success.
      reject({
        ...res,
        error: {
          status: res.body.errorCode,
          message: res.body.errorMessage,
        },
      });
    } else {
      resolve(res);
    }
  }));
}

function cacheBustGet(url) {
  const cacheBusting = `_=${new Date().getTime()}`;
  const suffix = (url.indexOf('?') === -1 ? `?${cacheBusting}` : `&${cacheBusting}`);
  return Rest.get(`${url}${suffix}`);
}

function combineAuthResponses(oculusResponse, iamResponse) {
  const iamSession = iamResponse.body
    && iamResponse.body.data
    && iamResponse.body.data.currentSession;
  const verifiedEmail = iamSession && iamSession.emailVerification === 'VERIFIED' ? iamSession.user && iamSession.user.userName : undefined;
  const eulaVersion = iamSession && iamSession.eulaAccepted ? requiredTermsOfUseVersion : -1;
  // This is where we combine the oculus and IAM responses so the rest of the code doesn't have
  // to know we changed the API
  const authResponse = { ...oculusResponse,
    body: {
      ...oculusResponse.body,
      verifiedEmail,
      eulaVersion,
    },
  };
  return authResponse;
}

// When we need to get the EULA and email verification status from the IAM backend
function getIamSession() {
  const iamSessionQuery = '{ "query" : "query { currentSession { emailVerification eulaAccepted user { userName } } }" }';
  return apiCall(Rest.post(`${urlPrefix}/InfoSight/api/iam/graphql`, iamSessionQuery));
}

export function getAuthentication() {
  const oculusRequest = apiCall(cacheBustGet(`${urlPrefix}/auth/authenticate`));
  const iamSessionQuery = '{ "query" : "query { currentSession { emailVerification eulaAccepted user { userName } } }" }';
  const iamRequest = apiCall(Rest.post(`${urlPrefix}/InfoSight/api/iam/graphql`, iamSessionQuery));
  return Promise.all([oculusRequest, iamRequest]).then(([oculusResponse, iamResponse]) =>
    combineAuthResponses(oculusResponse, iamResponse));
}

export function postLogin(username, password) {
  const params = {
    username,
    password,
  };
  const oculusRequest = apiCall(Rest.post(`${urlPrefix}/auth/authenticate`, params));
  return oculusRequest.then(oculusResponse => getIamSession().then(iamResponse =>
    combineAuthResponses(oculusResponse, iamResponse)));
}

export function deleteLogout() {
  return apiCall(Rest.del(`${urlPrefix}/auth/authenticate`));
}

export function getTenants() {
  return apiCall(cacheBustGet(`${urlPrefix}/auth/tenants?count=100`));
}

export function getUser() {
  return apiCall(cacheBustGet(`${urlPrefix}/auth/user`));
}

export function getTenantsDetails() {
  // Note: we're not currently using this, because we don't need to know the list of tenant users.
  return apiCall(cacheBustGet(`${urlPrefix}/auth/tenants/details?count=100`));
}

export function getMeta(category) {
  return apiCall(cacheBustGet(`${urlPrefix}/api/${category}/systems/_meta`));
}

export function getFacets(category, dataProps) {
  const facetParams = dataProps.join('&facet=');
  return apiCall(cacheBustGet(`${urlPrefix}/api/${category}/systems?count=0&facet=${facetParams}`));
}

export function getStatusMetadata(category) {
  return apiCall(cacheBustGet(`${urlPrefix}/api/${category}/status/metadata`));
}

export function postSystemsQuery(category, requestBody, count = 0, sortOrder) {
  let sortQuery = '';
  if (sortOrder) {
    const sortParams = (Array.isArray(sortOrder) ? sortOrder : [sortOrder])
      .join(',');
    sortQuery = `&sort=${sortParams}`;
  }
  return apiCall(Rest.post(`${urlPrefix}/api/${category}/systems?count=${count}${sortQuery}`,
    requestBody));
}

export function getSystemMonthly(category, systemId) {
  return apiCall(cacheBustGet(`${urlPrefix}/api/${category}/systems/${systemId}/monthly?f=isForecast:false&count=100&sort=date:desc`));
}

export function getSystemDaily(category, systemId) {
  return apiCall(cacheBustGet(`${urlPrefix}/api/${category}/systems/${systemId}/daily?f=isForecast:false&count=100&sort=date:desc`));
}

export function getSystemPerformance(category, systemId, dataProps) {
  const fields = ['timeRange', ...dataProps].join(',');
  return apiCall(cacheBustGet(`${urlPrefix}/api/${category}/perform-deltas/system/${systemId}?fields=${fields}&count=450&sort=timeRange.to:desc&viewAll=true`));
}

export function getSystem(category, systemId) {
  return apiCall(cacheBustGet(`${urlPrefix}/api/${category}/systems/${systemId}`));
}

export function getFilter(category, filterId) {
  return apiCall(cacheBustGet(`${urlPrefix}/api/${category}/systems/reports/${filterId}`));
}

export function postCreateFilter(category, data) {
  return apiCall(Rest.post(`${urlPrefix}/api/${category}/systems/reports`, data));
}

export function putSaveFilter(category, filterId, data) {
  return apiCall(Rest.put(`${urlPrefix}/api/${category}/systems/reports/${filterId}`, data));
}

export function delFilter(category, filterId) {
  return apiCall(Rest.del(`${urlPrefix}/api/${category}/systems/reports/${filterId}`));
}

export function getProfile() {
  // This API URL is /storeserv/, although it applies to no category in particular
  return apiCall(cacheBustGet(`${urlPrefix}/api/storeserv/user/profile`));
}

export function putProfile(profileData) {
  return apiCall(Rest.put(`${urlPrefix}/api/storeserv/user/profile`, profileData));
}

export function postConfirmVerifiedEmail(linkId) {
  return apiCall(Rest.post(`${urlPrefix}/InfoSight/api/iam/graphql`, `{ "query" : "mutation checkEmailVerification($token: String) { checkEmailVerification(jwt: $token) }", "variables" : { "token" : "${linkId}" } }`));
}

export function postSendVerificationEmail() {
  return apiCall(Rest.post(`${urlPrefix}/InfoSight/api/iam/graphql`, '{ "query" : "mutation sendEmailVerification { sendEmailVerification }" }'));
}

export function postAcceptTermsOfUse() {
  return apiCall(Rest.post(`${urlPrefix}/InfoSight/api/iam/graphql`, '{ "query" : "mutation acceptEula { acceptEula }" }'));
}
