// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import moment from 'moment';
import shortid from 'shortid';
import AppUtils from './AppUtils';
import ConfigUtils from './ConfigUtils';

const validSpecificDateFilterRegexp = /^(\d{4}-\d{2}-\d{2})$/;
const validRelativeDateFilterRegexp = /^now(-|\+)(\d+)(d|M|y)$/;
const nonDigitsExceptInParenRegexp = /(\([^)]*\)|\D)/g;
const trailingDotZeroRegexp = /\.(\.|0)*$/;

const FilterUtils = {
  isF: filterObject => !!filterObject.f &&
      AppUtils.hasProperties(filterObject.f, ['field', 'operator', 'value']),

  // TODO currently we only support nested filters with a single property in them.
  // An API filter object may have an AND/OR, etc inside the nested filter,
  // and for now we ignore those (treat them as raw JSON), so they return false for isNf.
  isNf: filterObject => !!filterObject.nf &&
      AppUtils.hasProperties(filterObject.nf, ['path', 'filters']) &&
      FilterUtils.isF(filterObject.nf.filters), // (only support single property nf for now)

  isNot: filterObject => !!filterObject.not,

  isAnd: filterObject => !!filterObject.and && !!filterObject.and.terms,

  isOr: filterObject => !!filterObject.or && !!filterObject.or.terms,

  isGroup: filterObject => FilterUtils.isAnd(filterObject) || FilterUtils.isOr(filterObject),

  isReadyToSave: (filterObject) => {
    // A filter is ready to save if it has no empty groups or empty properties.
    if (FilterUtils.isF(filterObject)) {
      return filterObject.f.field !== '' &&
          filterObject.f.operator !== '' &&
          filterObject.f.value !== '';
    }
    if (FilterUtils.isNf(filterObject)) {
      return FilterUtils.isReadyToSave(filterObject.nf.filters);
    }
    if (FilterUtils.isNot(filterObject)) {
      return FilterUtils.isReadyToSave(filterObject.not);
    }
    if (FilterUtils.isAnd(filterObject)) {
      return filterObject.and.terms.length > 0 &&
          filterObject.and.terms.every(FilterUtils.isReadyToSave);
    }
    if (FilterUtils.isOr(filterObject)) {
      return filterObject.or.terms.length > 0 &&
          filterObject.or.terms.every(FilterUtils.isReadyToSave);
    }
    return true;
  },

  withTermId: (object) => {
    if (object.termId) return object;
    return { ...object, termId: shortid.generate() };
  },

  populateTermIds: (filterObject) => {
    if (FilterUtils.isNot(filterObject)) {
      return {
        ...FilterUtils.withTermId(filterObject),
        not: FilterUtils.populateTermIds(filterObject.not),
      };
    }
    if (FilterUtils.isAnd(filterObject)) {
      return {
        ...FilterUtils.withTermId(filterObject),
        and: { terms: filterObject.and.terms.map(FilterUtils.populateTermIds) },
      };
    }
    if (FilterUtils.isOr(filterObject)) {
      return {
        ...FilterUtils.withTermId(filterObject),
        or: { terms: filterObject.or.terms.map(FilterUtils.populateTermIds) },
      };
    }
    return FilterUtils.withTermId(filterObject);
  },

  populateReportObjectTermIds: reportObject => ({ ...reportObject,
    report: { ...reportObject.report,
      reportFilter: { ...FilterUtils.populateTermIds(reportObject.report.reportFilter) },
    },
  }),

  // fieldMeta is an individual object from the processed api _meta
  // columnMeta is an individual object from the frontend column metadata
  // forceAsFacetField is a boolean in columnMeta which is present for the purpose
  // of enabling the capability of facet filtering on columns with non-string type
  // We only want to facet on fields that exist in column metadata,
  // so we're not loading a ton of extra facets and slowing down the page load
  isFacetField: (fieldMeta, columnMeta) => (fieldMeta && columnMeta &&
    (columnMeta.forceAsFacetField || (fieldMeta.type === 'string' &&
    !fieldMeta.isPersonallyIdentifiable && !columnMeta.excludeFromFacets))),

  isNumericField: fieldMeta => (fieldMeta &&
    (fieldMeta.type === 'int' || fieldMeta.type === 'float' ||
    fieldMeta.type === 'double' || fieldMeta.type === 'long')),

  isVersionRangeField: (fieldMeta, columnMeta) => (fieldMeta && columnMeta &&
      fieldMeta.type === 'string' && !!columnMeta.versionRangeDataProp),

  isNestedField: fieldMeta => !!fieldMeta && !!fieldMeta.nestedPath,

  isDateField: fieldMeta => fieldMeta && (fieldMeta.type === 'timestamp' ||
    fieldMeta.type === 'date'),

  isValidDateFilter: (operator, value) =>
    FilterUtils.parseSpecificDate(operator, value) ||
      FilterUtils.parseRelativeDate(operator, value),

  parseDateFilter: (operator, value) => {
    const defaultParsedProperties = {
      specificInput: moment().format('YYYY-MM-DD'),
      relativeInput: '0',
      selectedDateQualifier: 'beforeRelative',
      selectedRelativeUnits: 'months',
      selectedRelativeTimeSpan: 'ago',
    };
    const parsedValue = FilterUtils.parseSpecificDate(operator, value)
        || FilterUtils.parseRelativeDate(operator, value);
    if (!parsedValue && operator !== '' && value !== '') {
      // eslint-disable-next-line no-console
      console.warn(`Date filter value "${operator}${value}" could not be parsed!`);
    }
    return {
      ...defaultParsedProperties,
      ...parsedValue,
    };
  },

  parseSpecificDate: (operator, value) => {
    if (validSpecificDateFilterRegexp.test(value) && moment(value).isValid()) {
      return {
        selectedDateQualifier: (operator === '<' ? 'beforeSpecific' : 'afterSpecific'),
        specificInput: value,
      };
    }
    return undefined;
  },

  parseRelativeDate: (operator, value) => {
    const matches = value.match(validRelativeDateFilterRegexp);
    if (matches) {
      return {
        selectedDateQualifier: (operator === '<' ? 'beforeRelative' : 'afterRelative'),
        selectedRelativeTimeSpan: (matches[1] === '-' ? 'ago' : 'fromNow'),
        relativeInput: matches[2],
        selectedRelativeUnits: ((unit) => {
          switch (unit) {
            case 'd': return 'days';
            case 'M': return 'months';
            default: return 'years';
          }
        })(matches[3]),
      };
    }
    return undefined;
  },

  getFacetDataProp: (dataProp, fieldMeta) => {
    let facetProp = dataProp;

    // If we have e.g. dataProp = 'disks.diskModels.type' and nestedPath = 'disks.diskModels',
    // we want facetProp = 'disks.diskModels:type'
    if (fieldMeta.nestedPath) {
      const propAfterPath = dataProp.replace(`${fieldMeta.nestedPath}.`, '');
      facetProp = `${fieldMeta.nestedPath}:${propAfterPath}`;
    }

    if (fieldMeta.isAnalyzed) {
      return `${facetProp}.raw`;
    }
    return facetProp;
  },

  getAllFacetDataProps: (apiMetadata, frontendMetadata) =>
    Object.keys(apiMetadata.fields).filter((dataProp) => {
      const columnObject = ConfigUtils.getFieldByDataProp(frontendMetadata, dataProp);
      return FilterUtils.isFacetField(apiMetadata.fields[dataProp], columnObject);
    })
      .map(dataProp => FilterUtils.getFacetDataProp(dataProp, apiMetadata.fields[dataProp])),

  // Convert a given version string to x.x.x.xxxx form for comparison with API normalized versions
  normalizeVersionString: (versionString) => {
    const components = versionString.split('.');
    const expectedLengths = [1, 1, 1, 4];

    const normalizedComponents = expectedLengths.map((expectedLength, index) => {
      let component = components[index];

      if (component) {
        component = component.replace(nonDigitsExceptInParenRegexp, '');
      }

      if (!component || component.length === 0) {
        component = '0';
      }

      const componentAsInt = parseInt(component, 10);
      if (componentAsInt > 9 && index !== 3) {
        component = 'A';
      } else {
        component = componentAsInt.toString();
      }

      if (component.length >= expectedLength) {
        component = component.substring(0, expectedLength);
      } else {
        const paddingRequired = expectedLength - component.length;
        const padding = new Array(paddingRequired + 1).join('0');
        component = padding.concat(component);
      }

      return component;
    });

    return normalizedComponents.join('.');
  },

  denormalizeVersionString: (normalizedVersionString) => {
    if (normalizedVersionString === '') return '';
    const denormalizedComponents = normalizedVersionString.split('.').map((component) => {
      if (component === 'A') return '10';
      return parseInt(component, 10).toString();
    });
    return denormalizedComponents.join('.').replace(trailingDotZeroRegexp, '');
  },
};

export default FilterUtils;
