// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import SVGIcon from 'grommet/components/SVGIcon';

// SVG contents from https://www.onlinewebfonts.com/icon/103390

export const CircleBangIcon = () => (
  <SVGIcon
    viewBox="0 0 1000 1000"
    version="1.1"
    type="control"
  >
    <g>
      <path d="M500,101.9c-106.3,0-206.3,41.4-281.5,116.6S101.9,393.7,101.9,500s41.4,206.3,116.6,281.5S393.7,898.1,500,898.1s206.3-41.4,281.5-116.6S898.1,606.3,898.1,500s-41.4-206.3-116.6-281.5S606.3,101.9,500,101.9z M500,10L500,10c270.6,0,490,219.4,490,490S770.6,990,500,990S10,770.6,10,500S229.4,10,500,10z M438.8,683.8h122.5v122.5H438.8V683.8z M438.8,193.8h122.5v367.5H438.8V193.8z" />
    </g>
  </SVGIcon>
);

CircleBangIcon.displayName = 'CircleBangIcon';

export default CircleBangIcon;
