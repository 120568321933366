// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import dateFns from 'date-fns';

/* General Utility file for the App. Can be viewed as Utils.js file
 * which we had in Oculus Piano
 */

const outerQuotesRegex = /^".*"$/;

const AppUtils = {

  // Gets a nested property from an object using a dot notation string
  getNestedProperty(obj, property) {
    return property.split('.').reduce((o, i) => (o && Object.prototype.hasOwnProperty.call(o, i) ? o[i] : null), obj);
  },

  formatCapacity(sizeInTiB, units, precision) {
    let result = null;
    if (units === 'EiB') {
      result = sizeInTiB / (1024 * 1024);
    } else {
      result = sizeInTiB;
    }

    return Number(result.toFixed(precision));
  },

  sortDeduplicationKeys(a, b) {
    const aKey = a.label;
    const bKey = b.label;
    const findDedupKeyIndex = ((str) => {
      const index = str.indexOf('-');
      if (index !== -1) {
        return index;
      }
      return str.indexOf('+');
    });
    const aInd = findDedupKeyIndex(aKey);
    const bInd = findDedupKeyIndex(bKey);
    if (aKey.substring(0, aInd) < bKey.substring(0, bInd)) {
      return -1;
    }
    if (aKey.substring(0, aInd) > bKey.substring(0, bInd)) {
      return 1;
    }
    return 0;
  },

  convertCamelCaseText(text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    // capitalize the first letter
    return result.charAt(0).toUpperCase() + result.slice(1);
  },

  wrapInQuotesIfHasSpaces(text) {
    // Only use quotes if there is a space, and the text is not already wrapped in quotes.
    if (text.indexOf(' ') !== -1 && !text.match(outerQuotesRegex)) {
      return `"${text}"`;
    }
    return text;
  },

  // converts { members: [{ date: 1234, a: 1}, { date: 1235, a: 17 }]}
  // with dataProps: ['a']
  // into { a: [[1234, 1], [1235, 17]] }
  // This assumes you have reverse sorted data which is the pattern
  //   we use for all our historical api calls
  // This also assumes you need your xProp to be parsed as a date
  //   and returned as a unix timestamp number
  processDataPoints(rawData, dataProps, xProp = 'date', granularity = 1000 * 60 * 60 * 24) {
    const data = {};
    // TODO refactor so it doesn't have to do granularity math on the same xVal more than once
    dataProps.forEach((dataProp) => {
      const chartArr = rawData.members.map((member) => {
        const xVal = AppUtils.getNestedProperty(member, xProp);
        const ms = dateFns.parse(xVal).getTime();
        const yVal = AppUtils.getNestedProperty(member, dataProp);
        return typeof yVal === 'number' ? ([
          Math.floor(ms / granularity) * granularity,
          yVal,
        ]) : undefined;
      });
      data[dataProp] = chartArr.filter(item => item !== undefined).reverse();
    });
    return data;
  },

  capitalizeFirstLetter(inputString) {
    return `${inputString.charAt(0).toUpperCase()}${inputString.slice(1).toLowerCase()}`;
  },

  // Intersperse returns a copy of the given array with the given element in between each item.
  // intersperse([1, 2, 3], 'and') -> [1, 'and', 2, 'and', 3];
  // Can optionally pass a function to be called on each item, e.g. for setting up unique keys.
  // Slightly modified version of http://stackoverflow.com/a/31879739
  intersperse(array, element, processElement) {
    return [].concat(...array.map((x, i) => {
      const elem = (processElement ? processElement(element, i) : element);
      return [elem, x];
    })).slice(1);
  },

  hasProperties(object, properties) {
    return properties.every(property => Object.prototype.hasOwnProperty.call(object, property));
  },

  // Converts an object containing other objects as values
  // into an array of the value objects, with each containing the original key as a property.
  // For example, this object:
  //   {
  //     foo: { name: 'Foo', num: 1 },
  //     bar: { name: 'Bar', num: 2 },
  //   }
  // would be converted to this array:
  //   [
  //     { key: 'foo', name: 'Foo', num: 1 },
  //     { key: 'bar', name: 'Bar', num: 2 },
  //   ]
  // This is useful for retaining a map for easy lookup by key, while also using the values
  // in the map as an array (e.g. for options in a Select dropdown)
  objectToArrayWithKeys(object) {
    return Object.keys(object).map(key => ({ key, ...object[key] }));
  },

  // NOTE: Only use this when absolutely necessary, to edit existing profile keys, etc.
  // We should NOT introduce any new usage of the legacy category strings.
  getLegacyPianoCategory(category) {
    switch (category) {
      case 'storeserv': return 'systems';
      case 'storeonce': return 'storeonce-systems';
      default: return category;
    }
  },

  delay(millis, handleTimeoutId) {
    return new Promise((resolve) => {
      const timeoutId = setTimeout(() => resolve(true), millis);
      if (handleTimeoutId) {
        handleTimeoutId(timeoutId);
      }
    });
  },
};

export default AppUtils;
