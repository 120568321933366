// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from 'grommet/components/Box';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Heading from 'grommet/components/Heading';
import Paragraph from 'grommet/components/Paragraph';
import normalize from '../utils/normalizeWithPrecisionPatch';
import ConfigUtils from '../utils/ConfigUtils';
import ChartLayoutHelper from './ChartLayoutHelper';

export class MDChart extends Component {
  constructor(props) {
    super(props);
    this.maxCountHandler = this.maxCountHandler.bind(this);
    this.hoverHandler = this.hoverHandler.bind(this);
    this.state = { maxCount: undefined, hoverIndex: undefined };
  }

  maxCountHandler(count) {
    this.setState({ ...this.state, maxCount: count });
  }

  hoverHandler(index) {
    if (typeof index === 'number') {
      this.setState({ ...this.state, hoverIndex: index });
    }
  }

  render() {
    function selectChartType(chartConfig, normalizedValues, color, key) {
      const vertical = chartConfig.vertical;
      return {
        values: normalizedValues,
        activeIndex: chartConfig.activeIndex,
        vertical,
        colorIndex: color,
        min: chartConfig.yMin,
        max: chartConfig.yMax,
        key,
      };
    }

    const { chartConfig, dataSets, clickHandler, clickedIndex, small, roles } = this.props;

    if (dataSets === null) {
      return (
        <Box pad="small">
          <Heading tag="h3">
            <FormattedMessage id={chartConfig.title} />
          </Heading>
          <Paragraph size="large">
            <FormattedMessage id="Error Retrieving Data" />
          </Paragraph>
        </Box>
      );
    }

    // Filter out any empty data sets so we show the rest of the chart without the missing data line
    const filteredDataSets = dataSets
      .filter(dataSet => Array.isArray(dataSet) && dataSet.length > 0);

    const emptyData = filteredDataSets.length === 0 ||
      (Math.max(...filteredDataSets.map(dataSet => dataSet.length)) === 0);

    if (emptyData) {
      return (
        <Box pad="small">
          <Heading tag="h3">
            <FormattedMessage id={chartConfig.title} />
          </Heading>
          <Paragraph size="large">
            <FormattedMessage id="No Data Available" />
          </Paragraph>
        </Box>
      );
    }

    const granularity = chartConfig.granularity ? chartConfig.granularity : 1000 * 60 * 60 * 24;
    const precision = chartConfig.precision ? chartConfig.precision : 1e2;
    const normalizedValues = normalize(filteredDataSets, granularity, precision);

    // We may want to use a formula based on normalizedValues.values.length and maxCount to
    // determine the number of 'stops' (tick marks) and labels
    const numDataPoints = Math.max(...normalizedValues.values.map(vals => vals.length));
    const xAxisStops = small ? 2 : 3;
    const yAxisStops = 3;
    const xAxisFields = ['month', 'day'];
    // the min x value from the first data points
    const xMin = Math.min(...filteredDataSets.map(dataSet => dataSet[0][0]));
    // the max x value from the last data points
    const xMax = Math.max(...filteredDataSets.map(dataSet => dataSet[dataSet.length - 1][0]));
    const flatten = arr => arr.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);
    const nonEmptyYValues = flatten(normalizedValues.values).filter(item => item !== undefined);
    const yMin = typeof chartConfig.yMin === 'number' ? chartConfig.yMin : Math.min(...nonEmptyYValues);
    const yMax = typeof chartConfig.yMax === 'number' ? chartConfig.yMax : Math.ceil(Math.max(...nonEmptyYValues));
    const activeIndex = typeof this.state.hoverIndex === 'number' ? this.state.hoverIndex : numDataPoints - 1;
    // The true X value of where we are hovering
    const activeX = xMin + (activeIndex * granularity);
    const gridRows = chartConfig.grid ? 3 : 2;
    const gridCols = chartConfig.grid ? (xMax - xMin) / granularity / 24 : 2;

    const dataPropsForRoles = chartConfig.series.reduce((validPropList, seriesObj) => {
      if (ConfigUtils.doRolesOverlap(roles, seriesObj.roles)) {
        validPropList.push(seriesObj);
      }
      return validPropList;
    }, []);

    const colors = dataPropsForRoles.map(propObj => (propObj.colorIndex));
    const graphProps = normalizedValues.values.map((vals, i) => selectChartType({ ...chartConfig, yMin, yMax, activeIndex }, vals, colors[i], `series-${i + 1}`));
    const legendSeries = dataPropsForRoles.map(propObj => ({
      label: propObj.label,
      colorIndex: propObj.colorIndex,
    }));
    const markerVals = normalizedValues.values.map((vals) => {
      if (typeof vals[activeIndex] === 'number') {
        return vals[activeIndex];
      }
      return '';
    });
    const legendIncludingValues = legendSeries ? legendSeries.map((series, i) => {
      const oldSeries = series;
      return { ...oldSeries, value: markerVals[i], units: chartConfig.units };
    }) : null;

    return (
      <ChartLayoutHelper
        title={chartConfig.title}
        type={chartConfig.type}
        yMin={yMin}
        yMax={yMax}
        yAxisStops={yAxisStops}
        xMin={xMin}
        xMax={xMax}
        xAxisStops={xAxisStops}
        gridRows={gridRows}
        gridCols={gridCols}
        graphProps={graphProps}
        numDataPoints={numDataPoints}
        activeIndex={activeIndex}
        clickedIndex={clickedIndex}
        activeX={activeX}
        legendSeries={legendIncludingValues}
        maxCountHandler={this.maxCountHandler}
        clickHandler={clickHandler}
        hoverHandler={this.hoverHandler}
        xAxisFields={xAxisFields}
        small={small}
        normalizedValues={normalizedValues.values}
      />
    );
  }
}

MDChart.propTypes = {
  chartConfig: PropTypes.object.isRequired,
  // dataSets should be an array of arrays of data points. For example,
  // [
  //   [ [0, 70], [10, 0], [12, 20], [13, 100], [14, 60] ],
  //   [ [0, 0], [1, 10], [2, 20], [3, 30], [4, 50] ],
  // ]
  dataSets: PropTypes.array.isRequired,
  small: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired,
  clickedIndex: PropTypes.number,
  roles: PropTypes.array.isRequired,
};

MDChart.defaultProps = {
  clickedIndex: undefined,
};

export default MDChart;
