// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import { getSystemMonthly, getSystemDaily, getSystemPerformance } from './api';
import AppUtils from '../utils/AppUtils';

export const HISTORICAL_LOAD_MONTHLY = 'HISTORICAL_LOAD_MONTHLY';
export const HISTORICAL_LOAD_MONTHLY_SUCCESS = 'HISTORICAL_LOAD_MONTHLY_SUCCESS';
export const HISTORICAL_LOAD_MONTHLY_FAILURE = 'HISTORICAL_LOAD_MONTHLY_FAILURE';
export const HISTORICAL_LOAD_DAILY = 'HISTORICAL_LOAD_DAILY';
export const HISTORICAL_LOAD_DAILY_SUCCESS = 'HISTORICAL_LOAD_DAILY_SUCCESS';
export const HISTORICAL_LOAD_DAILY_FAILURE = 'HISTORICAL_LOAD_DAILY_FAILURE';
export const HISTORICAL_LOAD_PERFORMANCE = 'HISTORICAL_LOAD_PERFORMANCE';
export const HISTORICAL_LOAD_PERFORMANCE_SUCCESS = 'HISTORICAL_LOAD_PERFORMANCE_SUCCESS';
export const HISTORICAL_LOAD_PERFORMANCE_FAILURE = 'HISTORICAL_LOAD_PERFORMANCE_FAILURE';
export const HISTORICAL_UNLOAD = 'HISTORICAL_UNLOAD';

export function loadMonthlySuccess(result, category, systemId) {
  return { type: HISTORICAL_LOAD_MONTHLY_SUCCESS, result, category, systemId };
}

export function loadMonthlyFailure(error, category, systemId) {
  return { type: HISTORICAL_LOAD_MONTHLY_FAILURE, error, category, systemId };
}

export function loadMonthly(category, systemId) {
  return (dispatch) => {
    dispatch({ type: HISTORICAL_LOAD_MONTHLY, category, systemId });
    return getSystemMonthly(category, systemId)
      .then(res => dispatch(loadMonthlySuccess(res.body, category, systemId)))
      .catch(err => dispatch(loadMonthlyFailure(err, category, systemId)));
  };
}

export function loadDailySuccess(result, category, systemId) {
  return { type: HISTORICAL_LOAD_DAILY_SUCCESS, result, category, systemId };
}

export function loadDailyFailure(error, category, systemId) {
  return { type: HISTORICAL_LOAD_DAILY_FAILURE, error, category, systemId };
}

export function loadDaily(category, systemId) {
  return (dispatch) => {
    dispatch({ type: HISTORICAL_LOAD_DAILY, category, systemId });
    return getSystemDaily(category, systemId)
      .then(res => dispatch(loadDailySuccess(res.body, category, systemId)))
      .catch(err => dispatch(loadDailyFailure(err, category, systemId)));
  };
}

export function loadPerformanceSuccess(result, category, systemId) {
  return { type: HISTORICAL_LOAD_PERFORMANCE_SUCCESS, result, category, systemId };
}

export function loadPerformanceFailure(error, category, systemId) {
  return { type: HISTORICAL_LOAD_PERFORMANCE_FAILURE, error, category, systemId };
}

export function loadPerformance(category, systemId, dataProps) {
  return (dispatch) => {
    dispatch({ type: HISTORICAL_LOAD_PERFORMANCE, category, systemId });
    return getSystemPerformance(category, systemId, dataProps)
      .then((res) => {
        const result = AppUtils.processDataPoints(res.body, dataProps,
          'timeRange.to', 1000 * 60 * 60);
        return dispatch(loadPerformanceSuccess(result, category, systemId));
      }).catch(err => dispatch(loadPerformanceFailure(err, category, systemId)));
  };
}

export function unloadHistoricalData(category) {
  return { type: HISTORICAL_UNLOAD, category };
}
