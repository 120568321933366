// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

import { TENANTS_LOAD,
  TENANTS_LOAD_SUCCESS,
  TENANTS_LOAD_FAILURE } from '../actions/actions';

const initialState = {
  details: {},
  error: null,
};

const handlers = {

  [TENANTS_LOAD]: state => state,

  [TENANTS_LOAD_SUCCESS]: (state, action) => ({
    ...state,
    details: { ...action.tenantsById },
  }),

  [TENANTS_LOAD_FAILURE]: (state, action) => ({
    ...state,
    error: { ...action.error },
  }),

};

export default function tenantsReducer(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...handler(state, action) };
}
