// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'grommet/components/Heading';
import Box from 'grommet/components/Box';
import FormattedMessage from 'grommet/components/FormattedMessage';
import AppUtils from '../utils/AppUtils';


const SystemDetailsHeader = (props) => {
  const { systemDetails, metadata, defaultHeader } = props;
  let pageHeader = null;
  if (systemDetails) {
    const primaryIndex = Object.keys(metadata).find(columnId => metadata[columnId].fatRowPrimary);
    const secondaryIndex = Object.keys(metadata).find(columnId =>
      metadata[columnId].fatRowSecondary);
    const primary = metadata[primaryIndex];
    const secondary = metadata[secondaryIndex];

    /*
    * Check for render function to make it future proof where, if we may have to render columns
    * which needs special formatting before it is displayed on UI, we ensure to format it before
    * displaying. For example if Free Capacity % column is decided to be displayed in mobile view in
    * future, we just have to change fatRowPrimary or fatRowSecondary to true in config file. In
    * this case, the render check below will by default take care of the formatting required before
    * displaying the capacity on screen
    */
    const fatHeaderPrimaryElem = (primary.render) ?
      primary.render(systemDetails) :
      AppUtils.getNestedProperty(systemDetails, primary.dataProp);

    const fatHeaderSecondaryElem = (secondary.render) ?
      secondary.render(systemDetails) :
      AppUtils.getNestedProperty(systemDetails, secondary.dataProp);

    pageHeader = (
      <Box
        direction="row"
        pad={{ between: 'small' }}
        align="end"
      >
        <Heading tag="h3" margin="none" strong >{fatHeaderPrimaryElem}</Heading>
        <Heading tag="h4" margin="none" strong >{fatHeaderSecondaryElem}</Heading>
      </Box>
    );
  } else {
    pageHeader = (
      <Box
        direction="row"
        pad={{ between: 'small' }}
        align="end"
      >
        <Heading tag="h3" margin="none" strong >
          <FormattedMessage id={defaultHeader} />
        </Heading>
      </Box>
    );
  }

  return pageHeader;
};

SystemDetailsHeader.propTypes = {
  systemDetails: PropTypes.object,
  metadata: PropTypes.object,
};

SystemDetailsHeader.defaultProps = {
  systemDetails: undefined,
  metadata: {},
};

export default SystemDetailsHeader;
