// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import bowser from 'bowser';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Anchor from 'grommet/components/Anchor';
import Menu from 'grommet/components/Menu';
import AppsIcon from 'grommet/components/icons/base/Apps';
import FormattedMessage from 'grommet/components/FormattedMessage';

export const LoginMenu = (props) => {
  const { intl } = props;
  const userGuidesLabel = (intl) ? intl.formatMessage(
    { id: 'User Guides' }) : '???';
  const hpeProductLabel = (intl) ? intl.formatMessage(
    { id: 'HPE Products' }) : '???';
  const referenceLabel = (intl) ? intl.formatMessage(
    { id: 'Reference Documents' }) : '???';
  const supportLabel = (intl) ? intl.formatMessage(
    { id: 'HPE Support' }) : '???';

  const emailBody = encodeURIComponent(`
Please include the following debug information in order to make it easier and faster for the HPE InfoSight Portal Support team to help.

URL: ${window.location.href}
Browser: ${bowser.name} ${bowser.version}
Timestamp: ${new Date()}

Please fill out the below fields.

Region (Americas/EMEA/Asia-Pacific):
HPE Partner? (yes/no): `);

  return (
    <Menu
      inline={false}
      direction="column"
      icon={<AppsIcon />}
      dropAlign={{ left: 'left', top: 'top' }}
      colorIndex="grey-3-a"
      justify="center"
      pad={{ vertical: 'small' }}
    >
      <Menu
        label={userGuidesLabel}
        dropAlign={{ left: 'left', top: 'top' }}
        colorIndex="grey-3-a"
      >
        <Anchor href="http://www.hpe.com/support/InfoSight-GSG-en">
          <FormattedMessage id="HPE InfoSight Getting Started Guide" />
        </Anchor>
        <Anchor href="http://www.hpe.com/support/InfoSight-UG-en">
          <FormattedMessage id="HPE InfoSight User Guide" />
        </Anchor>
      </Menu>
      <Menu
        label={hpeProductLabel}
        dropAlign={{ left: 'left', top: 'top' }}
        colorIndex="grey-3-a"
      >
        <Anchor href="https://www.hpe.com/us/en/storage/hpe-primera.html">
          <FormattedMessage id="HPE Primera" />
        </Anchor>
        <Anchor href="https://www.hpe.com/us/en/storage/3par.html">
          <FormattedMessage id="HPE 3PAR StoreServ" />
        </Anchor>
        <Anchor href="https://www.hpe.com/us/en/storage/nimble.html">
          <FormattedMessage id="HPE Nimble Storage" />
        </Anchor>
        <Anchor href="https://www.hpe.com/us/en/storage/nimble-storage-dhci.html">
          <FormattedMessage id="HPE Nimble Storage dHCI" />
        </Anchor>
        <Anchor href="https://www.hpe.com/us/en/servers/rack.html">
          <FormattedMessage id="HPE Servers" />
        </Anchor>
        <Anchor href="https://www.hpe.com/us/en/integrated-systems/simplivity.html">
          <FormattedMessage id="HPE SimpliVity" />
        </Anchor>
        <Anchor href="https://www.hpe.com/us/en/storage/storeonce.html">
          <FormattedMessage id="HPE StoreOnce" />
        </Anchor>
      </Menu>

      <Menu
        label={referenceLabel}
        dropAlign={{ left: 'left', top: 'top' }}
        colorIndex="grey-3-a"
      >
        <Anchor href="https://h20195.www2.hpe.com/v2/getdocument.aspx?docname=a00067516enw">
          <FormattedMessage id="HPE InfoSight for Storage" />
        </Anchor>
        <Anchor href="https://support.hpe.com/hpesc/public/docDisplay?docLocale=en_US&docId=emr_na-a00105907en_us">
          <FormattedMessage id="HPE InfoSight Servers" />
        </Anchor>
      </Menu>

      <Menu
        label={supportLabel}
        dropAlign={{ left: 'left', top: 'top' }}
        colorIndex="grey-3-a"
      >
        <Anchor href={`mailto:infosightsupport@hpe.com?subject=%5BInfoSight%20Portal%20Support%5D%20Login%20Help%20Request&body=${emailBody}`}>
          <FormattedMessage id="HPE InfoSight General Support" />
        </Anchor>
        <Anchor href="https://h20195.www2.hpe.com/v2/getdocument.aspx?docname=a00050207enw">
          <FormattedMessage id="HPE Nimble Storage Support" />
        </Anchor>
        <Anchor href="http://h20566.www2.hpe.com/portal/site/hpsc/public/scm/home?lc=en-US&cc=us">
          <FormattedMessage id="HPE Support Center" />
        </Anchor>
      </Menu>
    </Menu>
  );
};

LoginMenu.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LoginMenu);
