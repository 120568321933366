// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

/*
 * The Util file to format the display on viewing.
 */
import React from 'react';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Timestamp from 'grommet/components/Timestamp';
import WellnessIcon from '../components/WellnessIcon';
import AppUtils from './AppUtils';

const SystemsRenderer = {

  getStoreServOpportunityIconType(system) {
    if (system.status && system.status.level === 'unknown') {
      return 'unknown';
    } else if (system.sales && system.sales.prospect && system.sales.prospect.score &&
       system.sales.prospect.score > 0) {
      return 'opportunity';
    }
    return 'blank';
  },

  getStoreServOpportunityScore(system) {
    if (system.sales && system.sales.prospect && system.sales.prospect.score) {
      return Math.ceil(system.sales.prospect.score).toFixed(0);
    }
    return null;
  },

  getStoreOnceOpportunityIconType(system) {
    if (system.sales && system.sales.level === 'opportunity' && system.sales.score > 0) {
      return 'opportunity';
    } else if (system.sales && system.sales.level === 'none') {
      return 'blank'; // iconType ==> None
    }
    return 'unknown';
  },

  getStoreOnceOpportunityScore(system) {
    if (system.sales && system.sales.score > 0) {
      return Math.ceil(system.sales.score).toFixed(0);
    }
    return null;
  },

  // Given a status.level string, returns the iconType prop to be passed to a WellnessIcon.
  // NOTE that this functon takes a level string as its argument, not a system object.
  getWellnessIconTypeFromLevel(level) {
    // Temp change as Status tag in grommet does not have value as normal
    // This pops up an error. Hence reassigned normal as OK. Need to re work on
    // how the statuses are assigned and shown.
    if (level === 'serious') return 'critical';
    if (level === 'normal') return 'ok';
    // Other severity levels map to WellnesIcon iconTypes (warning, informational, unknown)
    return level;
  },

  // Given a score number, returns the iconType prop to be passed to a WellnessIcon.
  // NOTE that this function takes a score number as its argument, not a system object.
  getWellnessIconTypeFromScore(score) {
    // NOTE: DO NOT USE if level is available. This is a fallback for when it is missing.
    if (score >= 10) return 'critical';
    if (score > 0) return 'warning';
    return 'informational';
  },

  getWellnessIconType(system) {
    const level = (system.status && system.status.level) ? system.status.level : null;
    if (level) {
      return SystemsRenderer.getWellnessIconTypeFromLevel(level);
    }
    return 'Unknown';
  },

  /* Since the API Json for StoreOnce and StoreServ Status score are same,
  maintaining it under a comman function */
  getWellnessScore(system) {
    if (system.status && system.status.score > 0) {
      return Math.ceil(system.status.score).toFixed(0);
    }
    return null;
  },

  renderStoreServOpportunityScoreIcon(system) {
    return (
      <WellnessIcon
        iconType={SystemsRenderer.getStoreServOpportunityIconType(system)}
        score={SystemsRenderer.getStoreServOpportunityScore(system)}
      />
    );
  },

  renderStoreOnceOpportunityScoreIcon(system) {
    return (
      <WellnessIcon
        iconType={SystemsRenderer.getStoreOnceOpportunityIconType(system)}
        score={SystemsRenderer.getStoreOnceOpportunityScore(system)}
      />
    );
  },

  renderWellnessScoreIcon(system) {
    return (
      <WellnessIcon
        iconType={SystemsRenderer.getWellnessIconType(system)}
        score={SystemsRenderer.getWellnessScore(system)}
      />
    );
  },

  // Renders the wellness icon for an individual status object, used in StatusSummary components.
  // NOTE that this function takes a status object as its argument, not a system object.
  renderWellnessIconForStatus(status) {
    const score = Math.ceil(status.score).toFixed(0);
    // StoreServ API currently does not return a status level property,
    // so we determine the icon type by status score if the level is missing.
    let iconType = null;
    if (status.statusType === 'opportunity') {
      iconType = 'opportunity';
    } else {
      iconType = (status.level ?
        SystemsRenderer.getWellnessIconTypeFromLevel(status.level) :
        SystemsRenderer.getWellnessIconTypeFromScore(score));
    }
    return (
      <WellnessIcon
        iconType={iconType}
        score={(score !== '0' ? score : null)}
      />
    );
  },

  renderStoreServFreeCapacityPerct(system) {
    // Should we maintain seperate Component Class ??
    return (
      (system && system.capacity && system.capacity.total) ?
        `${system.capacity.total.freePct.toFixed(1)}%` : ''
    );
  },

  renderStoreOnceFreeCapacityPerct(system) {
    // Should we maintain seperate Component Class ??
    return (
      (system && system.config && system.config.freeSpacePercent) ?
        `${system.config.freeSpacePercent.toFixed(1)}%` : ''
    );
  },

  renderStoreServSerialNumber(system) {
    if (!system.serialNumberHP) {
      return (
        <FormattedMessage
          id="SERIALNUMBERINSERV (3PAR)"
          values={{ serialNumberInserv: system.serialNumberInserv }}
        />
      );
    }
    if (system.serialNumberHP !== system.serialNumberInserv) {
      return (
        <FormattedMessage
          id="SERIALNUMBER (3PAR: SERIALNUMBERINSERV)"
          values={{
            serialNumber: system.serialNumberHP,
            serialNumberInserv: system.serialNumberInserv }}
        />
      );
    }
    return system.serialNumberHP;
  },

  renderStoreServLocation(systemObj) {
    let location = null;
    if (systemObj && systemObj.system && systemObj.system.location) {
      if (systemObj.system.location.city) {
        location = systemObj.system.location.city;
      }
      if (systemObj.system.location.state) {
        location = `${location}, ${systemObj.system.location.state}`;
      }
      // TODO get the countrycode mappings as in Piano.
      if (systemObj.system.location.country) {
        location = `${location} ( ${systemObj.system.location.country} )`;
      }
      if (systemObj.system.location.region) {
        location = `${location} - ${systemObj.system.location.region}`;
      }
    }
    return location;
  },

  renderDate(systemObj, value) {
    if (!value) return '';
    return (<Timestamp value={new Date(value)} fields="date" />);
  },

  renderDateAndTime(systemObj, value) {
    if (!value) return '';
    return (<Timestamp value={new Date(value)} />);
  },

  renderMonthAndYear(systemObj, value) {
    if (!value) return '';
    return (<Timestamp value={new Date(value)} fields={['month', 'year']} />);
  },

  renderBooleanAsYesNo(systemObj, value) {
    if (value === true) return (<FormattedMessage id="Yes" />);
    if (value === false) return (<FormattedMessage id="No" />);
    return '';
  },

  renderSystemsPerToken(systemObj) {
    const tokens = AppUtils.getNestedProperty(systemObj, 'authorized.tokens');
    const systemsPerToken = AppUtils.getNestedProperty(systemObj, 'aggregations.systemsPerToken');
    if (!tokens || tokens.length === 0) return '';
    if (!systemsPerToken) return (<FormattedMessage id="Loading..." />);
    const tenantIds = tokens.map(token => token.tenantId);
    return systemsPerToken.tokens.buckets.map((bucket) => {
      if (tenantIds.indexOf(bucket.key) === -1) return null;
      return (<div key={bucket.key}>
        <FormattedMessage id="System group with N systems" values={{ n: bucket.doc_count }} />
      </div>);
    });
  },

  renderStoreServVlunCount(system) {
    return (
      `${system.vluns.vlunCount} total (${system.vluns.activeVlunCount} active)`
    );
  },

  renderAdaptiveOptimization(systemObj) {
    const aoEnabled = AppUtils.getNestedProperty(systemObj, 'cpgs.aoEnabled');
    if (aoEnabled === true) return (<FormattedMessage id="Active" />);
    if (aoEnabled === false) return (<FormattedMessage id="Not Active" />);
    return '';
  },

  renderStoreOnceLocation(systemObj) {
    let location = null;
    if (systemObj && systemObj.custdata && systemObj.custdata.location) {
      if (systemObj.custdata.location.country) {
        location = systemObj.custdata.location.country;
      }
      if (systemObj.custdata.location.region) {
        location = `${location} - ${systemObj.custdata.location.region}`;
      }
    }
    return location;
  },

  renderMinimumSsdLifeLeftPercent(systemObj) {
    return (
      (systemObj.capacity && systemObj.capacity.byType && systemObj.capacity.byType.ssd &&
      systemObj.capacity.byType.ssd.lifeLeftPctMin) ?
        `${systemObj.capacity.byType.ssd.lifeLeftPctMin}%` : ''
    );
  },

  renderSmartStartStatus(systemObj) {
    const ssswStatus = AppUtils.getNestedProperty(systemObj, 'system.sp.ssswStatus');
    if (ssswStatus) return AppUtils.capitalizeFirstLetter(ssswStatus);
    return '';
  },

  renderStoreServDedupeRatio(systemObj) {
    const dedupeRatio = AppUtils.getNestedProperty(systemObj, 'capacity.total.dedupeRatio');
    if (typeof dedupeRatio !== 'number') return '1 : 1';
    const value = dedupeRatio < 10 ? dedupeRatio.toFixed(1) : Math.round(dedupeRatio);
    if (value > 500) return '>500 : 1';
    return `${value} : 1`;
  },

  renderCommaSeparatedStrings(systemObj, value) {
    return Array.isArray(value) ? value.join(', ') : value;
  },
};

export default SystemsRenderer;
