// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

const ConfigUtils = {
  doRolesOverlap(myRoles, rolesAllowed) {
    return ((myRoles || []).findIndex(role => rolesAllowed.indexOf(role) !== -1) !== -1);
  },

  // Defaults to true if it cannot find the metadata for this field
  isAllowed(field, roles, meta) {
    if (!meta) return true;
    const fieldMeta = field && meta.fields ? meta.fields[field.dataProp] : null;
    const rolesAllowed = fieldMeta && fieldMeta.roles ? fieldMeta.roles : [];
    return ConfigUtils.doRolesOverlap(roles, rolesAllowed);
  },

  roleRestrictOrder: (order, roles, frontendMetadata, meta) => order.filter((fieldId) => {
    const field = frontendMetadata[fieldId];
    return ConfigUtils.isAllowed(field, roles, meta);
  }),

  roleRestrictMetadata(roles, frontendMetadata, meta) {
    return Object.keys(frontendMetadata).reduce((filtered, fieldId) => {
      const field = frontendMetadata[fieldId];
      if (ConfigUtils.isAllowed(field, roles, meta)) {
        return { ...filtered, [fieldId]: field };
      }
      return filtered;
    }, {});
  },

  addRoleBasedMetadataProps(roles, frontendMetadata, roleBasedProps) {
    if (!roleBasedProps || Object.keys(roleBasedProps).length === 0) return frontendMetadata;
    return Object.keys(roleBasedProps).reduce((metadata, propKey) => {
      const propOptions = roleBasedProps[propKey];
      const match = propOptions.roleBased.find(obj => ConfigUtils.doRolesOverlap(roles, obj.roles));
      const fieldId = match ? match.field : propOptions.defaultField;
      if (!metadata[fieldId]) {
        // If this field is not in the metadata (probably removed by role restriction),
        // Prevent it from being added back in with only this new property.
        return metadata;
      }
      return { ...metadata,
        [fieldId]: { ...metadata[fieldId],
          [propKey]: propOptions.value,
        },
      };
    }, frontendMetadata);
  },

  processMetadata(roles, frontendMetadata, meta, roleBasedProps) {
    const restrictedMetadata = ConfigUtils.roleRestrictMetadata(roles, frontendMetadata, meta);
    return ConfigUtils.addRoleBasedMetadataProps(roles, restrictedMetadata, roleBasedProps);
  },

  getAllDataProps: (order, frontendMetadata) => order.reduce((props, fieldId) => {
    const field = frontendMetadata[fieldId];
    if (!field) return props;
    if (field.relatedData) return props.concat([field.dataProp, ...field.relatedData]);
    return props.concat([field.dataProp]);
  }, []),

  getAllChartDataProps: (charts, orderName, roles) => {
    const chartIds = charts.order[orderName];
    return chartIds.reduce((props, chartId) => {
      const chartProps = charts[chartId].series.reduce((propsArr, seriesObj) => {
        if (ConfigUtils.doRolesOverlap(roles, seriesObj.roles)) {
          propsArr.push(seriesObj.dataProp);
        }
        return propsArr;
      }, []);
      return props.concat(chartProps);
    }, []);
  },

  getFieldByDataProp: (frontendMetadata, dataProp) =>
    frontendMetadata[Object.keys(frontendMetadata).find(columnId =>
      (frontendMetadata[columnId].dataProp === dataProp ||
        frontendMetadata[columnId].versionRangeDataProp === dataProp))],
};

export default ConfigUtils;
