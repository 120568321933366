// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import { getMeta, getFacets, getStatusMetadata, postSystemsQuery } from './api';
import StoreWise from '../utils/StoreWise';
import FilterUtils from '../utils/FilterUtils';

export const SYSTEMS_DATA_REQUEST = 'SYSTEMS_DATA_REQUEST';
export const SYSTEMS_DATA_LOADED = 'SYSTEMS_DATA_LOADED';
export const SYSTEMS_DATA_ERROR = 'SYSTEMS_DATA_ERROR';

export const SYSTEMS_META_REQUEST = 'SYSTEMS_META_REQUEST';
export const SYSTEMS_META_LOADED = 'SYSTEMS_META_LOADED';
export const SYSTEMS_META_ERROR = 'SYSTEMS_META_ERROR';

export const SYSTEMS_FACETS_REQUEST = 'SYSTEMS_FACETS_REQUEST';
export const SYSTEMS_FACETS_LOADED = 'SYSTEMS_FACETS_LOADED';
export const SYSTEMS_FACETS_ERROR = 'SYSTEMS_FACETS_ERROR';

export const SYSTEMS_STATUS_META_REQUEST = 'SYSTEMS_STATUS_META_REQUEST';
export const SYSTEMS_STATUS_META_LOADED = 'SYSTEMS_STATUS_META_LOADED';
export const SYSTEMS_STATUS_META_ERROR = 'SYSTEMS_STATUS_META_ERROR';

const sortOrder = ['favorite:asc', 'status.score:desc'];

export function systemsMetaLoaded(result, category) {
  return { type: SYSTEMS_META_LOADED, result, category };
}

export function systemsMetaError(error, category) {
  return { type: SYSTEMS_META_ERROR, error, category };
}

// This is some nasty recursion but is not easy to parse otherwise
// and this is at least somewhat readable
let processFieldArray;

function processField(field, accum, nestedPath) {
  if (field.fields) {
    const childNestedPath = (field.objType === 'nested' ? field.fullName : null);
    return processFieldArray(field.fields, accum, childNestedPath);
  }

  // If this field is nested inside an array, include the nestedPath property.
  // Otherwise, don't include it at all.
  const nestedPathIfPresent = (nestedPath ? { nestedPath } : {});

  return {
    ...accum,
    [field.fullName]: {
      ...nestedPathIfPresent,
      roles: field.roles,
      type: field.type,
      isAnalyzed: field.isAnalyzed,
      isPersonallyIdentifiable: field.isPersonallyIdentifiable,
    },
  };
}

processFieldArray = (fields, initialAccum, nestedPath) => (
  fields.reduce((accum, field) => (
    processField(field, accum, nestedPath)
  ), initialAccum)
);

function processMeta(rawMeta) {
  const fields = processFieldArray(rawMeta.fields, {});
  return {
    fields,
  };
}

export function loadSystemsMeta(category) {
  return (dispatch, getState) => (
    StoreWise.check({
      dispatch,
      state: getState().systems[category].meta,
      request: StoreWise.prepRequest(
        { type: SYSTEMS_META_REQUEST, category },
        getMeta, category),
      response: res => dispatch(systemsMetaLoaded(processMeta(res.body), category)),
      error: err => dispatch(systemsMetaError(err, category)),
    })
  );
}

// exported for testing
export function processFacets(rawFacets) {
  const facets = rawFacets.facets.reduce((accum, facet) => {
    const sortedValues = [...facet.values].sort((valueA, valueB) => {
      if (valueA.term < valueB.term) return -1;
      if (valueA.term > valueB.term) return 1;
      return 0;
    });
    return {
      ...accum,
      [facet.fieldName]: sortedValues,
    };
  }, {});
  return facets;
}

export function systemsFacetsLoaded(result, category) {
  return { type: SYSTEMS_FACETS_LOADED, result, category };
}

export function systemsFacetsError(error, category) {
  return { type: SYSTEMS_FACETS_ERROR, error, category };
}

export function loadSystemsFacets(category, columnConfig) {
  return (dispatch, getState) => (
    dispatch(loadSystemsMeta(category)).then(() => {
      // processedMeta is the processed API metadata (/systems/_meta)
      // columnMetadata is the UI column metadata, after role restriction
      const processedMeta = getState().systems[category].meta.data;
      const columnMetadata = columnConfig([], getState().session.roles, processedMeta).metadata;
      const dataProps = FilterUtils.getAllFacetDataProps(processedMeta, columnMetadata);
      return StoreWise.check({
        dispatch,
        state: getState().systems[category].facets,
        request: StoreWise.prepRequest(
          { type: SYSTEMS_FACETS_REQUEST, category },
          getFacets, category, dataProps),
        response: res => dispatch(systemsFacetsLoaded(processFacets(res.body), category)),
        error: err => dispatch(systemsFacetsError(err, category)),
      });
    })
  );
}

export function systemsDataLoaded(result, category) {
  return { type: SYSTEMS_DATA_LOADED, result, category };
}

export function systemsDataError(error, category) {
  return { type: SYSTEMS_DATA_ERROR, error, category };
}

export function loadSystemsData(category, dataProps, filters) {
  return (dispatch, getState) => (
    Promise.all([
      dispatch(loadSystemsMeta(category)),
      StoreWise.check({
        dispatch,
        state: getState().systems[category].systemsData,
        request: StoreWise.prepRequest(
          { type: SYSTEMS_DATA_REQUEST, category },
          postSystemsQuery, category, { fields: dataProps, filters }, 50, sortOrder),
        response: res => dispatch(systemsDataLoaded(res.body, category)),
        error: err => dispatch(systemsDataError(err, category)),
      })])
  );
}

export function systemsStatusMetaLoaded(result, category) {
  return { type: SYSTEMS_STATUS_META_LOADED, result, category };
}

export function systemsStatusMetaError(error, category) {
  return { type: SYSTEMS_STATUS_META_ERROR, error, category };
}

function processStatusMetadata(rawMetadata) {
  const statuses = rawMetadata.members.reduce((statusHash, currentStatus) => ({
    ...statusHash,
    [currentStatus.id]: currentStatus,
  }), {});
  return { ...rawMetadata, statuses };
}

export function loadStatusMetadata(category) {
  return (dispatch, getState) => (
    StoreWise.check({
      dispatch,
      state: getState().systems[category].statusMetadata,
      request: StoreWise.prepRequest(
        { type: SYSTEMS_STATUS_META_REQUEST, category },
        getStatusMetadata, category),
      response: res => dispatch(systemsStatusMetaLoaded(processStatusMetadata(res.body), category)),
      error: err => dispatch(systemsStatusMetaError(err, category)),
    })
  );
}
