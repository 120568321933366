// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import Box from 'grommet/components/Box';
import FilterBuilderGroup from './FilterBuilderGroup';
import FilterBuilderNot from './FilterBuilderNot';
import FilterBuilderProperty from './FilterBuilderProperty';
import FilterUtils from '../utils/FilterUtils';

export const FilterBuilderObject = (props) => {
  const {
    filterObject,
    onChange,
    onRemove,
    onNegate,
    path,
    disabled,
    columns,
    meta,
    facets,
  } = props;

  if (FilterUtils.isGroup(filterObject)) {
    return (
      <FilterBuilderGroup
        filterObject={filterObject}
        onChange={onChange}
        onRemove={onRemove}
        onNegate={onNegate}
        path={path}
        disabled={disabled}
        columns={columns}
        meta={meta}
        facets={facets}
      />
    );
  }

  if (FilterUtils.isNot(filterObject)) {
    return (
      <FilterBuilderNot
        filterObject={filterObject}
        onChange={onChange}
        onRemove={onRemove}
        onNegate={onNegate}
        path={path}
        disabled={disabled}
        columns={columns}
        meta={meta}
        facets={facets}
      />
    );
  }

  if (FilterUtils.isF(filterObject) || FilterUtils.isNf(filterObject)) {
    return (
      <FilterBuilderProperty
        filterObject={filterObject}
        onChange={onChange}
        onRemove={onRemove}
        onNegate={onNegate}
        path={path}
        disabled={disabled}
        columns={columns}
        meta={meta}
        facets={facets}
      />
    );
  }

  // We should never get to this point, but if there is some kind of filter object
  // we don't recognize, we just dump out the JSON for now.
  return (<Box className="filter-builder-object"><p>{JSON.stringify(filterObject)}</p></Box>);
};

FilterBuilderObject.displayName = 'FilterBuilderObject';

FilterBuilderObject.propTypes = {
  filterObject: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onNegate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  columns: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired,
};

FilterBuilderObject.defaultProps = {
  disabled: false,
  onRemove: null,
};

export default FilterBuilderObject;
