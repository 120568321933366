// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Heading from 'grommet/components/Heading';

export const MDTableSectionHeading = (props) => {
  const { colSpan, title } = props;

  return (
    <tr className="md-table-section-heading">
      <td colSpan={colSpan}>
        <Heading tag="h3">
          <FormattedMessage id={title} />
        </Heading>
      </td>
    </tr>
  );
};

MDTableSectionHeading.propTypes = {
  colSpan: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

MDTableSectionHeading.displayName = 'MDTableSectionHeading';

export default MDTableSectionHeading;
