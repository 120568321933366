// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from 'grommet/components/Box';
import Button from 'grommet/components/Button';
import CloseIcon from 'grommet/components/icons/base/Close';
import CircleBangIcon from './CircleBangIcon';
import FilterBuilderFieldPicker from './FilterBuilderFieldPicker';
import FilterBuilderRawValue from './FilterBuilderRawValue';
import FilterBuilderFacetSelector from './FilterBuilderFacetSelector';
import FilterBuilderDateSelector from './FilterBuilderDateSelector';
import FilterBuilderNumericValue from './FilterBuilderNumericValue';
import FilterBuilderVersionRangeValue from './FilterBuilderVersionRangeValue';
import FilterUtils from '../utils/FilterUtils';
import ConfigUtils from '../utils/ConfigUtils';

export class FilterBuilderProperty extends Component {
  // Given a filterObject which may be either an individual property ({ f: field, operator, value })
  // or a nested filter containing one ({ nf: path, filters: { f: field, operator, value }}),
  // get the inner individual property filter.
  static getInnerFilter(filterObject) {
    if (FilterUtils.isNf(filterObject)) return filterObject.nf.filters;
    return filterObject;
  }

  // Given a new field, operator and value, check whether the field should be nested in the meta,
  // and return either an { nf: path, filters: { f }} or an { f } accordingly.
  static buildOuterFilter(meta, field, operator, value) {
    const fieldMeta = meta.fields[field];
    const newInnerFilterObject = {
      f: { field, operator, value },
    };
    if (FilterUtils.isNestedField(fieldMeta)) {
      return {
        nf: {
          path: fieldMeta.nestedPath,
          filters: { ...newInnerFilterObject },
        },
      };
    }
    return newInnerFilterObject;
  }

  constructor() {
    super();
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  onFilterChange(field, operator, value) {
    const { onChange, meta, filterObject } = this.props;
    onChange({
      ...FilterBuilderProperty.buildOuterFilter(meta, field, operator, value),
      termId: filterObject.termId,
    });
  }

  onFieldChange(newField) {
    const { filterObject } = this.props;
    const { f: { field } } = FilterBuilderProperty.getInnerFilter(filterObject);

    // We don't want to reset the operator and value unless the field is really changing.
    if (newField !== field) {
      this.onFilterChange(newField, '=', '');
    }
  }

  onValueChange(operator, value) {
    const { filterObject } = this.props;
    const { f: { field } } = FilterBuilderProperty.getInnerFilter(filterObject);
    this.onFilterChange(field, operator, value);
  }

  render() {
    const { filterObject, path, onRemove, onNegate, disabled, columns, meta, facets } = this.props;
    const { f: { field, operator, value } } = FilterBuilderProperty.getInnerFilter(filterObject);
    const isCreatingNewFilter = operator === '=' && value === '';

    const selectedFieldMeta = meta.fields[field];
    const selectedColumnObj = (selectedFieldMeta ?
      ConfigUtils.getFieldByDataProp(columns, field) : null);

    const childPath = (FilterUtils.isNestedField(selectedFieldMeta) ?
      `${path}.nf.filters.f` : `${path}.f`);

    let valueComponent = null;

    // If the field is totally unrecognized in API metadata, don't render any value selector.
    if (selectedFieldMeta) {
      if (FilterUtils.isVersionRangeField(selectedFieldMeta, selectedColumnObj)) {
        const selectedFieldFacets = facets[FilterUtils.getFacetDataProp(field, selectedFieldMeta)];
        valueComponent = (
          <FilterBuilderVersionRangeValue
            path={childPath}
            onFilterChange={this.onFilterChange}
            onValueChange={this.onValueChange}
            columnMeta={selectedColumnObj}
            currentDataProp={field}
            operatorVal={operator}
            valueVal={value}
            facetValues={selectedFieldFacets}
            disabled={disabled}
          />
        );
      } else if (FilterUtils.isFacetField(selectedFieldMeta, selectedColumnObj)) {
        const selectedFieldFacets = facets[FilterUtils.getFacetDataProp(field, selectedFieldMeta)];
        valueComponent = (
          <FilterBuilderFacetSelector
            path={childPath}
            onChange={this.onValueChange}
            filterValue={value}
            facetValues={selectedFieldFacets}
            columnMeta={selectedColumnObj}
            disabled={disabled}
          />
        );
      } else if (FilterUtils.isNumericField(selectedFieldMeta)) {
        valueComponent = (
          <FilterBuilderNumericValue
            path={childPath}
            onChange={this.onValueChange}
            operatorVal={operator}
            valueVal={value}
            disabled={disabled}
          />
        );
      } else if (FilterUtils.isDateField(selectedFieldMeta) &&
        (isCreatingNewFilter || FilterUtils.isValidDateFilter(operator, value))) {
        valueComponent = (
          <FilterBuilderDateSelector
            path={childPath}
            onChange={this.onValueChange}
            filterOperator={operator}
            filterValue={value}
            disabled={disabled}
          />
        );
      } else {
        // Only use the raw operator/value fields if we have a field that appears in API meta,
        // but doesn't fit into one of the above conditions.
        valueComponent = (
          <FilterBuilderRawValue
            path={childPath}
            onChange={this.onValueChange}
            operatorVal={operator}
            valueVal={value}
            disabled={disabled}
          />
        );
      }
    }

    return (
      <Box
        direction="row"
        className="filter-builder-object center-group-dash"
        pad={{ between: 'small' }}
      >
        <Box basis="1/3" size={{ width: { min: 'small', max: 'medium' } }}>
          <FilterBuilderFieldPicker
            id={`${childPath}.field`}
            dataProp={field}
            onChange={this.onFieldChange}
            disabled={disabled}
            columns={columns}
            meta={meta}
          />
        </Box>
        <Box basis="2/3" justify="center">
          {valueComponent}
        </Box>
        <Box justify="center">
          <Box direction="row" pad={{ between: 'small' }}>
            <Button
              icon={<CircleBangIcon />}
              onClick={!disabled ? onNegate : undefined}
            />
            <Button
              icon={<CloseIcon />}
              onClick={!disabled ? onRemove : undefined}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

FilterBuilderProperty.displayName = 'FilterBuilderProperty';

FilterBuilderProperty.propTypes = {
  filterObject: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onNegate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  columns: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired,
};

FilterBuilderProperty.defaultProps = {
  disabled: false,
};

export default FilterBuilderProperty;
