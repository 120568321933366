// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import AppUtils from '../utils/AppUtils';

const MDFatRow = (props) => {
  const { systemData, columns, order } = props;
  // Using the order ensures that we only show data that the user has selected
  // and that they are authorized to see
  const primaryIndex = order.find(columnId => columns[columnId].fatRowPrimary);
  const secondaryIndex = order.find(columnId => columns[columnId].fatRowSecondary);
  const primary = columns[primaryIndex];
  const secondary = columns[secondaryIndex];

  // Check for render function to make it future proof where, if we may have to render columns which
  // needs special formatting before it is displayed on UI, we ensure to format it before displaying
  // For example if Free Capacity % column is decided to be displayed in mobile view in future, we
  // just have to change fatRowPrimary or fatRowSecondary to true in config file. In this case, the
  // render check below will by default take care of the formatting required before displaying the
  // capacity on screen

  const fatRowPrimaryElem = (primary.render) ?
    primary.render(systemData) :
    AppUtils.getNestedProperty(systemData, primary.dataProp);

  const fatRowSecondaryElem = (secondary.render) ?
    secondary.render(systemData) :
    AppUtils.getNestedProperty(systemData, secondary.dataProp);

  const fatRowElem = (
    <div>
      <strong>{fatRowPrimaryElem}</strong>
      <br />
      {fatRowSecondaryElem}
    </div>
  );

  return fatRowElem;
};

MDFatRow.propTypes = {
  systemData: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  order: PropTypes.array.isRequired,
};

export default MDFatRow;
