// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import App from 'grommet/components/App';
import Loading from './Loading';
import SessionDirector from './SessionDirector';
import routesMetadata from '../routes';

export function InfoSight({ loading, location }) {
  // Vicious hack
  // Hide the header & footer on every page except the login page, where we explicitly un-hide it
  const headerEle = document.getElementById('header');
  const footerEle = document.getElementById('footer');
  if (location.pathname === '/login' && headerEle && footerEle) {
    // Explicitly un-hide the header & footer for the login page
    headerEle.style.display = 'block';
    footerEle.style.display = 'block';
  } else if (headerEle && footerEle) {
    // Hide the header & footer for every other page
    headerEle.style.display = 'none';
    footerEle.style.display = 'none';
  }

  const routes = Object.keys(routesMetadata).map(key => (
    <Route
      path={routesMetadata[key].path}
      key={key}
      exact={routesMetadata[key].exact}
      component={routesMetadata[key].component}
    />
  ), this);

  return (
    <App centered={false}>
      {loading
        ? <Loading />
        : <React.Fragment>
          <SessionDirector />
          <Switch>
            {routes}
            {/* We will eventually want to create a 404 page instead of using loading */}
            <Route component={Loading} />
          </Switch>
        </React.Fragment>
      }
    </App>
  );
}

InfoSight.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  location: PropTypes.object.isRequired, // See comment by export at the bottom of the file
};

const select = state => ({
  loading: state.session.loading,
});

// exported for testing
export const ConnectedInfoSight = connect(select)(InfoSight);

// this needs to be wrapped in withRouter in order to make sure this component updates whenever
// there is a location change
export default withRouter(ConnectedInfoSight);
