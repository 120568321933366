// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

// This caching module is called StoreWise after the most popular proposed
// rename for StoreFront Remote before merging with InfoSight

// Some helpful background on error handling with async actions:
// https://github.com/facebook/react/issues/7617#issuecomment-247710003

// Best practices or ES6 Promises would have you believe that you want to have a
// series of chained promises using .then followed by a final .catch. One
// problem with this approach is the one mentioned in the link above. Another,
// is that having one handler for all rejections in complex chains of Promises
// is confusing. The prefered way in this entire code base is to use the second
// argument of .then as the rejection handler and to only check rejections in
// the promise immediately after the api call.

// Another rule of thumb is that actions that depend on results of other actions
// should be looking in state for that data, NOT at the actual promise returned
// by the previous action. An example here:
// https://github.com/reactjs/redux/issues/1676#issuecomment-215413478

import isEqual from 'lodash.isequal';
import shortid from 'shortid';

// GAH!!! STATE OUTSIDE REDUX BURN IT WITH FIRE!!!
// So... take a deep breath.  Calm down.
// Now, in order for some of the more interesting Redux features to work, it
// needs the store to be fully serializeable. This means our cacher needs to be
// the one storing any promises AND we need to be able to handle when the
// promise is no longer there even though "isLoading" is true because unlike
// redux, this "store" is not ever being serialized and will obviously be lost
// on refresh even if we add some magic Redux rehydrator and that is fine.
const promises = {};

export default {
  prepRequest(requestAction, apiCall, ...args) {
    return {
      requestAction,
      args,
      send: () => apiCall(...args),
    };
  },

  check({
    dispatch,
    state,
    request: { requestAction, args, send },
    response,
    error,
  }) {
    if (state && state.previousRequest && isEqual(state.previousRequest.args, args) &&
      !state.error) {
      if (!state.isLoading) {
        // Data is already cached. Woo hoo!  The cacher was useful!
        return Promise.resolve();
      } else if (state.previousRequest.promiseId && promises[state.previousRequest.promiseId]) {
        // Request in progress, return a promise so we don't duplicate the request
        return promises[state.previousRequest.promiseId];
      }
      // if "loading" but we can't find the promise, we need to resend the request.
    }
    const requestInfo = {
      promiseId: shortid.generate(),
      args,
    };
    dispatch({ ...requestAction, request: requestInfo });

    promises[requestInfo.promiseId] = send().then(response, error);

    return promises[requestInfo.promiseId];
  },

  requestReducer(action, existingData) {
    return {
      ...existingData,
      isLoading: true,
      previousRequest: action.request,
      data: null,
      error: null,
    };
  },

  loadedReducer(action, existingData) {
    return {
      ...existingData,
      isLoading: false,
      data: action.result,
      error: null,
    };
  },

  errorReducer(action, existingData) {
    return {
      ...existingData,
      isLoading: false,
      error: action.error,
      data: null,
    };
  },
};
