// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import Box from 'grommet/components/Box';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Heading from 'grommet/components/Heading';
import Legend from 'grommet/components/Legend';
import Timestamp from 'grommet/components/Timestamp';
import Chart, { Area, Axis, Bar, Base, Grid, Layers, Line, Marker, MarkerLabel } from 'grommet/components/chart/Chart';

import HotSpots from './HotSpots';

const ChartLayoutHelper = (props) => {
  const {
    title,
    type,
    yMin,
    yMax,
    yAxisStops,
    xMin,
    xMax,
    xAxisStops,
    gridRows,
    gridCols,
    graphProps,
    numDataPoints,
    activeIndex,
    clickedIndex,
    activeX,
    legendSeries,
    maxCountHandler,
    clickHandler,
    hoverHandler,
    xAxisFields,
    small,
    normalizedValues,
    xIsMillis,
  } = props;

  const grid = gridRows && gridCols ? <Grid rows={gridRows} columns={gridCols} /> : null;
  const graphs = graphProps.map((graphProp) => {
    if (type === 'bar') {
      return (<Bar {...graphProp} />);
    } else if (type === 'area') {
      return (<Area {...graphProp} />);
    } // else 'line' is the default
    return (<Line {...graphProp} />);
  });
  const legend = legendSeries ? <Legend series={legendSeries} /> : null;

  const millisOffset = xIsMillis && (new Date().getTimezoneOffset()) % 60 !== 0 ?
    1000 * 60 * 30 : 0;
  const xAxisOrigin = {
    index: 0,
    label: (<Timestamp value={new Date(xMin + millisOffset)} fields={xAxisFields} />),
  };
  const xAxisMidpoint = {
    index: (xAxisStops - 1) / 2,
    label: (<Timestamp
      value={new Date(xMin + ((xMax - xMin) / 2) + millisOffset)}
      fields={xAxisFields}
    />),
  };
  const xAxisEndpoint = {
    index: xAxisStops - 1,
    label: (<Timestamp value={new Date(xMax + millisOffset)} fields={xAxisFields} />),
  };
  const xAxisLabels = small ? [xAxisOrigin, xAxisEndpoint]
    : [xAxisOrigin, xAxisMidpoint, xAxisEndpoint];

  return (
    <Box pad="medium">
      <Heading tag="h3" margin="none">
        <FormattedMessage id={title} />
      </Heading>
      <Chart full>
        <Axis
          count={yAxisStops}
          labels={[
            { index: 0, label: yMin.toString() },
            { index: (yAxisStops - 1) / 2, label: (((yMax - yMin) / 2) + yMin).toString() },
            { index: yAxisStops - 1, label: yMax.toString() },
          ]}
          vertical
          ticks
        />
        <Chart vertical full onMaxCount={maxCountHandler}>
          <MarkerLabel
            count={numDataPoints}
            index={activeIndex}
            label={<div>
              <Timestamp value={new Date(activeX + millisOffset)} />
              <div>{legend}</div>
            </div>}
          />
          <Base height="small" width="full" />
          <Layers>
            {grid}
            {graphs}
            <Marker count={numDataPoints} index={activeIndex} vertical />
            {clickedIndex ? <Marker count={numDataPoints} index={clickedIndex} colorIndex="accent-1" vertical /> : null}
            <HotSpots
              onActive={hoverHandler}
              onClick={clickHandler}
              normalizedValues={normalizedValues}
            />
          </Layers>
          <Axis
            count={xAxisStops}
            labels={xAxisLabels}
            // TODO: Figure out a solution for labels crowding out the tick marks on the axes
            ticks
          />
        </Chart>
      </Chart>
    </Box>
  );
};

ChartLayoutHelper.defaultProps = {
  xIsMillis: true,
};

ChartLayoutHelper.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  yMin: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired,
  yAxisStops: PropTypes.number.isRequired,
  xMin: PropTypes.number.isRequired,
  xMax: PropTypes.number.isRequired,
  xAxisStops: PropTypes.number.isRequired,
  gridRows: PropTypes.number.isRequired,
  gridCols: PropTypes.number.isRequired,
  graphProps: PropTypes.array.isRequired,
  numDataPoints: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  clickedIndex: PropTypes.number,
  activeX: PropTypes.number.isRequired,
  legendSeries: PropTypes.array.isRequired,
  maxCountHandler: PropTypes.func.isRequired,
  clickHandler: PropTypes.func.isRequired,
  hoverHandler: PropTypes.func.isRequired,
  xAxisFields: PropTypes.array.isRequired,
  small: PropTypes.bool.isRequired,
  normalizedValues: PropTypes.array.isRequired,
  xIsMillis: PropTypes.bool,
};

ChartLayoutHelper.defaultProps = {
  clickedIndex: undefined,
};

export default ChartLayoutHelper;
