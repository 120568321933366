// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Box from 'grommet/components/Box';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Menu from 'grommet/components/Menu';
import Anchor from 'grommet/components/Anchor';
import Button from 'grommet/components/Button';
import Paragraph from 'grommet/components/Paragraph';
import Label from 'grommet/components/Label';
import AddIcon from 'grommet/components/icons/base/Add';
import CloseIcon from 'grommet/components/icons/base/Close';
import TreeIcon from 'grommet/components/icons/base/Tree';
import FilterIcon from 'grommet/components/icons/base/Filter';
import CircleBangIcon from './CircleBangIcon';
import FilterBuilderObject from './FilterBuilderObject';
import AppUtils from '../utils/AppUtils';
import FilterUtils from '../utils/FilterUtils';

export class FilterBuilderGroup extends Component {
  constructor() {
    super();
    this.updateChildTerm = this.updateChildTerm.bind(this);
    this.removeChildTerm = this.removeChildTerm.bind(this);
    this.negateChildTerm = this.negateChildTerm.bind(this);
    this.addChildTerm = this.addChildTerm.bind(this);
    this.addChildProperty = this.addChildProperty.bind(this);
    this.addChildGroup = this.addChildGroup.bind(this);
    this.getChildTermChangeHandler = this.getChildTermChangeHandler.bind(this);
    this.getChildTermRemoveHandler = this.getChildTermRemoveHandler.bind(this);
    this.getChildTermNegateHandler = this.getChildTermNegateHandler.bind(this);
    this.changeGroupPropertyTo = this.changeGroupPropertyTo.bind(this);
    this.changeTypeToOr = this.changeTypeToOr.bind(this);
    this.changeTypeToAnd = this.changeTypeToAnd.bind(this);
  }

  getChildTermChangeHandler(termIndex) {
    return (newTermObject => this.updateChildTerm(termIndex, newTermObject));
  }

  getChildTermRemoveHandler(termIndex) {
    return (() => this.removeChildTerm(termIndex));
  }

  getChildTermNegateHandler(termIndex) {
    return (() => this.negateChildTerm(termIndex));
  }

  getGroupProperty() {
    const { filterObject } = this.props;
    if (FilterUtils.isAnd(filterObject)) return 'and';
    if (FilterUtils.isOr(filterObject)) return 'or';
    return null;
  }

  updateChildTerm(termIndex, newTermObject) {
    const { filterObject, onChange } = this.props;
    const groupProperty = this.getGroupProperty();
    const oldTerms = filterObject[groupProperty].terms;
    onChange({ ...filterObject,
      [groupProperty]: { ...filterObject[groupProperty],
        terms: [
          ...oldTerms.slice(0, termIndex),
          newTermObject, // We trust that the new child term has a termId
          ...oldTerms.slice(termIndex + 1),
        ],
      },
    });
  }

  removeChildTerm(termIndex) {
    const { filterObject, onChange } = this.props;
    const groupProperty = this.getGroupProperty();
    const oldTerms = filterObject[groupProperty].terms;
    onChange({ ...filterObject,
      [groupProperty]: { ...filterObject[groupProperty],
        terms: [
          ...oldTerms.slice(0, termIndex),
          ...oldTerms.slice(termIndex + 1),
        ],
      },
    });
  }

  negateChildTerm(termIndex) {
    const { filterObject } = this.props;
    const groupProperty = this.getGroupProperty();
    const oldChildTerm = filterObject[groupProperty].terms[termIndex];
    const newChildTerm = (FilterUtils.isNot(oldChildTerm) ? {
      ...oldChildTerm.not, // Child term is wrapped in a NOT, so unwrap it
    } : FilterUtils.withTermId({
      not: { ...oldChildTerm }, // Otherwise wrap it in a NOT
    }));
    this.updateChildTerm(termIndex, newChildTerm);
  }

  addChildTerm(newTermObject) {
    const { filterObject, onChange } = this.props;
    const groupProperty = this.getGroupProperty();
    onChange({ ...filterObject,
      [groupProperty]: { ...filterObject[groupProperty],
        terms: [
          ...filterObject[groupProperty].terms,
          FilterUtils.withTermId(newTermObject),
        ],
      },
    });
  }

  addChildProperty() {
    const newTermObject = { f: { field: '', operator: '=', value: '' } };
    this.addChildTerm(newTermObject);
  }

  addChildGroup() {
    // Prefer adding the opposite type of group, don't default to AND-within-AND / OR-within-OR
    const newTermProperty = (this.getGroupProperty() === 'and') ? 'or' : 'and';
    const newTermObject = { [newTermProperty]: { terms: [] } };
    this.addChildTerm(newTermObject);
  }

  changeGroupPropertyTo(newGroupProperty) {
    const { filterObject, onChange } = this.props;
    const oldGroupProperty = this.getGroupProperty();
    const newFilterObject = {
      ...filterObject, // Preserve other properties such as termId
      [newGroupProperty]: { ...filterObject[oldGroupProperty] },
    };
    // Because we used ...filterObject above to preserve termId,
    // we also preserved the old group property, so we need to delete it.
    delete newFilterObject[oldGroupProperty];
    onChange(newFilterObject);
  }

  changeTypeToOr() {
    this.changeGroupPropertyTo('or');
  }

  changeTypeToAnd() {
    this.changeGroupPropertyTo('and');
  }

  render() {
    const {
      filterObject,
      path,
      disabled,
      onRemove,
      onNegate,
      columns,
      meta,
      facets,
      intl,
    } = this.props;

    const andGroupLabelId = 'ALL of the following conditions must be satisfied (AND):';
    const andGroupColorIndex = 'accent-2';
    const orGroupLabelId = 'ONE OR MORE of the following conditions must be satisfied (OR):';
    const orGroupColorIndex = 'accent-1';

    const groupProperty = this.getGroupProperty();

    let terms;
    let thisGroupTypeLabelId;
    let otherGroupTypeLabelId;
    let thisGroupTypeColorIndex;
    let changeGroupTypeFn;
    let termAndOrLabel;

    if (FilterUtils.isAnd(filterObject)) {
      terms = filterObject.and.terms;
      thisGroupTypeLabelId = andGroupLabelId;
      otherGroupTypeLabelId = orGroupLabelId;
      thisGroupTypeColorIndex = andGroupColorIndex;
      changeGroupTypeFn = this.changeTypeToOr;
      termAndOrLabel = (<Label size="small" className="join-label-and">
        <FormattedMessage id="AND" />
      </Label>);
    } else if (FilterUtils.isOr(filterObject)) {
      terms = filterObject.or.terms;
      thisGroupTypeLabelId = orGroupLabelId;
      otherGroupTypeLabelId = andGroupLabelId;
      thisGroupTypeColorIndex = orGroupColorIndex;
      changeGroupTypeFn = this.changeTypeToAnd;
      termAndOrLabel = (<Label size="small" className="join-label-or">
        <FormattedMessage id="OR" />
      </Label>);
    } else {
      // We should never get to this point,
      // FilterBuilderObject only uses FilterBuilderGroup for ANDs and ORs.
      return null;
    }

    const groupHeaderMenu = (
      <Menu
        label={intl ? intl.formatMessage({ id: thisGroupTypeLabelId }) : thisGroupTypeLabelId}
        colorIndex={thisGroupTypeColorIndex}
        flex="grow"
      >
        <Anchor onClick={changeGroupTypeFn}>
          <FormattedMessage id={otherGroupTypeLabelId} />
        </Anchor>
      </Menu>
    );

    const rootPath = (path !== '' ? `${path}.` : '');
    const termElements = terms.map((term, termIndex) => {
      const termPath = `${rootPath}${groupProperty}.terms[${termIndex}]`;
      return (
        <FilterBuilderObject
          filterObject={term}
          onChange={this.getChildTermChangeHandler(termIndex)}
          onRemove={this.getChildTermRemoveHandler(termIndex)}
          onNegate={this.getChildTermNegateHandler(termIndex)}
          disabled={disabled}
          path={termPath}
          columns={columns}
          meta={meta}
          facets={facets}
          key={term.termId}
        />
      );
    });

    // Use a process function for AppUtils.intersperse, so we have unique keys on the labels
    const termElementsWithAndOrLabels = AppUtils.intersperse(termElements, termAndOrLabel,
      (andOrLabel, i) => React.cloneElement(andOrLabel, {
        key: `${groupProperty}-join-label-${i}`,
      }));

    return (
      <Box className={`filter-builder-group filter-builder-object group-${groupProperty}`}>
        <Box direction="row" pad={{ between: 'small' }}>
          {groupHeaderMenu}
          <Button onClick={!disabled ? onNegate : undefined} icon={<CircleBangIcon />} />
          {onRemove ? (
            <Button onClick={!disabled ? onRemove : undefined} icon={<CloseIcon />} />
          ) : null}
        </Box>
        <Box margin={{ left: 'medium' }} separator="left" className="bracket-separator">
          <Box margin={{ left: 'medium', vertical: 'medium' }}>
            {termElementsWithAndOrLabels.length > 0 ? termElementsWithAndOrLabels : (
              <Paragraph>
                <FormattedMessage id="(This Group is Empty)" /><br />
                {onRemove ? (
                  <Anchor onClick={!disabled ? onRemove : undefined}>
                    <FormattedMessage id="Remove Group" />
                  </Anchor>
                ) : null}
              </Paragraph>
            )}
          </Box>
        </Box>
        <Box
          margin={{ left: 'medium' }}
          direction="row"
          responsive={false}
        >
          <Box className="bottom-left-border-bracket" />
          <Menu
            icon={<AddIcon />}
            colorIndex={thisGroupTypeColorIndex}
          >
            <Anchor onClick={this.addChildProperty}>
              <FilterIcon />
              <FormattedMessage id="Property Filter" />
            </Anchor>
            <Anchor onClick={this.addChildGroup}>
              <TreeIcon />
              <FormattedMessage id="Logical Filter Group" />
            </Anchor>
          </Menu>
        </Box>
      </Box>
    );
  }
}

FilterBuilderGroup.displayName = 'FilterBuilderGroup';

FilterBuilderGroup.propTypes = {
  filterObject: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func, // onRemove is not required, the very top level group is not removable.
  onNegate: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  columns: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

FilterBuilderGroup.defaultProps = {
  disabled: false,
  onRemove: null,
};

export default injectIntl(FilterBuilderGroup);
