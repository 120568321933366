// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'grommet/components/Button';
import LinkPreviousIcon from 'grommet/components/icons/base/LinkPrevious';

const BackToPiano = (props) => {
  const { href, icon, label } = props;

  const match = /^\/org\/(urn[\w-:%]*)\/infrastructure\/storage\/(.*)$/.exec(href);
  const safeHref = (match && match.length === 3) ? `/org/${match[1]}/infrastructure/storage/${match[2]}` : null;

  if (safeHref) {
    return (
      <Button
        icon={icon}
        label={label}
        href={safeHref}
        primary
      />
    );
  }

  return null;
};

BackToPiano.defaultProps = {
  label: null,
  icon: <LinkPreviousIcon />,
};

BackToPiano.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.node,
  icon: PropTypes.node,
};

export default BackToPiano;
