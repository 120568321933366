// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

import { SYSTEM_DETAILS_LOAD,
  SYSTEM_DETAILS_LOAD_SUCCESS,
  SYSTEM_DETAILS_UNLOAD,
  SYSTEM_DETAILS_AGGREGATION_LOAD_SUCCESS } from '../actions/actions';

const initialState = {};

// NOTE: We do NOT automatically merge existing state
// so you must explicitely add ...state if you are not
// intending to blow away all state
const handlers = {

  [SYSTEM_DETAILS_LOAD]: () => ({ ...initialState }),

  [SYSTEM_DETAILS_UNLOAD]: () => ({ ...initialState }),

  [SYSTEM_DETAILS_LOAD_SUCCESS]: (state, action) => ({
    ...state,
    [action.systemId]: {
      ...action.result,
    },
  }),

  [SYSTEM_DETAILS_AGGREGATION_LOAD_SUCCESS]: (state, action) => ({
    ...state,
    [action.systemId]: {
      ...state[action.systemId],
      aggregations: {
        ...(state[action.systemId] ? state[action.systemId].aggregations : {}),
        ...action.result.aggregations,
      },
    },
  }),

};

export default function systemDetailsReducer(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...handler(state, action) };
}
