// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import { getProfile, putProfile } from './api';

export const PROFILE_LOAD = 'PROFILE_LOAD';
export const PROFILE_LOAD_SUCCESS = 'PROFILE_LOAD_SUCCESS';
export const PROFILE_LOAD_FAILURE = 'PROFILE_LOAD_FAILURE';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE';

export function loadProfile() {
  return (dispatch) => {
    dispatch({ type: PROFILE_LOAD });
    return getProfile().then(res => dispatch({
      type: PROFILE_LOAD_SUCCESS,
      profileData: res.body.profile,
    })).catch(error => dispatch({ type: PROFILE_LOAD_FAILURE, error }));
  };
}

export function updateProfile(profileUpdateFn) {
  return (dispatch, getState) => {
    dispatch({ type: PROFILE_UPDATE });
    return dispatch(loadProfile()).then(() => {
      const profileData = getState().profile.profileData;
      const updatedProfileData = profileUpdateFn(profileData);
      return putProfile({ profile: { ...updatedProfileData } }).then(res => dispatch({
        type: PROFILE_UPDATE_SUCCESS,
        profileData: res.body.profile,
      }));
    }).catch(error => dispatch({ type: PROFILE_UPDATE_FAILURE, error }));
  };
}
