import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { termsOfUseVersion as requiredTermsOfUseVersion } from './BaseTermsOfUse';
import NavUtils from '../utils/NavUtils';
import { clearInitialPath as clearInitialPathAction } from '../actions/session';

export const ConditionalRedirect = ({ location, to }) => {
  // Already on the right page, do nothing
  if (location.pathname === to) {
    return null;
  }

  return <Redirect to={to} />;
};

ConditionalRedirect.propTypes = {
  location: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
};

const SessionDirector = ({
  authId,
  landingPage,
  initialPath,
  email,
  verifiedEmail,
  termsOfUseVersion,
  location,
  clearInitialPath,
}) => {
  // Not logged in
  if (!authId) {
    return <ConditionalRedirect to="/login" location={location} />;
  }

  // Confirming email
  if (NavUtils.isConfirmEmailLink(location.pathname)) {
    // In this case, we are already on the right page.
    // Do NOT redirect!
    return null;
  }

  // Go to confirming email after login
  if (NavUtils.isConfirmEmailLink(initialPath)) {
    clearInitialPath();
    return <ConditionalRedirect to={initialPath} location={location} />;
  }

  // Email not verified
  if (verifiedEmail !== email) {
    return <ConditionalRedirect to="/verifyemail" location={location} />;
  }

  // Terms of Use not accepted
  if (termsOfUseVersion < requiredTermsOfUseVersion) {
    return <ConditionalRedirect to="/termsofuse" location={location} />;
  }

  // standalone is a legacy name for what is now any non-session page
  const { standalone: currentlyStandalone } = NavUtils.getRouteConfigFromPath(location.pathname);
  if (currentlyStandalone) {
    // In this case, we are already on the right page.
    // Do NOT redirect!
    return null;
  }

  // go to standalone page
  const { standalone: initialStandalone } = NavUtils.getRouteConfigFromPath(initialPath);
  if (initialStandalone) {
    clearInitialPath();
    // Go to the initial path if it is a standalone page.
    return <ConditionalRedirect to={initialPath} location={location} />;
  }

  // Send the user to the appropriate landing page
  const validLandingPageRegex = /^\/[A-Za-z].*/;
  const verifiedLandingPage = landingPage
      && landingPage.match(validLandingPageRegex)
    ? landingPage : '/landing';
  if (process.env.NODE_ENV !== 'development') {
    window.location.assign(verifiedLandingPage);
  } else {
    // In dev mode there is no real UI to direct to, so we just tell you it worked.
    // eslint-disable-next-line no-alert
    alert(`Logged in and going to ${verifiedLandingPage}! Clear your cookies to test again.`);
  }

  return null;
};

SessionDirector.propTypes = {
  authId: PropTypes.string,
  initialPath: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  landingPage: PropTypes.string.isRequired,
  clearInitialPath: PropTypes.func.isRequired,
  email: PropTypes.string,
  verifiedEmail: PropTypes.string,
  termsOfUseVersion: PropTypes.number,
};

SessionDirector.defaultProps = {
  authId: null,
  email: null,
  verifiedEmail: null,
  termsOfUseVersion: null,
};

export { SessionDirector as UnwrappedSessionDirector };

const mapStateToProps = state => ({
  authId: state.session.authId,
  initialPath: state.session.initialPath,
  landingPage: state.session.landingPage,
  email: state.session.email,
  verifiedEmail: state.session.verifiedEmail,
  termsOfUseVersion: state.session.termsOfUseVersion,
});

const mapDispatchToProps = {
  clearInitialPath: clearInitialPathAction,
};

// exported for testing
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionDirector));
