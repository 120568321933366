// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Intl from 'grommet/utils/Intl';
import Box from 'grommet/components/Box';
import CheckBox from 'grommet/components/CheckBox';
import FormattedMessage from 'grommet/components/FormattedMessage';
import FormField from 'grommet/components/FormField';
import Section from 'grommet/components/Section';
import Select from 'grommet/components/Select';
import { loadTenants, editFilter } from '../actions/actions';
import AppUtils from '../utils/AppUtils';
import ConfigUtils from '../utils/ConfigUtils';
import DebouncedTextInputField from './DebouncedTextInputField';

export class FilterBuilderVisibilitySelector extends Component {
  static getVisibilityOptionsMap(intl) {
    return {
      onlyMe: Intl.getMessage(intl, 'Only Me'),
      systemGroups: Intl.getMessage(intl, 'System Group(s)'),
      allUsers: Intl.getMessage(intl, 'All Users...'),
    };
  }

  static getStatusTypeOptionsMap(intl) {
    return {
      opportunity: {
        value: Intl.getMessage(intl, 'Opportunity'),
        apiValue: 'opportunity',
      },
      health: {
        value: Intl.getMessage(intl, 'Health'),
        apiValue: 'health',
      },
      serviceability: {
        value: Intl.getMessage(intl, 'Supportability'),
        apiValue: 'serviceability',
      },
      optimization: {
        value: Intl.getMessage(intl, 'Best Practices'),
        apiValue: 'optimization',
      },
      performance: {
        value: Intl.getMessage(intl, 'Performance'),
        apiValue: 'performance',
      },
      systemFilters: {
        value: Intl.getMessage(intl, 'System Filters'),
        apiValue: null,
      },
    };
  }


  constructor(props) {
    super();
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.onTenantSelectionChange = this.onTenantSelectionChange.bind(this);
    this.onEditPrivilegesChange = this.onEditPrivilegesChange.bind(this);
    this.onRoleRestrictionChange = this.onRoleRestrictionChange.bind(this);
    this.onStatusTypeChange = this.onStatusTypeChange.bind(this);
    this.onSubStatusTypeChange = this.onSubStatusTypeChange.bind(this);
    this.onRoleSelectionChange = this.onRoleSelectionChange.bind(this);
    this.getTenantSelectionChangeHandler = this.getTenantSelectionChangeHandler.bind(this);
    this.getRoleSelectionChangeHandler = this.getRoleSelectionChangeHandler.bind(this);

    this.state = {
      sharedWithTenants: false,
      roleRestricted: false,
    };

    const { filterId, filterState } = props;
    const filterData = filterState[filterId].filterData;
    if (filterData.roles && filterData.roles.length > 0) {
      this.state.roleRestricted = true;
    }
    if (filterData.tenants && filterData.tenants.length > 0) {
      this.state.sharedWithTenants = true;
    }
  }

  componentDidMount() {
    this.props.dispatch(loadTenants());
  }

  onVisibilityChange(event) {
    const { category, filterId, filterState, intl, editingDisabled } = this.props;
    if (!editingDisabled) {
      const visibilityOptionsMap = FilterBuilderVisibilitySelector.getVisibilityOptionsMap(intl);
      const filterData = filterState[filterId].filterData;
      const promoted = event.value === visibilityOptionsMap.allUsers;
      const sharedWithTenants = event.value === visibilityOptionsMap.systemGroups;
      this.setState({ sharedWithTenants });
      const newFilterData = { ...filterData,
        report: { ...filterData.report,
          promoted,
          statusType: promoted ? filterData.report.statusType : null,
          subStatusType: promoted ? filterData.report.subStatusType : null,
        },
        isPublic: promoted,
        isAuthorised: sharedWithTenants ? filterData.isAuthorised : false,
        tenants: sharedWithTenants ? filterData.tenants : [],
        roles: promoted ? filterData.roles : [],
      };
      this.props.dispatch(editFilter(category, filterId, newFilterData));
    }
  }

  onTenantSelectionChange(option) {
    const { category, filterId, filterState } = this.props;
    const filterData = filterState[filterId].filterData;
    const position = filterData.tenants.indexOf(option);
    const newValues = [...filterData.tenants];
    if (position !== -1) {
      // This value was checked, so remove it
      newValues.splice(position, 1);
    } else {
      // This value was not checked, so add it
      newValues.push(option);
    }
    const newFilterData = { ...filterData,
      tenants: newValues,
    };
    this.props.dispatch(editFilter(category, filterId, newFilterData));
  }

  onEditPrivilegesChange() {
    const { category, filterId, filterState } = this.props;
    const filterData = filterState[filterId].filterData;
    const newValue = !filterData.isAuthorised;
    const newFilterData = { ...filterData,
      isAuthorised: newValue,
    };
    this.props.dispatch(editFilter(category, filterId, newFilterData));
  }

  onRoleRestrictionChange() {
    const { category, filterId, filterState } = this.props;
    const newValue = !this.state.roleRestricted;
    this.setState({ roleRestricted: newValue });
    const filterData = filterState[filterId].filterData;
    const newFilterData = { ...filterData,
      // We reset the roles when we clear or check the role restriction checkbox
      roles: [],
    };
    this.props.dispatch(editFilter(category, filterId, newFilterData));
  }

  onStatusTypeChange(event) {
    const { category, filterId, filterState, editingDisabled } = this.props;
    if (!editingDisabled) {
      const filterData = filterState[filterId].filterData;
      const newFilterData = { ...filterData,
        report: { ...filterData.report,
          statusType: event.option.apiValue,
        },
      };
      this.props.dispatch(editFilter(category, filterId, newFilterData));
    }
  }

  onSubStatusTypeChange(name, value) {
    const { category, filterId, filterState } = this.props;
    this.setState({ subStatusType: value });
    const filterData = filterState[filterId].filterData;
    const newFilterData = { ...filterData,
      report: { ...filterData.report,
        subStatusType: value,
      },
    };
    this.props.dispatch(editFilter(category, filterId, newFilterData));
  }

  onRoleSelectionChange(option) {
    const { category, filterId, filterState } = this.props;
    const filterData = filterState[filterId].filterData;
    const position = filterData.roles.indexOf(option);
    const newValues = [...filterData.roles];
    if (position !== -1) {
      // This value was checked, so remove it
      newValues.splice(position, 1);
    } else {
      // This value was not checked, so add it
      newValues.push(option);
    }
    const newFilterData = { ...filterData,
      roles: newValues,
    };
    this.props.dispatch(editFilter(category, filterId, newFilterData));
  }

  getTenantSelectionChangeHandler(option) {
    return () => this.onTenantSelectionChange(option);
  }

  getRoleSelectionChangeHandler(option) {
    return () => this.onRoleSelectionChange(option);
  }

  render() {
    const {
      filterId,
      filterState,
      roles,
      tenants,
      tenantsDetails,
      intl,
      editingDisabled,
    } = this.props;

    const { roleRestricted, sharedWithTenants } = this.state;

    const filter = filterState[filterId];
    const filterData = filter.filterData;

    const visibilityOptionsMap = FilterBuilderVisibilitySelector.getVisibilityOptionsMap(intl);
    const statusTypeOptionsMap = FilterBuilderVisibilitySelector.getStatusTypeOptionsMap(intl);

    const defaultVisibilityOptions = [
      visibilityOptionsMap.onlyMe,
      visibilityOptionsMap.systemGroups,
    ];
    const visibilityOptions = ConfigUtils.doRolesOverlap(roles, 'admin') ? [
      ...defaultVisibilityOptions,
      visibilityOptionsMap.allUsers,
    ] : defaultVisibilityOptions;

    function visibilityOptionSelector(isPublic, isPromoted) {
      if (isPublic || isPromoted) {
        return visibilityOptionsMap.allUsers;
      } else if (sharedWithTenants) {
        return visibilityOptionsMap.systemGroups;
      }
      return visibilityOptionsMap.onlyMe;
    }

    const visibilityVal = visibilityOptionSelector(filterData.isPublic, filterData.report.promoted);

    const restrictableRoles = [
      'customer',
      'partner',
      'sales',
      'support',
      'engineering',
      'admin',
    ];

    const statusTypeOptions = AppUtils.objectToArrayWithKeys(statusTypeOptionsMap);

    const statusTypeVal = filterData.report.statusType ?
      statusTypeOptions.find(opt => opt.apiValue === filterData.report.statusType) :
      statusTypeOptionsMap.systemFilters;

    const showTenantSelectionFields = (visibilityVal === visibilityOptionsMap.systemGroups);
    const showAllUserFields = (ConfigUtils.doRolesOverlap(roles, 'admin') &&
        visibilityVal === visibilityOptionsMap.allUsers);


    const tenantCheckboxes = tenants.map((tenant, index) => {
      const id = `tenantSelection-${index}`;
      const tenantName = tenantsDetails[tenant] ? tenantsDetails[tenant].name : tenant;
      return (<CheckBox
        key={id}
        id={id}
        label={tenantName}
        onChange={this.getTenantSelectionChangeHandler(tenant)}
        checked={filterData.tenants.includes(tenant)}
        disabled={editingDisabled}
      />);
    });

    const tenantSelectionFields = (<div>
      <FormField label={<FormattedMessage id="Which groups?" />}>
        {tenantCheckboxes}
      </FormField>
      <FormField>
        <CheckBox
          key={'edit-privileges'}
          id={'edit-privileges'}
          label={<FormattedMessage id="Allow selected system group users to modify filter?" />}
          onChange={this.onEditPrivilegesChange}
          checked={!!filterData.isAuthorised}
          disabled={editingDisabled}
        />
      </FormField>
    </div>);

    const roleCheckboxes = restrictableRoles.map((role, index) => {
      const id = `roleSelection-${index}`;
      return (<CheckBox
        key={id}
        id={id}
        label={role.charAt(0).toUpperCase() + role.slice(1)}
        onChange={this.getRoleSelectionChangeHandler(role)}
        checked={filterData.roles ? filterData.roles.includes(role) : false}
        disabled={editingDisabled}
      />);
    });

    const allUserFields = (<div>
      <FormField>
        <CheckBox
          key={'role-restriction'}
          id={'role-restriction'}
          label={<FormattedMessage id="Role Restricted?" />}
          onChange={this.onRoleRestrictionChange}
          checked={roleRestricted}
          disabled={editingDisabled}
        />
      </FormField>
      {roleRestricted ? (
        <FormField label={<FormattedMessage id="Which roles?" />}>
          <Box direction="row" pad={{ horizontal: 'medium', vertical: 'small' }}>
            {roleCheckboxes}
          </Box>
        </FormField>
      ) : null}
      <FormField label={<FormattedMessage id="Filter Category" />}>
        <Select
          id="statusType"
          name="statusType"
          options={statusTypeOptions}
          value={statusTypeVal}
          onChange={this.onStatusTypeChange}
          className="filter-builder-bold-value"
        />
      </FormField>
      <DebouncedTextInputField
        id="subStatusType"
        name="subStatusType"
        value={filterData.report.subStatusType ? filterData.report.subStatusType : ''}
        label={<FormattedMessage id="Filter Sub-Category (optional)" />}
        onChange={this.onSubStatusTypeChange}
        disabled={editingDisabled}
        synchronous={false}
      />
    </div>);

    return (
      <Section>
        <FormField htmlFor="visibilityLevel" label={<FormattedMessage id="Who can view this filter?" />}>
          <Select
            id="visibilityLevel"
            name="visibilityLevel"
            options={visibilityOptions}
            value={visibilityVal}
            onChange={this.onVisibilityChange}
            className="filter-builder-bold-value"
          />
        </FormField>
        {showTenantSelectionFields ? tenantSelectionFields : null}
        {showAllUserFields ? allUserFields : null}
      </Section>
    );
  }
}

FilterBuilderVisibilitySelector.propTypes = {
  category: PropTypes.string.isRequired,
  filterId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  filterState: PropTypes.object.isRequired,
  roles: PropTypes.array,
  tenants: PropTypes.array,
  tenantsDetails: PropTypes.object,
  intl: intlShape.isRequired,
  editingDisabled: PropTypes.bool,
};

FilterBuilderVisibilitySelector.defaultProps = {
  roles: [],
  tenants: [],
  tenantsDetails: {},
  editingDisabled: false,
};

const select = state => ({
  filterState: state.filter,
  roles: state.session.roles,
  tenants: state.session.tenants,
  tenantsDetails: state.tenants ? state.tenants.details : {},
});

export default connect(select)(injectIntl(FilterBuilderVisibilitySelector));
