// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

import { TOU_UPDATE, TOU_UPDATE_SUCCESS, TOU_UPDATE_FAILURE } from '../actions/actions';

const initialState = {
  saving: false,
  error: null,
};

const handlers = {

  [TOU_UPDATE]: state => ({
    ...state,
    saving: true,
    error: null,
  }),

  [TOU_UPDATE_SUCCESS]: state => ({
    ...state,
    saving: false,
  }),

  [TOU_UPDATE_FAILURE]: (state, action) => ({
    ...state,
    saving: false,
    error: { ...action.error },
  }),
};

export default function termsOfUseReducer(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...handler(state, action) };
}
