
// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Box from 'grommet/components/Box';
import Menu from 'grommet/components/Menu';
import Search from 'grommet/components/Search';
import CheckBox from 'grommet/components/CheckBox';
import AppUtils from '../utils/AppUtils';

const operator = '=';
const outerParensRegex = /^\((.*)\)$/;

export class FilterBuilderFacetSelector extends Component {
  static getOptions(facetValueObjects, columnMeta, intl) {
    if (!facetValueObjects) return [];
    return facetValueObjects.map(fv => ({
      value: AppUtils.wrapInQuotesIfHasSpaces(fv.term),
      label: (columnMeta && columnMeta.renderFacetValue ?
        columnMeta.renderFacetValue(fv.term, intl) : fv.term),
    }));
  }

  static filterOptions(options, searchCriteria) {
    if (!searchCriteria) return options;
    return options.filter(option =>
      option.label.toLowerCase().includes(searchCriteria.toLowerCase()));
  }

  static getActualValues(filterValueString) {
    if (!filterValueString) return [];
    const outerParensMatch = outerParensRegex.exec(filterValueString);
    const filterStringWithoutParens = (outerParensMatch ? outerParensMatch[1] : filterValueString);
    return filterStringWithoutParens.split(' OR ').map(val => val.trim()).filter(val => val !== '');
  }

  static getSelectedOptions(options, filterValue) {
    if (!filterValue) return [];
    const actualValues = this.getActualValues(filterValue);
    return options.filter(option => actualValues.includes(option.value));
  }

  constructor(props) {
    super(props);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.getCheckboxChangeHandler = this.getCheckboxChangeHandler.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.renderSelectedSummary = this.renderSelectedSummary.bind(this);
    this.renderCheckboxes = this.renderCheckboxes.bind(this);

    this.state = {
      searchCriteria: '',
    };
  }

  onCheckboxChange(option) {
    const { filterValue, onChange } = this.props;
    const actualValues = FilterBuilderFacetSelector.getActualValues(filterValue);
    const position = actualValues.indexOf(option.value);
    const newValues = [...actualValues];
    if (position !== -1) {
      // This value was checked, so remove it
      newValues.splice(position, 1);
    } else {
      // This value was not checked, so add it
      newValues.push(option.value);
    }
    if (newValues.length === 0) {
      onChange(operator, '');
    } else if (newValues.length === 1) {
      onChange(operator, newValues[0]);
    } else {
      onChange(operator, `(${newValues.join(' OR ')})`);
    }
  }

  onSearch(event) {
    this.setState({
      searchCriteria: event.target.value,
    });
  }

  getCheckboxChangeHandler(option) {
    return () => this.onCheckboxChange(option);
  }

  renderSelectedSummary(selectedOptions) {
    const { intl } = this.props;
    if (!selectedOptions || selectedOptions.length === 0) {
      return (intl ? intl.formatMessage({ id: 'Choose Values...' }) : 'Choose Values...');
    }
    // Set a maximum character count on the summary text in order to prevent
    // the Menu from extending the surrounding Box.
    const maxSummaryCharCount = 20;
    // The initial values of the reduce call are set to correspond to the
    // first selected option and selectedOptions is consequently sliced
    // in order to ensure that the first selected option is always included
    // in the summary regardless of its character count
    const summary = selectedOptions.slice(1).reduce((acc, curr) => {
      if (acc.charCount + curr.label.length < maxSummaryCharCount) {
        return {
          summaryVals: [...acc.summaryVals, curr.label],
          charCount: acc.charCount + curr.label.length,
        };
      }
      return acc;
    }, {
      summaryVals: [selectedOptions[0].label],
      charCount: selectedOptions[0].label.length,
    }).summaryVals;
    const shouldUseEllipses = summary.length < selectedOptions.length;
    const summaryText = summary.join(', ');
    if (shouldUseEllipses) {
      return (intl ? intl.formatMessage({ id: 'N Values: a, b, c, ...' }, {
        n: selectedOptions.length, values: summaryText,
      }) : summaryText);
    }
    return summaryText;
  }

  renderCheckboxes(filteredOptions, selectedOptions) {
    const { path, disabled } = this.props;
    return filteredOptions.map((option) => {
      // Giving a CheckBox an id attribute makes its label clickable (because it has an htmlFor)
      const id = `${path}-${option.value}`;
      return (
        <CheckBox
          key={id}
          id={id}
          label={option.label}
          onChange={this.getCheckboxChangeHandler(option)}
          checked={selectedOptions.includes(option)}
          disabled={disabled}
        />
      );
    });
  }

  render() {
    const { facetValues, columnMeta, filterValue, intl } = this.props;
    const { searchCriteria } = this.state;

    const allOptions = FilterBuilderFacetSelector.getOptions(facetValues, columnMeta, intl);
    const filteredOptions = FilterBuilderFacetSelector.filterOptions(allOptions, searchCriteria);
    const selectedOptions = FilterBuilderFacetSelector.getSelectedOptions(allOptions, filterValue);

    let searchBox;

    if (facetValues.length > 10) {
      searchBox = (
        <Search
          inline
          fill
          responsive={false}
          pad="medium"
          placeHolder={(intl ? intl.formatMessage({ id: 'Search' }) : null)}
          onDOMChange={this.onSearch}
          value={searchCriteria}
        />);
    }

    return (
      <Box separator="all">
        <Menu
          label={this.renderSelectedSummary(selectedOptions)}
          closeOnClick={false}
        >
          {searchBox}
          <Box pad="small" className="filter-builder-facet-selector-options">
            {this.renderCheckboxes(filteredOptions, selectedOptions)}
          </Box>
        </Menu>
      </Box>
    );
  }
}

FilterBuilderFacetSelector.propTypes = {
  path: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  facetValues: PropTypes.arrayOf(PropTypes.shape({
    term: PropTypes.string,
    count: PropTypes.number,
  })),
  columnMeta: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  intl: intlShape.isRequired,
};

FilterBuilderFacetSelector.defaultProps = {
  facetValues: [],
  columnMeta: {},
  disabled: false,
};

export default injectIntl(FilterBuilderFacetSelector);
