// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layer from 'grommet/components/Layer';
import Box from 'grommet/components/Box';
import Heading from 'grommet/components/Heading';
import Section from 'grommet/components/Section';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Button from 'grommet/components/Button';
import {
  acceptTermsOfUse,
  logout,
} from '../actions/actions';
import ApiErrorToast from './ApiErrorToast';

// IMPORTANT: Make sure this is synchronized with 'auth.currentEulaVersion' in the API and
// OculusSession.js's CURRENT_TOU_VERSION in Piano
export const termsOfUseVersion = 4;

export class BaseTermsOfUse extends Component {
  constructor() {
    super();
    this.onAccept = this.onAccept.bind(this);
    this.onDecline = this.onDecline.bind(this);
  }

  onAccept() {
    this.props.dispatch(acceptTermsOfUse());
  }

  onDecline() {
    this.props.dispatch(logout());
  }

  render() {
    const { viewOnly, termsOfUseState, session } = this.props;
    const mailtoDebugData = encodeURIComponent(`
Please include the following debug information in order to make it easier and faster for the HPE InfoSight Portal Support team to help.

URL: ${window.location.href}
AuthID: ${session && session.authId ? session.authId : ''}
Email: ${session && session.email ? session.email : ''}
Verified Email: ${session && session.verifiedEmail ? session.verifiedEmail : ''}
Roles: ${session && session.roles ? session.roles.join(', ') : ''}
Tenants: ${session && session.tenants ? session.tenants.join(', ') : ''}`);

    const paragraph1 = (<FormattedMessage
      id="Welcome to HPE InfoSight, a service provided in conjunction with your (“Customer”, “You” or “Your”) Hewlett Packard Enterprise (“HPE”) purchase. Provided that You have not disabled the feature, Your eligible HPE products include software that transmits to HPE certain diagnostic information relating to Your products, including without limitation system performance, capacity and memory usage, performance metrics, error and information messages, resource identifiers, metadata tags, security and access roles, rules, usage policies, permissions, analytics, and hardware faults information (collectively “Diagnostic Data”).  The HPE InfoSight service provides information to You, based on such Diagnostic Data sent to HPE from Your eligible HPE products.  You have access to HPE InfoSight for so long as You maintain a valid support contract for your HPE solution. Such information may assist You, Your authorized business partner (if authorized by You), and HPE in better understanding and optimizing Your solutions."
    />);
    const paragraph2 = (<FormattedMessage
      id="HPE may use and disclose Diagnostic Data at HPE’s discretion for any purpose, except where HPE is required to do otherwise under applicable law. HPE may disclose Diagnostic Data to HPE affiliates, contractors and service providers who are working on HPE’s behalf to maintain and provide services and/or products. HPE also may use or disclose Diagnostic Data: (i) where it is in an aggregated, anonymized form that does not link such Diagnostic Data to a particular customer; or (ii) as HPE believes it to be necessary or appropriate: (a) under applicable law, which may include laws outside Your country of residence; (b) to respond to a governmental body or requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities, which may include such authorities outside Your country of residence; (c) to enforce HPE terms and conditions; and (d) to protect HPE rights, privacy, safety or property, and/or that of HPE affiliates, You or others."
    />);
    const paragraph3 = (<FormattedMessage
      id="By utilizing HPE InfoSight, You also agree and accept the Hewlett Packard Enterprise {termsOfUse}.  If You are using HPE InfoSight on behalf of an entity, You represent and warrant that You have the legal authority to bind that entity and that entity will be deemed the Customer for purposes of this HPE InfoSight Terms of Use (“These Terms”)"
      values={{
        termsOfUse: (
          <a href="https://www.hpe.com/us/en/about/legal/terms-of-use.html">
            <FormattedMessage id="Terms of Use" />
          </a>
        ),
      }}
    />);
    const paragraph4 = (<FormattedMessage
      id="If You decline, HPE InfoSight will not become active. If You would like additional information please email {supportEmail}."
      values={{
        supportEmail: (
          <a href={`mailto:infosightsupport@hpe.com?subject=%5BInfoSight%20Portal%20Support%5D%20Terms%20of%20Use%20Support&body=${mailtoDebugData}`}>
            <FormattedMessage id="infosightsupport@hpe.com" />
          </a>
        ),
      }}
    />);
    const paragraph5 = (<FormattedMessage
      id="HPE may, in its sole and absolute discretion, change these Terms (including any other documents that are referenced in or linked to from These Terms) from time to time. HPE will post notice of such changes within HPE InfoSight as applicable. If You object to any such changes, Your sole recourse shall be to cease using HPE InfoSight. Continued use of HPE InfoSight following notice of any such changes shall indicate Your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes. Certain provisions of These Terms may be superseded by expressly-designated legal notices or terms located on particular pages of HPE InfoSight and, in such circumstances, the expressly-designated legal notice or term shall be deemed to be incorporated into HPE InfoSight and to supersede the provision(s) of These Terms that are designated as being superseded."
    />);
    const message = (<Box direction="column" pad={{ between: 'medium' }}>
      {paragraph1}
      {paragraph2}
      {paragraph3}
      {paragraph4}
      {paragraph5}
    </Box>);

    const accepting = termsOfUseState.saving;

    const acceptDeclineButtons = (
      <Box direction="row" justify="end" pad={{ between: 'small' }}>
        <Button
          label={<FormattedMessage id={accepting ? 'Accepting...' : 'Accept'} />}
          onClick={accepting ? null : this.onAccept}
          primary
        />
        <Button
          label={<FormattedMessage id="Decline" />}
          onClick={this.onDecline}
        />
      </Box>
    );

    // Layer renders its contents outside the mounted context, so we can't use it for snapshots.
    const LayerComponent = (process.env.NODE_ENV === 'test' ? Box : Layer);

    // Layer has issues in IE11 when not align-"center"
    return (
      <LayerComponent closer={false} align="center">
        {termsOfUseState.error ? <ApiErrorToast apiResponse={termsOfUseState.error} /> : null}
        <Section flex={false}>
          <Heading tag="h2" strong align="center">
            <FormattedMessage id="HPE InfoSight Terms Of Use" />
          </Heading>
        </Section>
        <Section flex={false}>
          {message}
        </Section>
        {viewOnly ? null : <Section flex={false}>{acceptDeclineButtons}</Section>}
      </LayerComponent>
    );
  }
}

BaseTermsOfUse.propTypes = {
  dispatch: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired,
  termsOfUseState: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

const select = state => ({
  termsOfUseState: state.termsOfUse,
  session: state.session,
});

export default connect(select)(BaseTermsOfUse);
