// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import FormattedMessage from 'grommet/components/FormattedMessage';

const MDTableHeader = (props) => {
  let header = props.header;
  if (!header) {
    header = <FormattedMessage id={props.title} />;
  }
  return (
    <th>{header}</th>
  );
};

MDTableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.element,
};

MDTableHeader.defaultProps = {
  header: null,
};

export default MDTableHeader;
