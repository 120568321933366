// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

import StoreWise from '../utils/StoreWise';

import {
  SYSTEMS_DATA_REQUEST,
  SYSTEMS_DATA_LOADED,
  SYSTEMS_DATA_ERROR,

  SYSTEMS_META_REQUEST,
  SYSTEMS_META_LOADED,
  SYSTEMS_META_ERROR,

  SYSTEMS_FACETS_REQUEST,
  SYSTEMS_FACETS_LOADED,
  SYSTEMS_FACETS_ERROR,

  SYSTEMS_STATUS_META_REQUEST,
  SYSTEMS_STATUS_META_LOADED,
  SYSTEMS_STATUS_META_ERROR } from '../actions/actions';
import { initialOrder as storeServInitialOrder } from '../config/storeServSystems';
import { initialOrder as storeOnceInitialOrder } from '../config/storeOnceSystems';

const initialState = {
  storeserv: {
    order: storeServInitialOrder,
    systemsData: {},
    meta: {},
    facets: {},
    statusMetadata: {},
  },
  storeonce: {
    order: storeOnceInitialOrder,
    systemsData: {},
    meta: {},
    facets: {},
    statusMetadata: {},
  },
};

// handlers here will only affect their own category and will only
// receive the state from their own category as input
const handlers = {

  [SYSTEMS_DATA_REQUEST]: (state, action) => ({
    systemsData:
      // results in: isLoading: true, error: null, data: null
      StoreWise.requestReducer(action, state.systemsData),
  }),

  [SYSTEMS_DATA_LOADED]: (state, action) => ({
    systemsData:
      // results in: isLoading: false, error: null, data: action.result
      StoreWise.loadedReducer(action, state.systemsData),
  }),

  [SYSTEMS_DATA_ERROR]: (state, action) => ({
    systemsData:
      // results in: isLoading: false, error: action.error, data: null
      StoreWise.errorReducer(action, state.systemsData),
  }),

  [SYSTEMS_META_REQUEST]: (state, action) => ({
    meta:
      // results in: isLoading: true, error: null, data: null
      StoreWise.requestReducer(action, state.meta),
  }),

  [SYSTEMS_META_LOADED]: (state, action) => ({
    meta:
      // results in: isLoading: false, error: null, data: action.result
      StoreWise.loadedReducer(action, state.meta),
  }),

  [SYSTEMS_META_ERROR]: (state, action) => ({
    meta:
      // results in: isLoading: false, error: action.error, data: null
      StoreWise.errorReducer(action, state.meta),
  }),

  [SYSTEMS_FACETS_REQUEST]: (state, action) => ({
    facets:
      // results in: isLoading: true, error: null, data: null
      StoreWise.requestReducer(action, state.facets),
  }),

  [SYSTEMS_FACETS_LOADED]: (state, action) => ({
    facets:
      // results in: isLoading: false, error: null, data: action.result
      StoreWise.loadedReducer(action, state.facets),
  }),

  [SYSTEMS_FACETS_ERROR]: (state, action) => ({
    facets:
      // results in: isLoading: false, error: action.error, data: null
      StoreWise.errorReducer(action, state.facets),
  }),

  [SYSTEMS_STATUS_META_REQUEST]: (state, action) => ({
    statusMetadata:
      // results in: isLoading: true, error: null, data: null
      StoreWise.requestReducer(action, state.statusMetadata),
  }),

  [SYSTEMS_STATUS_META_LOADED]: (state, action) => ({
    statusMetadata:
      // results in: isLoading: false, error: null, data: action.result
      StoreWise.loadedReducer(action, state.statusMetadata),
  }),

  [SYSTEMS_STATUS_META_ERROR]: (state, action) => ({
    statusMetadata:
      // results in: isLoading: false, error: action.error, data: null
      StoreWise.errorReducer(action, state.statusMetadata),
  }),
};

export default function systemsReducer(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler || !action.category) return state;
  return {
    ...state,
    [action.category]: {
      ...state[action.category],
      ...handler(state[action.category], action),
    },
  };
}
