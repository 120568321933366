// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

export default {
  order: {
    performance: [
      'histAvgWriteBandwidth',
      'histAvgReadBandwidth',
      'histAvgWriteServiceTime',
      'histAvgReadServiceTime',
      'histAvgDelAcksPct',
      'histAvgMaxVVWriteServiceTime',
      'histAvgMaxVVReadServiceTime',
    ],
  },
  histAvgWriteBandwidth: {
    title: 'Average Write Bandwidth',
    type: 'line',
    yMin: 0,
    units: 'MiB/s',
    granularity: 1000 * 60 * 60,
    precision: 1e2,
    series: [
      {
        dataProp: 'portInfo.portMin.portWriteMinBandwidthMBPS',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Write Min',
        colorIndex: 'accent-3',
      },
      {
        dataProp: 'timeCorrelation.portWriteAvgBandwidthMBPSMetrics.rollingAvg7Days',
        roles: ['admin', 'sales', 'support', 'engineering'],
        label: 'Weighted Avg',
        colorIndex: 'accent-1',
      },
      {
        dataProp: 'portInfo.portWriteAvgBandwidthMBPS',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Sample Avg',
        colorIndex: 'accent-2',
      },
      {
        dataProp: 'portInfo.portMax.portWriteMaxBandwidthMBPS',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Write Max',
        colorIndex: 'brand',
      },
    ],
  },
  histAvgReadBandwidth: {
    title: 'Average Read Bandwidth',
    type: 'line',
    yMin: 0,
    units: 'MiB/s',
    granularity: 1000 * 60 * 60,
    precision: 1e2,
    series: [
      {
        dataProp: 'portInfo.portMin.portReadMinBandwidthMBPS',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Read Min',
        colorIndex: 'accent-3',
      },
      {
        dataProp: 'timeCorrelation.portReadAvgBandwidthMBPSMetrics.rollingAvg7Days',
        roles: ['admin', 'sales', 'support', 'engineering'],
        label: 'Weighted Avg',
        colorIndex: 'accent-1',
      },
      {
        dataProp: 'portInfo.portReadAvgBandwidthMBPS',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Sample Avg',
        colorIndex: 'accent-2',
      },
      {
        dataProp: 'portInfo.portMax,portReadMaxBandwidthMBPS',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Read Max',
        colorIndex: 'brand',
      },
    ],
  },
  histAvgWriteServiceTime: {
    title: 'Average Write Service Time',
    type: 'line',
    yMin: 0,
    units: 'ms',
    granularity: 1000 * 60 * 60,
    precision: 1e2,
    series: [
      {
        dataProp: 'portInfo.portMin.portWriteMinServiceTimeMillis',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Write Min',
        colorIndex: 'accent-3',
      },
      {
        dataProp: 'timeCorrelation.portWriteAvgServiceTimeMillisMetrics.rollingAvg7Days',
        roles: ['admin', 'sales', 'support', 'engineering'],
        label: 'Weighted Avg',
        colorIndex: 'accent-1',
      },
      {
        dataProp: 'portInfo.portWriteAvgServiceTimeMillis',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Sample Avg',
        colorIndex: 'accent-2',
      },
      {
        dataProp: 'portInfo.portMax.portWriteMaxServiceTimeMillis',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Write Max',
        colorIndex: 'brand',
      },
      // NOTE: Currently missing from our data is Max Access time for the day/week/month
    ],
  },
  histAvgReadServiceTime: {
    title: 'Average Read Service Time',
    type: 'line',
    yMin: 0,
    units: 'ms',
    granularity: 1000 * 60 * 60,
    precision: 1e2,
    series: [
      {
        dataProp: 'portInfo.portMin.portReadMinServiceTimeMillis',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Read Min',
        colorIndex: 'accent-3',
      },
      {
        dataProp: 'timeCorrelation.portReadAvgServiceTimeMillisMetrics.rollingAvg7Days',
        roles: ['admin', 'sales', 'support', 'engineering'],
        label: 'Weighted Avg',
        colorIndex: 'accent-1',
      },
      {
        dataProp: 'portInfo.portReadAvgServiceTimeMillis',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Sample Avg',
        colorIndex: 'accent-2',
      },
      {
        dataProp: 'portInfo.portMax.portReadMaxServiceTimeMillis',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Read Max',
        colorIndex: 'brand',
      },
      // NOTE: Currently missing from our data is Max Access time for the day/week/month
    ],
  },
  histAvgDelAcksPct: {
    title: 'Average % of Writes Resulting in DelAcks',
    type: 'line',
    yMin: 0,
    units: '%',
    granularity: 1000 * 60 * 60,
    precision: 1e2,
    series: [
      {
        dataProp: 'timeCorrelation.delAcksPctMetrics.rollingAvg7Days',
        roles: ['admin', 'sales', 'support', 'engineering'],
        label: 'Weighted Avg',
        colorIndex: 'accent-1',
      },
      {
        dataProp: 'delAcksPct',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Sample Avg',
        colorIndex: 'accent-2',
      },
    ],
  },
  histAvgMaxVVWriteServiceTime: {
    title: 'Average Max VV Write Service Time',
    type: 'line',
    yMin: 0,
    units: 'ms',
    granularity: 1000 * 60 * 60,
    precision: 1e2,
    series: [
      {
        dataProp: 'timeCorrelation.maxVVWriteServiceTimeMillisMetrics.rollingAvg7Days',
        roles: ['admin', 'sales', 'support', 'engineering'],
        label: 'Weighted Avg',
        colorIndex: 'accent-1',
      },
      {
        dataProp: 'vvInfo.vvMaxWriteAvgServiceTimeMillis',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Sample Avg',
        colorIndex: 'accent-2',
      },
    ],
  },
  histAvgMaxVVReadServiceTime: {
    title: 'Average Max VV Read Service Time',
    type: 'line',
    yMin: 0,
    units: 'ms',
    granularity: 1000 * 60 * 60,
    precision: 1e2,
    series: [
      {
        dataProp: 'timeCorrelation.maxVVReadServiceTimeMillisMetrics.rollingAvg7Days',
        roles: ['admin', 'sales', 'support', 'engineering'],
        label: 'Weighted Avg',
        colorIndex: 'accent-1',
      },
      {
        dataProp: 'vvInfo.vvMaxReadAvgServiceTimeMillis',
        roles: ['admin', 'sales', 'support', 'engineering', 'partner', 'customer'],
        label: 'Sample Avg',
        colorIndex: 'accent-2',
      },
    ],
  },
};
