// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

import { HISTORICAL_LOAD_MONTHLY,
  HISTORICAL_LOAD_MONTHLY_SUCCESS,
  HISTORICAL_LOAD_DAILY,
  HISTORICAL_LOAD_DAILY_SUCCESS,
  HISTORICAL_LOAD_PERFORMANCE,
  HISTORICAL_LOAD_PERFORMANCE_SUCCESS,
  HISTORICAL_LOAD_PERFORMANCE_FAILURE,
  HISTORICAL_UNLOAD } from '../actions/actions';

const initialState = {
  storeserv: {}, // The keys of these objects will be system IDs
  storeonce: {},
};

// Handlers here will only affect their own category and will only
// receive the state from their own category as input.
// NOTE: The category state object returned by these handlers REPLACES the existing one.
//       if data in a category needs to be preserved, it must be carried over by the handler.
const categoryHandlers = {

  [HISTORICAL_UNLOAD]: (state, action) => ({ ...initialState[action.category] }),

};


// Handlers here will only affect their own individual system and will only
// recieve the state from their own system as input.
// NOTE: The system state object returned by these handlers is MERGED by the reducer
//       with the existing state object for that system, instead of replacing it.
const systemHandlers = {

  [HISTORICAL_LOAD_MONTHLY]: () => ({ monthly: null }),

  [HISTORICAL_LOAD_MONTHLY_SUCCESS]: (state, action) => ({ monthly: action.result }),

  [HISTORICAL_LOAD_DAILY]: () => ({ daily: null }),

  [HISTORICAL_LOAD_DAILY_SUCCESS]: (state, action) => ({ daily: action.result }),

  [HISTORICAL_LOAD_PERFORMANCE]: () => ({ performance: null }),

  [HISTORICAL_LOAD_PERFORMANCE_SUCCESS]: (state, action) => ({ performance: action.result }),

  [HISTORICAL_LOAD_PERFORMANCE_FAILURE]: (state, action) => ({ performance: action.error }),

};


export default function historicalReducer(state = initialState, action) {
  const categoryHandler = categoryHandlers[action.type];
  const systemHandler = systemHandlers[action.type];
  if (action.category) {
    if (categoryHandler) {
      return {
        ...state, // Other categories
        [action.category]: categoryHandler(state[action.category], action),
      };
    }
    if (systemHandler && action.systemId) {
      return {
        ...state, // Other categories
        [action.category]: {
          ...state[action.category], // Other systems in this category
          [action.systemId]: {
            ...state[action.category][action.systemId], // Other types of data for this system
            ...systemHandler(state[action.category][action.systemId], action),
          },
        },
      };
    }
  }
  return state;
}
