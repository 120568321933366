// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import { getAuthentication, postLogin, deleteLogout, getUser } from './api';
import AppUtils from '../utils/AppUtils';
import NavUtils from '../utils/NavUtils';
import crushCookies from '../utils/crushCookies';

export const LOADING_SESSION = 'LOADING_SESSION';
export const NO_SESSION = 'NO_SESSION';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const POLLING = 'POLLING';
export const POLLING_DONE = 'POLLING_DONE';
export const CLEAR_INITIAL_PATH = 'CLEAR_INITIAL_PATH';

export function loginFailure(error) {
  return { type: LOGIN_FAILURE, error };
}

export function checkVerifiedEmail(authResponse) {
  return (dispatch) => {
    // TODO This action is not really LOGIN_SUCCESS, it's AUTHN_SUCCESS,
    // since it happens before the user is truly allowed to use the UI.  Should be renamed/reworked.
    const successAction = {
      type: LOGIN_SUCCESS,
      authId: authResponse.body.authId,
      userId: authResponse.body.userId,
      email: authResponse.body.email,
      roles: authResponse.body.roles,
      tenants: authResponse.body.tenants,
      verifiedEmail: authResponse.body.verifiedEmail,
      emailVerification: null,
      termsOfUseVersion: authResponse.body.eulaVersion,
    };

    // We only need to look up emailVerification status if we know the email is not verified.
    if (authResponse.body.verifiedEmail !== authResponse.body.email) {
      getUser()
        .then(res => dispatch({
          ...successAction,
          emailVerification: res.body.emailVerification,
        }), err => dispatch(loginFailure(err)));
    } else {
      dispatch(successAction);
    }
  };
}

export function login(username, password) {
  return (dispatch) => {
    // Remove unneeded cookies that may cause header too large issues
    crushCookies();
    dispatch({ type: LOGIN, username });
    return postLogin(username, password)
      .then((res) => {
        dispatch(checkVerifiedEmail(res));
      })
      .catch(err => dispatch(loginFailure(err)));
  };
}

export function logout() {
  return (dispatch) => {
    const logoutPromise = deleteLogout();
    // We don't wait for the logout here because even if the logout request has issues,
    // users should still be able to logout
    dispatch({ type: LOGOUT });
    return logoutPromise;
  };
}

export function parseRedirect() {
  if (NavUtils.getQueryParam(window.location, 'redirectTo')) {
    // In this case, we have a ?redirectTo param and want to get the router to go outside the UI
    const redirectUrl = NavUtils.getQueryParam(window.location, 'redirectTo');
    return typeof redirectUrl === 'string' ? decodeURIComponent(redirectUrl) : undefined;
  } else if (window.location.hash) {
    const hashpathUrl = `/InfoSight/${window.location.hash}`;
    return hashpathUrl;
  }
  return undefined;
}

export function init() {
  return (dispatch) => {
    const isLogout = NavUtils.getQueryParam(window.location, 'logout');
    // Remove unneeded cookies that may cause header too large issues
    crushCookies();
    // Do the authenticate call as early as possible so that initial page load experience is fast
    let authCall;
    if (!isLogout) {
      authCall = getAuthentication();
    }

    const landingPage = parseRedirect();

    const currentPathname = window.location.pathname + (window.location.search ?
      window.location.search : '');
    const initialPath = currentPathname.replace(process.env.PUBLIC_PATH.slice(0, -1), '');

    dispatch({ type: LOADING_SESSION, landingPage, initialPath });

    if (isLogout) {
      deleteLogout();
      return dispatch({ type: NO_SESSION });
    }


    return authCall
      .then(res => dispatch(checkVerifiedEmail(res)))
      .catch(() => dispatch({ type: NO_SESSION }));
  };
}

export function pollForEmailConfirmation(duration = 10000) {
  return (dispatch, getState) => {
    const checkIfPollingDone = () => (
      getUser()
        .then((res) => {
          // If there is no longer an email out, and we have a verified email, we're done.
          if (!res.body.emailVerification && res.body.verifiedEmail) {
            dispatch({ type: POLLING_DONE, verifiedEmail: res.body.verifiedEmail });
          } else {
            dispatch(pollForEmailConfirmation(duration));
          }
        })
        .catch(() => dispatch(pollForEmailConfirmation(60000))) // Slow down polling on errors
        // this catch is not being coverted in the unit tests as this we don't have any
        // reference to the new promise being created
    );
    // In case this action got dispatched multiple times, cancel any outstanding timeout.
    const existingTimeoutId = getState().session.pollingTimeoutId;
    if (existingTimeoutId) clearTimeout(existingTimeoutId);
    // Store the timeout ID in redux so we can clear it if necessary.
    return AppUtils.delay(duration, (timeoutId) => {
      dispatch({ type: POLLING, timeoutId });
    }).then(checkIfPollingDone);
  };
}

export function stopPollingForEmailConfirmation() {
  // The polling will stop on its own if it hits POLLING_DONE above, but if we leave the verifyemail
  // page by other means (clicked logout?) we still want to make sure the current timer is cleared.
  return (_, getState) => {
    const timeoutId = getState().session.pollingTimeoutId;
    if (timeoutId) clearTimeout(timeoutId);
  };
}

export function clearInitialPath() {
  return { type: CLEAR_INITIAL_PATH };
}
