// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Article from 'grommet/components/Article';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Header from 'grommet/components/Header';
import Heading from 'grommet/components/Heading';
import Paragraph from 'grommet/components/Paragraph';
import BackToPiano from './BackToPiano';
import CorrelatedCharts from './CorrelatedCharts';
import charts from '../config/charts';
import ConfigUtils from '../utils/ConfigUtils';
import NavUtils from '../utils/NavUtils';
import { loadPerformance, unloadHistoricalData, loadSystemDetails } from '../actions/actions';
import SystemDetailsHeader from './SystemDetailsHeader';
import config, { uiMetadata, roleBasedProps } from '../config/storeServSystems';

export class StoreServPerformance extends Component {
  componentDidMount() {
    const { match: { params: { id } } } = this.props;
    if (this.props.roles && this.props.roles.length > 0) {
      const dataProps = ConfigUtils.getAllChartDataProps(charts, 'performance', this.props.roles);
      this.props.dispatch(loadPerformance('storeserv', id, dataProps));
      this.props.dispatch(loadSystemDetails('storeserv', id, config));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(unloadHistoricalData());
  }

  render() {
    const { match: { params: { id } }, historical, systemDetails, roles } = this.props;
    const backToAddress = NavUtils.getQueryParam(this.props.location, 'backTo');

    const data = historical.storeserv[id] ? historical.storeserv[id].performance : null;
    const systemDetailsData = systemDetails[id] ? systemDetails[id] : null;
    const metadata = ConfigUtils.addRoleBasedMetadataProps([], uiMetadata, roleBasedProps);

    let pageTitle = <SystemDetailsHeader defaultHeader="Performance Details" />;

    if (systemDetailsData) {
      pageTitle = (
        <SystemDetailsHeader
          systemDetails={systemDetailsData}
          metadata={metadata}
        />
      );
    }

    const backTo = backToAddress ? (<BackToPiano href={backToAddress} />) : null;

    if (data && data.error) {
      return (
        <Article pad="medium">
          <Header>
            {backTo}
            {pageTitle}
          </Header>
          <Heading tag="h2">
            <FormattedMessage id="Advanced Performance History" />
          </Heading>
          <Paragraph size="large">
            <FormattedMessage id="There has been an error retrieving the advanced performance data. Please try again in a few minutes." />
          </Paragraph>
        </Article>
      );
    }
    if (data) {
      const emptyData = Object.values(data).filter(val =>
        Array.isArray(val) && val.length > 0).length === 0;
      if (emptyData) {
        return (
          <Article pad="medium">
            <Header>
              {backTo}
              {pageTitle}
            </Header>
            <Heading tag="h2">
              <FormattedMessage id="Advanced Performance History" />
            </Heading>
            <Paragraph size="large">
              <FormattedMessage id="There is no advanced performance history data for this system. We currently only support advanced performance analytics for all-flash systems running HPE 3PAR OS version 3.2.1 or greater." />
            </Paragraph>
          </Article>
        );
      }
    }

    return (
      <Article pad="medium">
        <Header>
          {backTo}
          {pageTitle}
        </Header>
        <Heading tag="h2">
          <FormattedMessage id="Advanced Performance History" />
        </Heading>
        <CorrelatedCharts
          chartIds={charts.order.performance}
          data={data}
          small={false}
          roles={roles}
        />
      </Article>
    );
  }
}

StoreServPerformance.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  historical: PropTypes.object.isRequired,
  systemDetails: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};

const select = state => ({
  historical: state.historical,
  systemDetails: state.systemDetails,
  roles: state.session.roles,
});

export default connect(select)(StoreServPerformance);
