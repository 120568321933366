// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import BaseTermsOfUse from './BaseTermsOfUse';

const AcceptTermsOfUse = () => (
  <BaseTermsOfUse viewOnly={false} />
);

export default AcceptTermsOfUse;
