// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import bowser from 'bowser';
import {
  LOADING_SESSION,
  NO_SESSION,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  TOU_UPDATE_SUCCESS,
  LOGOUT,
  POLLING,
  POLLING_DONE,
  EMAIL_VERIFY_CONFIRM_SUCCESS,
  CLEAR_INITIAL_PATH,
} from '../actions/actions';

const initialState = {
  loading: true,
  authId: null,
  username: null,
  email: null,
  roles: null,
  landingPage: '/landing',
  initialPath: '/landing',
  error: null,
  pollingTimeoutId: null,
  termsOfUseVersion: undefined,
  testTimestamp: undefined,
};

const handlers = {

  [LOADING_SESSION]: (state, action) => ({
    loading: true,
    landingPage: action.landingPage || state.landingPage,
    initialPath: action.initialPath || state.initialPath,
  }),

  [NO_SESSION]: () => ({
    loading: false,
  }),

  [LOGIN]: (_, action) => ({
    username: action.username,
  }),

  [LOGIN_SUCCESS]: (_, action) => ({
    loading: false,
    authId: action.authId,
    username: action.email, // Display email as username
    email: action.email,
    roles: action.roles,
    tenants: action.tenants,
    verifiedEmail: action.verifiedEmail,
    emailVerification: action.emailVerification,
    error: null,
    termsOfUseVersion: action.termsOfUseVersion,
  }),

  [LOGIN_FAILURE]: (state, action) => {
    // eslint-disable-next-line no-underscore-dangle
    const failedEmail = action && action.error && action.error.req && action.error.req._data && action.error.req._data.username ? action.error.req._data.username : '';
    const emailBody = encodeURIComponent(`
Please include the following debug information in order to make it easier and faster for the HPE InfoSight Portal Support team to help.

URL: ${window.location.href}
Email: ${failedEmail}
Browser: ${bowser.name} ${bowser.version}
Timestamp: ${state.testTimestamp || new Date()}

Please fill out the below fields.

Region (Americas/EMEA/Asia-Pacific):
HPE Partner? (yes/no): `);
    const error = action.error.body || {};
    if (action.error.status === 401) {
      switch (error.ruleNumber) {
        case '371':
          error.message = 'Locked account';
          error.resolution = 'Locked account resolution';
          error.link = 'http://www.hpe.com/info/hpesc';
          error.linkText = 'the HPE Support Center website';
          break;
        case '370':
        case '372':
        case '376':
          error.message = 'Bad username or password';
          error.resolution = 'Bad username or password resolution';
          break;
        case '373':
        case '374':
        case '375':
        case '377':
        case '378':
          error.message = 'Fields expired';
          error.resolution = 'Fields expired resolution';
          error.link = 'https://cf.passport.hpe.com/hppcf/login.do?hpappid=hppecf&lang=en&cc=us&applandingpage=https://infosight.hpe.com';
          error.linkText = 'HPE Passport';
          break;
        default:
          if (typeof error.ruleNumber === 'string') {
            error.message = 'Unexpected error';
            error.resolution = 'Unexpected error resolution';
            error.link = `mailto:infosightsupport@hpe.com?subject=%5BInfoSight%20Portal%20Support%5D%20Unexpected%20Login%20Error&body=${emailBody}`;
            error.linkText = 'infosightsupport@hpe.com';
          } else if (error.message === 'Unable to fully authenticate user') {
            error.message = 'IAM down';
            error.resolution = 'IAM down resolution';
            break;
          } else {
            error.message = 'Bad username or password';
            error.resolution = 'Bad username or password resolution';
            break;
          }
      }
    } else if (action.error.status === 403) {
      error.message = 'Unauthorized';
      error.resolution = 'Unauthorized resolution';
      error.link = `mailto:infosightsupport@hpe.com?subject=%5BInfoSight%20Portal%20Support%5D%20Unauthorized%20Login%20Error&body=${emailBody}`;
      error.linkText = 'infosightsupport@hpe.com';
    } else if (action.error.status === 504) {
      error.message = 'Timeout';
      error.resolution = 'Timeout resolution';
      error.link = `mailto:infosightsupport@hpe.com?subject=%5BInfoSight%20Portal%20Support%5D%20Timeout%20Login%20Error&body=${emailBody}`;
      error.linkText = 'infosightsupport@hpe.com';
    } else if (action.error.status === 400 && action.error.text && action.error.text.indexOf('Request Header Or Cookie Too Large') > -1) {
      error.message = 'Header error';
      error.resolution = 'Header error resolution';
      error.link = `mailto:infosightsupport@hpe.com?subject=%5BInfoSight%20Portal%20Support%5D%20Internal%20Server%20Login%20Error&body=${emailBody}`;
      error.linkText = 'infosightsupport@hpe.com';
    } else {
      error.message = 'Internal error';
      error.resolution = 'Internal error resolution';
      error.link = `mailto:infosightsupport@hpe.com?subject=%5BInfoSight%20Portal%20Support%5D%20Internal%20Server%20Login%20Error&body=${emailBody}`;
      error.linkText = 'infosightsupport@hpe.com';
    }
    return {
      loading: false,
      authId: null,
      roles: null,
      error,
    };
  },

  [LOGOUT]: () => ({
    loading: false,
    authId: null,
    username: null,
    roles: null,
  }),

  [POLLING]: (_, action) => ({
    pollingTimeoutId: action.timeoutId,
  }),

  [POLLING_DONE]: (_, action) => ({
    pollingTimeoutId: null,
    emailVerification: null,
    verifiedEmail: action.verifiedEmail,
  }),

  [EMAIL_VERIFY_CONFIRM_SUCCESS]: state => ({
    emailVerification: null,
    verifiedEmail: state.email,
  }),

  [TOU_UPDATE_SUCCESS]: (_, action) => ({
    termsOfUseVersion: action.termsOfUseVersion,
  }),

  [CLEAR_INITIAL_PATH]: () => ({
    initialPath: initialState.initialPath,
  }),
};

export default function sessionReducer(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...state, ...handler(state, action) };
}
