// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import FilterBuilder from './FilterBuilder';
import columnConfig from '../config/storeOnceSystems';

export const StoreOnceFilterBuilder = props => (
  <FilterBuilder
    category="storeonce"
    columnConfig={columnConfig}
    filterId={props.match.params.id}
    location={props.location}
  />
);

StoreOnceFilterBuilder.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default StoreOnceFilterBuilder;
