// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import DevTools from './components/DevTools';

import session from './reducers/session';
import tenants from './reducers/tenants';
import emailVerification from './reducers/emailVerification';
import systems from './reducers/systems';
import systemDetails from './reducers/systemDetails';
import historical from './reducers/historical';
import filter from './reducers/filter';
import profile from './reducers/profile';
import termsOfUse from './reducers/termsOfUse';

export default compose(
  applyMiddleware(thunk),
  DevTools.instrument(),
)(createStore)(combineReducers({
  session,
  tenants,
  emailVerification,
  systems,
  systemDetails,
  historical,
  filter,
  profile,
  termsOfUse,
}));
