// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import Box from 'grommet/components/Box';
import Status from 'grommet/components/icons/Status';
import FlagIcon from 'grommet/components/icons/base/Flag';
import CircleInformationIcon from 'grommet/components/icons/base/CircleInformation';

export const WellnessIcon = (props) => {
  const { iconType, score } = props;
  let icon = null;
  let scoreClass = null;
  if (iconType === 'opportunity') {
    icon = <FlagIcon colorIndex="brand" />;
    scoreClass = 'opportunity-icon';
  } else {
    if (iconType === 'informational') {
      icon = <CircleInformationIcon colorIndex="accent-1" />;
    } else {
      icon = <Status value={iconType} />;
    }
    scoreClass = 'wellness-icon';
  }

  return (
    <Box justify="center" className="wellness-icon-static-width">
      <Box>
        {icon}
      </Box>
      {score ? (
        <Box className={scoreClass}>
          {score}
        </Box>
      ) : null}
    </Box>
  );
};

WellnessIcon.displayName = 'WellnessIcon';

WellnessIcon.propTypes = {
  iconType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

WellnessIcon.defaultProps = {
  score: null,
};

export default WellnessIcon;
