// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import FormField from 'grommet/components/FormField';
import TextInput from 'grommet/components/TextInput';

export class DebouncedTextInputField extends PureComponent {
  constructor(props) {
    const { value } = props;
    super();
    // We keep the value of the TextInput in local state so it can update as you type,
    // without having to bubble the onChange call up to redux on every keystroke.
    this.state = { value };
    this.onInputChange = this.onInputChange.bind(this);
    this.callOnChange = debounce(this.callOnChange.bind(this), 300);
  }

  componentWillReceiveProps(nextProps) {
    // When a new value for this field does come down from redux state, that trumps local state.
    const { value } = nextProps;
    if (value !== this.state.value) {
      this.setState({ value });
    }
  }

  onInputChange(event) {
    const { name, value } = event.target;
    const { synchronous } = this.props;
    this.setState({ value });
    this.callOnChange(name, value);
    if (synchronous) this.callOnChange.flush();
  }

  // NOTE: DEBOUNCED so onChange isn't called on every keystroke
  callOnChange(name, value) {
    const { onChange } = this.props;
    onChange(name, value);
  }

  render() {
    const { id, name, label, disabled } = this.props;
    const { value } = this.state;

    const textInput = (
      <TextInput
        id={id}
        name={name}
        value={value}
        onDOMChange={this.onInputChange}
        disabled={disabled}
      />
    );

    if (id && label) {
      return (
        <FormField htmlFor={id} label={label}>
          {textInput}
        </FormField>
      );
    }

    return textInput;
  }
}

DebouncedTextInputField.displayName = 'DebouncedTextInputField';

DebouncedTextInputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  synchronous: PropTypes.bool,
};

DebouncedTextInputField.defaultProps = {
  id: null,
  value: '',
  label: null,
  disabled: false,
  synchronous: false,
};

export default DebouncedTextInputField;
