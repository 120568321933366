import Cookies from 'grommet/utils/Cookies';

// Delete unnecessary cookies in order to reduce the likeliehood of users hitting
// too big header issues.
function crushCookies() {
  let toRemove = [
    // Adobe Marketing Cloud cookies (some page tracker used by someone in HPE)
    's_cc',
    's_sq',
    's_vi',
    's_fid',
    's_ppv',
    's_ppvl',
    'mbox',

    // CTM tracking cookie
    'ctm',

    // Miscellaneous HPE cookies bigger than 25 bytes that do nothing for InfoSight
    'QSI_HistorySession',
    'HPPIDENTITY',
    'u_d',
    'utag_main',
    'c_u',
    '__CT_Data',
    'SMUSRMSG',
    'HPEProICCUID',
    'HPEProUID',
    's_ecid',
    'ELOQUA',
    'iv',
    'gss.api.gsid',
    'RELAYSTATE',
  ];
  // more Adobe Marketing Cloud cookies
  toRemove = toRemove.concat(Cookies.keys().filter(k => k.startsWith('AMC')));

  // we used to use mixpanel but no longer do so deleting the cookie
  toRemove = toRemove.concat(Cookies.keys().filter(k => k.endsWith('_mixpanel')));

  toRemove.forEach((cookie) => {
    // All the cookies being crushed so far are .hpe.com and / cookies.
    Cookies.remove(cookie, '/', '.hpe.com');
  });
}

export default crushCookies;
