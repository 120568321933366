// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Article from 'grommet/components/Article';
import Box from 'grommet/components/Box';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Header from 'grommet/components/Header';
import Section from 'grommet/components/Section';
import Heading from 'grommet/components/Heading';
import Paragraph from 'grommet/components/Paragraph';
import Button from 'grommet/components/Button';
import Spinning from 'grommet/components/icons/Spinning';
import { loadFilter,
  createDefaultFilter,
  editFilter,
  saveFilter,
  clearErrors,
  loadSystemsMeta,
  loadSystemsFacets } from '../actions/actions';
import FilterUtils from '../utils/FilterUtils';
import Loading from './Loading';
import ApiErrorToast from './ApiErrorToast';
import DebouncedTextInputField from './DebouncedTextInputField';
import DebouncedTextAreaField from './DebouncedTextAreaField';
import FilterBuilderObject from './FilterBuilderObject';
import FilterBuilderVisibilitySelector from './FilterBuilderVisibilitySelector';
import FilterBuilderPreview from './FilterBuilderPreview';
import BackToPiano from './BackToPiano';
import NavUtils from '../utils/NavUtils';

export class FilterBuilder extends Component {
  constructor() {
    super();
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
    this.onFilterTreeChange = this.onFilterTreeChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.negateFilterTree = this.negateFilterTree.bind(this);
    this.clearErrors = this.clearErrors.bind(this);

    // reportName and reportDescription are only used from local state for onCreateClick.
    // Once we are editing a filter and there is a filterObject in redux state, we use that instead.
    // TODO: Remove this local state as part of the refactor to get rid of the isNewFilter page.
    this.state = {
      reportName: '',
      reportDescription: '',
    };
  }

  componentDidMount() {
    const { category, filterId, columnConfig, roles } = this.props;
    // We make sure roles are already loaded, so we don't end up loading facets twice
    if (roles && roles.length > 0) {
      if (filterId) {
        this.props.dispatch(loadFilter(category, filterId));
      }
      this.props.dispatch(loadSystemsMeta(category));
      this.props.dispatch(loadSystemsFacets(category, columnConfig));
    }
  }

  // Called when either the name or description text fields change.
  // Note: This has nothing to do with filter fields...
  onFieldChange(name, value) {
    const { category, filterId, filterState } = this.props;
    if (filterId) {
      const filterData = filterState[filterId].filterData;
      const newFilterData = { ...filterData,
        report: { ...filterData.report,
          [name]: value,
        },
      };
      this.props.dispatch(editFilter(category, filterId, newFilterData));
    } else {
      // Only change the state if we're relying on it to render these fields,
      // otherwise they will trigger an unwanted re-render before the above editFilter event.
      // TODO: Remove this local state as part of the refactor to get rid of the isNewFilter page
      this.setState({ [name]: value });
    }
  }

  onCreateClick() {
    const { category } = this.props;
    const { reportName, reportDescription } = this.state;
    return this.props.dispatch(createDefaultFilter(category, reportName, reportDescription));
  }

  onFilterTreeChange(newFilterObject) {
    const { category, filterId, filterState } = this.props;
    const filterData = filterState[filterId].filterData;
    const newFilterData = { ...filterData,
      report: { ...filterData.report,
        reportFilter: { ...newFilterObject },
      },
    };
    return this.props.dispatch(editFilter(category, filterId, newFilterData));
  }

  onSaveClick() {
    const { category, filterId, filterState } = this.props;
    const filterData = filterState[filterId].filterData;
    return this.props.dispatch(saveFilter(category, filterId, filterData));
  }

  negateFilterTree() {
    const { filterId, filterState } = this.props;
    const oldFilterObject = filterState[filterId].filterData.report.reportFilter;
    const newFilterObject = (FilterUtils.isNot(oldFilterObject) ? {
      ...oldFilterObject.not, // Entire tree is wrapped in a NOT, so unwrap it
    } : {
      not: { ...oldFilterObject }, // Otherwise wrap it in a NOT
    });
    this.onFilterTreeChange(newFilterObject);
  }

  clearErrors() {
    const { category, filterId } = this.props;
    return this.props.dispatch(clearErrors(category, filterId));
  }

  render() {
    const {
      category,
      filterId,
      filterState,
      meta,
      columnOrder,
      columnConfig,
      roles,
      facets,
      previewLoading,
      previewError,
    } = this.props;
    const isNewFilter = !filterId;
    const filter = !isNewFilter ? filterState[filterId] : null;
    const error = !isNewFilter && filter ? filter.error : filterState.newFilterError;
    const backTo = NavUtils.getQueryParam(this.props.location, 'backTo');

    const columnConfigObj = columnConfig(columnOrder, roles, meta.data);

    const header = (
      <Header>
        {error ? (<ApiErrorToast apiResponse={error} onClose={this.clearErrors} />) : null}
        <Box direction="row" justify="center" pad={{ between: 'small' }}>
          {backTo ? <BackToPiano href={backTo} /> : null}
          <Heading tag="h2" margin="none">
            <FormattedMessage id="Advanced Filter Builder" />
          </Heading>
        </Box>
      </Header>
    );

    // We should consider adding error handling of load meta and load facets
    const loading = !isNewFilter &&
        (!filter || !filter.filterData || !facets.data || facets.isLoading);

    if (loading) {
      return (
        <Article pad="medium">
          {header}
          <Loading />
        </Article>
      );
    }

    const filterData = isNewFilter ? null : filter.filterData;
    const reportName = isNewFilter ? this.state.reportName : filterData.report.reportName;
    const reportDescription = isNewFilter ? this.state.reportDescription :
      filterData.report.reportDescription;
    const editingDisabled = filterState.creating || (!isNewFilter && filter.saving);
    const filterObject = isNewFilter ? null : filterData.report.reportFilter;

    const readyToSave = isNewFilter || FilterUtils.isReadyToSave(filterObject);
    const saveButtonEnabled = isNewFilter || (filter.hasUnsavedEdits && !filter.saving &&
        !previewLoading && !previewError && readyToSave);

    const filterTree = isNewFilter ? null : (
      <FilterBuilderObject
        filterObject={filterObject}
        onChange={this.onFilterTreeChange}
        onNegate={this.negateFilterTree}
        // Don't pass an onRemove handler, top-level group cannot be removed
        disabled={editingDisabled}
        path={''}
        columns={columnConfigObj.metadata}
        meta={meta.data}
        facets={facets.data}
      />
    );

    let saveButtonMessageId = 'Save Changes';
    if (!isNewFilter) {
      if (!filter.hasUnsavedEdits) {
        saveButtonMessageId = 'All Changes Saved';
      } else if (previewLoading) {
        saveButtonMessageId = 'Checking for Errors...';
      } else if (previewError) {
        saveButtonMessageId = 'Cannot Save with Errors';
      } else if (!readyToSave) {
        saveButtonMessageId = 'Cannot Save with Empty Criteria';
      } else if (filter.saving) {
        saveButtonMessageId = 'Saving...';
      }
    }

    const createButton = (
      <Box direction="row" align="center" pad={{ vertical: 'medium', between: 'medium' }}>
        <Button
          label={<FormattedMessage id="Create Filter" />}
          onClick={filterState.creating ? undefined : this.onCreateClick}
          primary
        />
        {filterState.creating ? <Spinning size="small" /> : null}
      </Box>
    );

    const saveButton = isNewFilter ? null : (
      <Box direction="row" align="center" pad={{ vertical: 'medium', between: 'medium' }}>
        <Button
          label={<FormattedMessage id={saveButtonMessageId} />}
          onClick={saveButtonEnabled ? this.onSaveClick : undefined}
          primary
        />
        {filter.saving ? <Spinning size="small" /> : null}
      </Box>
    );

    const pianoHrefCategory = (category === 'storeserv' ? 'systems' : category);
    const applyFilterInPianoHref = `/ui/#/${pianoHrefCategory}?f_q=customFilter%3A${filterId}`;

    const applyButton = isNewFilter ? null : (
      <Button
        label={<FormattedMessage id="Apply Filter and Return" />}
        href={!filter.hasUnsavedEdits ? applyFilterInPianoHref : undefined}
      />
    );

    const filterBuilderVisibilitySelector = (
      <FilterBuilderVisibilitySelector
        category={category}
        filterId={filterId}
        filterState={this.state.filter}
        editingDisabled={editingDisabled}
      />
    );

    return (
      <Article pad="medium">
        {header}
        <Section>
          {isNewFilter ? (
            <div>
              <Heading tag="h3">
                <FormattedMessage id="New Filter" />
              </Heading>
              <Paragraph>
                <FormattedMessage id="Please specify a name and (optional) description for your new filter. You can edit these later." />
              </Paragraph>
            </div>
          ) : (
            <Heading tag="h3">
              <FormattedMessage id="Edit Filter" />
            </Heading>
          )}
          <DebouncedTextInputField
            id="reportName"
            name="reportName"
            value={reportName}
            label={<FormattedMessage id="Filter Name" />}
            onChange={this.onFieldChange}
            disabled={editingDisabled}
            synchronous={isNewFilter}
          />
          <DebouncedTextAreaField
            id="reportDescription"
            name="reportDescription"
            value={reportDescription}
            label={<FormattedMessage id="Filter Description" />}
            onChange={this.onFieldChange}
            disabled={editingDisabled}
            synchronous={isNewFilter}
          />
          {isNewFilter ? null : filterBuilderVisibilitySelector}
          {isNewFilter ? createButton : null}
        </Section>

        {isNewFilter ? null : (
          <Section>
            {filterTree}
            <Box direction="row" align="center" pad={{ vertical: 'medium', between: 'medium' }}>
              {saveButton}
              {applyButton}
            </Box>
          </Section>
        )}

        {isNewFilter ? null : (
          <Section pad={{ vertical: 'none' }}>
            <FilterBuilderPreview
              filterObject={filterObject}
              category={category}
              columns={columnConfigObj.metadata}
              columnOrder={columnConfigObj.order}
              readyToSave={readyToSave}
            />
          </Section>
        )}

      </Article>
    );
  }
}

FilterBuilder.propTypes = {
  location: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  columnConfig: PropTypes.func.isRequired,
  columnOrder: PropTypes.array.isRequired,
  filterId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  filterState: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  facets: PropTypes.object.isRequired,
  roles: PropTypes.array,
  previewLoading: PropTypes.bool,
  previewError: PropTypes.object,
};

FilterBuilder.defaultProps = {
  filterId: null,
  roles: [],
  previewLoading: false,
  previewError: null,
};

const select = (state, props) => ({
  filterState: state.filter,
  columnOrder: state.systems[props.category].order,
  meta: state.systems[props.category].meta,
  facets: state.systems[props.category].facets,
  roles: state.session.roles,
  previewLoading: state.systems[props.category].systemsData &&
    state.systems[props.category].systemsData.isLoading,
  previewError: state.systems[props.category].systemsData &&
    state.systems[props.category].systemsData.error,
});

export default connect(select)(FilterBuilder);
