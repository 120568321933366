// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Box from 'grommet/components/Box';
import Heading from 'grommet/components/Heading';
import Spinning from 'grommet/components/icons/Spinning';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Notification from 'grommet/components/Notification';
import Intl from 'grommet/utils/Intl';
import { loadSystemsData } from '../actions/actions';
import ConfigUtils from '../utils/ConfigUtils';
import MDTable from './MDTable';
import ApiErrorToast from './ApiErrorToast';

const favoriteAndOtherSections = [{
  title: 'Favorite Systems',
  test: member => member.isFavorite,
}, {
  title: 'Other Systems',
  default: true,
}];

export class FilterBuilderPreview extends Component {
  constructor() {
    super();
    this.reloadSystemsData = this.reloadSystemsData.bind(this);
  }

  componentDidMount() {
    this.reloadSystemsData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.filterObject !== prevProps.filterObject) {
      this.reloadSystemsData();
    }
  }

  reloadSystemsData() {
    const { category, columns, columnOrder, filterObject, readyToSave } = this.props;
    const dataProps = ConfigUtils.getAllDataProps(columnOrder, columns);

    // If the filter is not "ready to save" according to the parent FilterBuilder
    // (which generally means there is some empty input)
    // don't try to load the preview.
    if (readyToSave) {
      this.props.dispatch(loadSystemsData(category, dataProps, filterObject));
    }
  }

  render() {
    const { category, columns, columnOrder, readyToSave, intl } = this.props;
    const { systemsData } = this.props.systems[category];

    if (!readyToSave) {
      return null;
    }

    if (systemsData && systemsData.isLoading) {
      return (
        <div>
          <Box direction="row" align="center" pad={{ horizontal: 'medium', between: 'medium' }}>
            <Heading tag="h2" margin="none">
              <FormattedMessage id="Loading Preview..." />
            </Heading>
            <Spinning size="medium" />
          </Box>
          <Box full />
        </div>
      );
    }

    if (systemsData && systemsData.error) {
      if (systemsData && systemsData.error.status === 502) {
        return (
          <Notification
            status="warning"
            message={Intl.getMessage(intl, 'There is an error in your filter')}
          />
        );
      }
      return (<ApiErrorToast apiResponse={systemsData.error} />);
    }

    if (!systemsData || !systemsData.data ||
        !systemsData.data.total || systemsData.data.total === 0) {
      return (
        <Heading tag="h2">
          <FormattedMessage id="Your filter currently matches 0 systems." />
        </Heading>
      );
    }

    return (
      <div>
        <Heading tag="h2">
          <FormattedMessage
            id="Your filter currently matches N systems:"
            values={{ n: systemsData.data.total }}
          />
        </Heading>

        <MDTable
          data={systemsData.data}
          sections={favoriteAndOtherSections}
          order={columnOrder}
          columns={columns}
          category={category}
          selectionDisabled
        />

        {systemsData.data.count !== systemsData.data.total ? (
          <Heading tag="h3">
            <FormattedMessage
              id="(Showing {count} of {total})"
              values={{ count: systemsData.data.count, total: systemsData.data.total }}
            />
          </Heading>
        ) : null}
      </div>
    );
  }
}

FilterBuilderPreview.propTypes = {
  filterObject: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  columns: PropTypes.object.isRequired,
  columnOrder: PropTypes.array.isRequired,
  readyToSave: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  systems: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

const select = state => ({
  systems: state.systems,
});

export default connect(select)(injectIntl(FilterBuilderPreview));
