// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import Section from 'grommet/components/Section';
import Footer from 'grommet/components/Footer';

const Loading = props => (
  <Section
    align="center"
    full={props.full}
  >
    <img src={`${process.env.PUBLIC_PATH}img/hpe-logo.svg`} alt="HPE Logo" />
    <img src={`${process.env.PUBLIC_PATH}img/time-passes.gif`} alt="Time Passes" />
    <span style={{ flexGrow: 1 }} />
    <Footer
      direction="row"
      size="small"
      justify="center"
      pad={{ horizontal: 'medium', vertical: 'small', between: 'small' }}
    >
      <span className="secondary">
        &copy; 2018 Hewlett Packard Enterprise Company, L.P.
      </span>
    </Footer>
  </Section>
);

Loading.propTypes = {
  full: Section.propTypes.full,
};

Loading.defaultProps = {
  full: true,
};

export default Loading;
