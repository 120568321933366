// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import BaseTermsOfUse from './BaseTermsOfUse';

const ViewTermsOfUse = props => (
  <BaseTermsOfUse location={props.location} viewOnly />
);

ViewTermsOfUse.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ViewTermsOfUse;
