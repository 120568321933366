// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

export default {
  InfoSight: 'HPE InfoSight',
  'InfoSight Subtitle': 'AI-Driven Operations for Hybrid Cloud',
  Yes: 'Yes',
  No: 'No',
  Dashboard: 'Dashboard',
  Wellness: 'Wellness',
  Health: 'Health',
  Opportunity: 'Opportunity',
  Supportability: 'Supportability',
  'Best Practices': 'Best Practices',
  Performance: 'Performance',
  'System Filters': 'System Filters',
  'Close Menu': 'Close Menu',
  'Log in to new UI': 'Log in to new UI',
  'HPE Employee Access to HPE InfoSight': ' HPE Employee Access to HPE InfoSight',
  'HPE Employee Access to HPE InfoSight steps': `Please make sure you log in with your HPE email address and
    HPE LDAP/NT password. If you do not already
    have access follow the steps below to request access:`,
  'Open Self-Service Manager': ' Use {link} to open a "Self-Service" ticket',
  'Search and Select HPE InfoSight': `Search for and select "3par legacy applications",
    then select "3par-storefront remote access".`,
  'Confirm your email address is accurate': 'Confirm your email address is accurate',
  'Select Single User': 'Select Single User',
  'Note for Access Request': `Note: If you’re making the request on behalf of multiple users
    and/or a department, attach the entire list of users
    (name and email address) by selecting "Add File".`,
  'Business Justification': `Put the business justification for needed
    access in the Request Summary field.`,
  'Request Summary Note': `Note: If you would like to be able to generate secure passwords,
    please make sure to give your justification for secure password
    generation in the 'Request Summary' field.`,
  'Default for Category Field': 'Leave the default “request for service” in the Category field.',
  'Read Problem Description': `Read the "Required Information" section of the Problem Description,
    and specify the group you belong to.`,
  'Choose Add File': 'Choose Add File and attach your manager’s approval.',
  'Please specify which type of user you are': 'Please specify which type of user you are.',
  'Customer or Partner': 'Customer or Partner',
  'HPE Employee': 'HPE Employee',
  'HPE Customer': 'HPE Customer',
  'Nimble Legacy': 'Nimble Legacy',
  Cancel: 'Cancel',
  Email: 'Email',
  Username: 'Username',
  Password: 'Password',
  'Log In': 'Sign in',
  'Create Account': 'Create Account',
  'Create Account Intro': 'or, create an account. It\'s free!',
  'Employee Login LDAP Warning': `If you intend to log in as an HPE employee,
    make sure you use your HPE LDAP/NT
    password to log in. If instead you want to use the customer/partner version of HPE InfoSight,
    you can log in with your Passport email address and password. We strongly recommend using a
    different password for your HPE LDAP/NT and Passport accounts.`,
  'Note to Internal Users': `NOTE: Internal HPE users should make sure to
    use their NT password to log in {link}`,
  'Learn More': 'LEARN MORE',
  'Learn more': 'Learn more',
  'User Guides': 'User Guides',
  'HPE InfoSight Getting Started Guide': 'HPE InfoSight Getting Started Guide',
  'HPE InfoSight User Guide': 'HPE InfoSight User Guide',
  'HPE Products': 'HPE Products',
  'HPE Nimble Storage': 'HPE Nimble Storage',
  'HPE Nimble Storage dHCI': 'HPE Nimble Storage dHCI',
  'HPE 3PAR StoreServ': 'HPE 3PAR StoreServ',
  'HPE StoreOnce': 'HPE StoreOnce',
  'HPE Primera': 'HPE Primera',
  'HPE Servers': 'HPE Servers',
  'HPE SimpliVity': 'HPE SimpliVity',
  'Reference Documents': 'Reference Documents',
  'HPE InfoSight for Storage': 'HPE InfoSight for Storage',
  'HPE InfoSight Servers': 'HPE InfoSight Servers',
  'HPE InfoSight General Support': 'HPE InfoSight General Support',
  'HPE Nimble Storage Support': 'HPE Nimble Storage Support',
  'HPE Support Center': 'HPE Support Center',
  'Having problems logging in?': 'Having problems logging in?',
  'HPE Passport': 'HPE Passport',
  'Locked account': 'Too many failed attempts; your account has been locked.',
  'Locked account resolution': 'Please unlock your account by resetting your Passport password through the "Forgot password" process at {link} before trying again.',
  'Bad username or password': 'Wrong username or password.',
  'Bad username or password resolution': `Please try again. Be sure you are either using your
    HPE Passport credentials (for customers and partners) or HPE email address and HPE LDAP/NT
    password (for internal HPE employees). Legacy Nimble InfoSight account users should create an
    HPE Passport account with the same email address for continued access to InfoSight.`,
  'Fields expired': 'Your HPE Passport account has expired fields.',
  'Fields expired resolution': `Please log in to the {link} site to be
    redirected to update your account.`,
  'Unexpected error': 'An unexpected error has occurred.',
  'Unexpected error resolution': 'If the issue persists, please contact us at: {link}',
  'IAM down': 'Unable to fully authenticate user.',
  'IAM down resolution': 'We are experiencing some problems authenticating users. Please try again later.',
  Unauthorized: 'You do not have permission to log in.',
  'Unauthorized resolution': 'In order to get access to HPE InfoSight, please contact us at : {link}',
  Timeout: 'The log in request has timed out.',
  'Timeout resolution': `Please wait a few minutes and try again. If you continue to get this
    error, please contact us at: {link}`,
  'Header error': 'The log in failed because the request had too many cookies.',
  'Header error resolution': `Please clear your cookies, refresh your browser, and try again. If you continue to get this
    error, please contact us at: {link}`,
  'Internal error': 'The log in request failed due to temporary connectivity issues.',
  'Internal error resolution': `Please wait 5 minutes and retry. If you continue to get this
    error, please contact us at: {link}`,
  storeserv: 'storeserv',
  'Wellness Type': 'Wellness Type',
  'Capacity Efficiency': 'Capacity Efficiency',
  Deduplication: 'Deduplication',
  storeonce: 'storeonce',
  'StoreOnce OS Versions': 'HPE StoreOnce OS Versions',
  'StoreOnce Model': 'StoreOnce Model',
  'StoreServ Systems': 'StoreServ Systems',
  'StoreOnce Systems': 'StoreOnce Systems',
  'StoreServ Performance': 'StoreServ Performance',
  UNKNOWN_STATUS: 'unknown',
  OPPORTUNITY_STATUS: 'ok',
  NONE_STATUS: 'critical',
  'Error Retrieving Data': 'Error Retrieving Data',
  'No Data Available': 'No Data Available',
  'There has been an error retrieving the advanced performance data. Please try again in a few minutes.': 'There has been an error retrieving the advanced performance data. Please try again in a few minutes.',
  'There is no advanced performance history data for this system. We currently only support advanced performance analytics for all-flash systems running HPE 3PAR OS version 3.2.1 or greater.': 'There is no advanced performance history data for this system. We currently only support advanced performance analytics for all-flash systems running HPE 3PAR OS version 3.2.1 or greater.',
  'Wellness Category': 'Wellness Category',
  'Wellness Status': 'Wellness Status',
  Back: 'Back',
  'System Details': 'System Details',
  General: 'General',
  Overview: 'Overview',
  'HPE InfoSight Registered to': 'HPE InfoSight Registered to',
  'Loading...': 'Loading...',
  'System group with N systems': 'System group with {n} systems',
  'Advanced Performance History': 'Advanced Performance History',
  'Average Read Bandwidth': 'Average Read Bandwidth',
  'Average Write Bandwidth': 'Average Write Bandwidth',
  'Average % of Writes Resulting in DelAcks': 'Average % of Writes Resulting in DelAcks',
  'Average Max VV Write Service Time': 'Average Max VV Write Service Time',
  'Average Max VV Read Service Time': ' Average Max VV Read Service Time',
  Active: 'Active',
  'Not Active': 'Not Active',
  Status: 'Status',
  'This is an early beta version of the HPE InfoSight mobile experience made available for user feedback.': 'This is an early beta version of the HPE InfoSight mobile experience made available for user feedback.',
  'SERIALNUMBERINSERV (3PAR)': '{serialNumberInserv} (3PAR)',
  'SERIALNUMBER (3PAR: SERIALNUMBERINSERV)': '{serialNumber} (3PAR: {serialNumberInserv})',
  'Favorite Systems': 'Favorite Systems',
  'Other Systems': 'Other Systems',
  'Performance Details': 'Performance Details',
  'Haven\'t logged in before?': 'Haven\'t logged in before?',
  'Advanced Filter Builder': 'Advanced Filter Builder',
  'This filter does not exist, or you do not have permission to view it.': 'This filter does not exist, or you do not have permission to view it.',
  'New Filter': 'New Filter',
  'Please specify a name and (optional) description for your new filter. You can edit these later.': 'Please specify a name and (optional) description for your new filter. You can edit these later.',
  'Filter Name': 'Filter Name',
  'Filter Description': 'Filter Description',
  'Create Filter': 'Create Filter',
  'Edit Filter': 'Edit Filter',
  'Save Changes': 'Save Changes',
  'Saving...': 'Saving...',
  'All Changes Saved': 'All Changes Saved',
  'Checking for Errors...': 'Checking for Errors...',
  'Cannot Save with Errors': 'Cannot Save with Errors',
  'Cannot Save with Empty Criteria': 'Cannot Save with Empty Criteria',
  'Apply Filter and Return': 'Apply Filter and Return',
  'There was an error in completing your request. Please try again.': 'There was an error in completing your request. Please try again.',
  'User data cannot be saved at this time.': 'User data cannot be saved at this time.',
  'We are currently backing up our database. Please try to save your data again after about 20 minutes.': 'We are currently backing up our database. Please try to save your data again after about 20 minutes.',
  'ALL of the following conditions must be satisfied (AND):': 'ALL of the following conditions must be satisfied (AND):',
  'ONE OR MORE of the following conditions must be satisfied (OR):': 'ONE OR MORE of the following conditions must be satisfied (OR):',
  '(This Group is Empty)': '(This Group is Empty)',
  'Add Criteria': 'Add Criteria',
  'Property Filter': 'Property Filter (Field, Value)',
  'Logical Filter Group': 'Logical Filter Group (AND/OR)',
  AND: 'AND',
  OR: 'OR',
  NOT: 'NOT',
  Negate: 'Negate',
  Remove: 'Remove',
  'Remove Group': 'Remove Group',
  Field: 'Field',
  Operator: 'Operator',
  Value: 'Value',
  'Find a Field': 'Find a Field',
  'Unknown Field': 'Unknown Field',
  'Choose Values...': 'Choose Values...',
  'N Values: a, b, c, ...': '{n} values: {values}, ...',
  Search: 'Search',
  'An error occurred fetching data': 'An error occurred fetching data',
  'Multiple errors occurred fetching data': 'Multiple errors occurred fetching data',
  'First error: error': 'First error: {error}',
  'Before (relative)': 'Before (relative)',
  'After (relatiive)': 'After (relative)',
  'Before (specific)': 'Before (specific)',
  'After (specific)': 'After (specific)',
  Days: 'Days',
  Months: 'Months',
  Years: 'Years',
  Ago: 'Ago',
  'From Now': 'From Now',
  'Who can view this filter?': 'Who can view this filter?',
  'Which groups?': 'Which groups?',
  'Allow selected system group users to modify filter?': 'Allow selected system group users to modify filter?',
  'Role Restricted?': 'Role Restricted?',
  'Which roles?': 'Which roles?',
  'Filter Category': 'Filter Category',
  'Filter Sub-Category (optional)': 'Filter Sub-Category (optional)',
  'Only Me': 'Only Me',
  'System Group(s)': 'System Group(s)',
  'All Users...': 'All Users...',
  'Loading Preview...': 'Loading Preview...',
  'There is an error in your filter': 'There is an error in your filter. Please check your recent edits.',
  'Your filter currently matches 0 systems.': 'Your filter currently matches 0 systems.',
  'Your filter currently matches N systems:': 'Your filter currently matches {n} systems:',
  '(Showing {count} of {total})': '(Showing {count} of {total})',
  'Verify Email Address': 'Verify Email Address',
  'Sending...': 'Sending...',
  Resend: 'Resend',
  'Log out': 'Log out',
  Continue: 'Continue',
  'A verification email has been sent to {email}': 'A verification email has been sent to {email}. Please confirm your email address by clicking the link in that email.',
  'If this email address is incorrect, please {updatePassport}': 'If this email address is incorrect, please {updatePassport} with the correct email address and log in again to continue the verification process.',
  'update your HPE Passport account': 'update your HPE Passport account',
  'It appears your verification link was intended for a different user account': 'It appears your verification link was intended for a different user account.',
  'Your verification link has expired...': 'Your verification link has expired. Please click the Resend button below to send another verification email to {email}.',
  'You are currently logged in as {email}. If this is the email address you are trying to verify...': 'You are currently logged in as {email}. If this is the email address you are trying to verify, click the Resend button to send another verification email. If not, please log out and log back in with the email address to which the verification link was sent.',
  'A new verification email has been sent to {email}. Please confirm your email...': 'A new verification email has been sent to {email}. Please confirm your email address by clicking the link in that email.',
  'A new verification email has been sent to {email}. Please confirm your new email...': 'A new verification email has been sent to {email}. Please confirm your new email address by clicking the link in that email.',
  'The email address associated with your Passport account appears to have changed from...': 'The email address associated with your Passport account appears to have changed from {oldEmail} to {newEmail}.',
  'Thank you! Your email address {email} has been successfully verified.': 'Thank you! Your email address {email} has been successfully verified.',
  'User credentials are now managed by HPE Passport. Please create a Passport account to regain access to HPE InfoSight. Please enroll in HPE Passport using the same email address as your current InfoSight login in order to retain your existing user profile.': 'User credentials are now managed by HPE Passport. Please {link} to regain access to HPE InfoSight. Please enroll in HPE Passport using the same email address as your current InfoSight login in order to retain your existing user profile.',
  'create a Passport account': 'create a Passport account',
  Submit: 'Submit',
};
