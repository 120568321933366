// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

import { PROFILE_LOAD,
  PROFILE_LOAD_SUCCESS,
  PROFILE_LOAD_FAILURE,
  PROFILE_UPDATE,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE } from '../actions/actions';

const initialState = {
  profileData: {},
  saving: false,
  error: null,
};

const handlers = {

  [PROFILE_LOAD]: state => state,

  [PROFILE_LOAD_SUCCESS]: (state, action) => ({
    ...state,
    profileData: { ...action.profileData },
  }),

  [PROFILE_LOAD_FAILURE]: (state, action) => ({
    ...state,
    error: { ...action.error },
  }),

  [PROFILE_UPDATE]: state => ({
    ...state,
    saving: true,
  }),

  [PROFILE_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    saving: false,
    profileData: action.profileData,
  }),

  [PROFILE_UPDATE_FAILURE]: (state, action) => ({
    ...state,
    error: { ...action.error },
  }),
};

export default function profileReducer(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...handler(state, action) };
}
