// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

import RoutePattern from 'route-pattern';
import queryString from 'query-string';
import routesMetadata from '../routes';

/*
 * Fetch the proper name of the nav item selected based on the path
 */
const NavUtils = {

  getRouteIdFromPath(pathname) {
    return Object.keys(routesMetadata).find((key) => {
      // TODO we should eventually replace these usages of RoutePattern with https://reacttraining.com/react-router/web/api/matchPath
      const pattern = RoutePattern.fromString(routesMetadata[key].path);
      return pattern.matches(pathname);
    });
  },

  // Nothing uses the config directly, they always try to access various
  // properties of the config so it is useful to have an empty object
  // returned here instead of null to simplify code using this function
  getRouteConfigFromPath(pathname) {
    const key = NavUtils.getRouteIdFromPath(pathname);
    return (key ? routesMetadata[key] : {});
  },

  getNavItemLabel(pathname) {
    return NavUtils.getRouteConfigFromPath(pathname).label;
  },

  isConfirmEmailLink(location) {
    const pathname = location && location.pathname ? location.pathname : location;
    // TODO we should eventually replace these usages of RoutePattern with https://reacttraining.com/react-router/web/api/matchPath
    const pattern = RoutePattern.fromString(routesMetadata.confirmemail.path);
    return pattern.matches(pathname);
  },

  getQueryParam(location, paramName) {
    if (location && location.search) {
      const parsedQuery = queryString.parse(location.search);
      if (parsedQuery[paramName]) {
        return parsedQuery[paramName];
      } else if (Object.prototype.hasOwnProperty.call(parsedQuery, paramName)) {
        return true;
      }
    }
    return null;
  },
};

export default NavUtils;
