// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import { getTenants } from './api';

export const TENANTS_LOAD = 'TENANTS_LOAD';
export const TENANTS_LOAD_SUCCESS = 'TENANTS_LOAD_SUCCESS';
export const TENANTS_LOAD_FAILURE = 'TENANTS_LOAD_FAILURE';

export function loadTenantsSuccess(tenantsById) {
  return { type: TENANTS_LOAD_SUCCESS, tenantsById };
}

export function loadTenantsFailure(error) {
  return { type: TENANTS_LOAD_FAILURE, error };
}

function processTenants(rawTenants) {
  return rawTenants.members.reduce((accum, tenant) => ({
    ...accum,
    [tenant.tenantId]: { ...tenant },
  }), {});
}

export function loadTenants() {
  return (dispatch) => {
    dispatch({ type: TENANTS_LOAD });
    return getTenants()
      .then(res => dispatch(loadTenantsSuccess(processTenants(res.body))))
      .catch(err => dispatch(loadTenantsFailure(err)));
  };
}
