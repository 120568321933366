// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Box from 'grommet/components/Box';
import FormattedMessage from 'grommet/components/FormattedMessage';
import FormField from 'grommet/components/FormField';
import Select from 'grommet/components/Select';

export class OperatorSelect extends PureComponent {
  constructor() {
    super();
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  onSelectChange(event) {
    const { onOperatorChange } = this.props;
    onOperatorChange(event.option.value);
  }

  render() {
    const { path, disabled, operatorVal } = this.props;
    const operators = ['>', '>=', '=', '<', '<='];

    const operatorOptions = operators.map(operator => ({
      value: operator,
      label: (<span className="filter-builder-bold-option">{operator}</span>),
    }));

    return (
      <Box size="small">
        <FormField htmlFor={`${path}.operator`} label={<FormattedMessage id="Operator" />}>
          <Select
            id={`${path}.operator`}
            name="operator"
            options={operatorOptions}
            value={operatorVal}
            onChange={this.onSelectChange}
            disabled={disabled}
            className="filter-builder-bold-value"
          />
        </FormField>
      </Box>
    );
  }
}

OperatorSelect.propTypes = {
  onOperatorChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  operatorVal: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

OperatorSelect.defaultProps = {
  disabled: false,
};

export default OperatorSelect;
