// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import { postSendVerificationEmail, postConfirmVerifiedEmail } from './api';

export const EMAIL_VERIFY_SEND = 'EMAIL_VERIFY_SEND';
export const EMAIL_VERIFY_SEND_SUCCESS = 'EMAIL_VERIFY_SEND_SUCCESS';
export const EMAIL_VERIFY_SEND_FAILURE = 'EMAIL_VERIFY_SEND_FAILURE';
export const EMAIL_VERIFY_CONFIRM = 'EMAIL_VERIFY_CONFIRM';
export const EMAIL_VERIFY_CONFIRM_SUCCESS = 'EMAIL_VERIFY_CONFIRM_SUCCESS';
export const EMAIL_VERIFY_CONFIRM_FAILURE = 'EMAIL_VERIFY_CONFIRM_FAILURE';

export function emailSendSuccess(sendResponse) {
  return { type: EMAIL_VERIFY_SEND_SUCCESS, sendResponse };
}

export function emailSendFailure(error) {
  return { type: EMAIL_VERIFY_SEND_FAILURE, error };
}

export function emailConfirmSuccess(confirmResponse) {
  return { type: EMAIL_VERIFY_CONFIRM_SUCCESS, confirmResponse };
}

export function emailConfirmFailure(error) {
  return { type: EMAIL_VERIFY_CONFIRM_FAILURE, error };
}

export function sendVerificationEmail(resending) {
  return (dispatch) => {
    dispatch({ type: EMAIL_VERIFY_SEND, resending });
    // res.body.data.sendEmailVerification (boolean) is the relevant piece here
    // but no one reads this value, so it doesn't really matter
    return postSendVerificationEmail()
      .then(res => dispatch(emailSendSuccess(res.body)))
      .catch(err => dispatch(emailSendFailure(err)));
  };
}

export function confirmVerifiedEmail(linkId) {
  return (dispatch) => {
    dispatch({ type: EMAIL_VERIFY_CONFIRM });
    return postConfirmVerifiedEmail(linkId)
      .then((res) => {
        if (res.body && res.body.data && res.body.data.checkEmailVerification) {
          dispatch(emailConfirmSuccess(res.body));
        } else {
          dispatch(emailConfirmFailure(res.body));
        }
      })
      .catch((err) => {
        dispatch(emailConfirmFailure(err));
        dispatch(sendVerificationEmail());
      });
  };
}
