// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import AppUtils from './AppUtils';

const ValueRenderer = {
  renderRaidLevel: value => value.replace('RAID', 'RAID '),

  renderSingleStatusType: (value, intl) => {
    // Most of the status types should have intl strings in statusHelp,
    // e.g. optimization: 'Best Practices', serviceability: 'Supportability'
    const formatted = (intl ? intl.formatMessage({ id: value }) : value);
    if (formatted !== value) return formatted;
    // If we didn't have a string for this status type, just return it capitalized.
    return AppUtils.capitalizeFirstLetter(value);
  },

  uppercase: value => value.toUpperCase(),
};

export default ValueRenderer;
