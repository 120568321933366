// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import { getSystem, postSystemsQuery } from './api';
import { loadSystemsMeta } from './systems';
import AppUtils from '../utils/AppUtils';

export const SYSTEM_DETAILS_LOAD = 'SYSTEM_DETAILS_LOAD';
export const SYSTEM_DETAILS_LOAD_SUCCESS = 'SYSTEM_DETAILS_LOAD_SUCCESS';
export const SYSTEM_DETAILS_LOAD_FAILURE = 'SYSTEM_DETAILS_LOAD_FAILURE';
export const SYSTEM_DETAILS_UNLOAD = 'SYSTEM_DETAILS_UNLOAD';
export const SYSTEM_DETAILS_AGGREGATION_LOAD_SUCCESS = 'SYSTEM_DETAILS_AGGREGATION_LOAD_SUCCESS';
export const SYSTEM_DETAILS_AGGREGATION_LOAD_FAILURE = 'SYSTEM_DETAILS_AGGREGATION_LOAD_FAILURE';

export function loadSystemDetailsSuccess(result, category, systemId) {
  return { type: SYSTEM_DETAILS_LOAD_SUCCESS, result, category, systemId };
}

export function loadSystemDetailsFailure(error, category, systemId) {
  return { type: SYSTEM_DETAILS_LOAD_FAILURE, error, category, systemId };
}

// dynamicallyLoadSystemsPerToken is specified as the dynamicActionCreator for the
// registeredSystems field in storeServSystems and storeOnceSystems config files.
// If there is data for that column ('authorized.tokenCount'), this will be called.
export function dynamicallyLoadSystemsPerToken(systemObj, category, systemId) {
  return (dispatch) => {
    const tokens = AppUtils.getNestedProperty(systemObj, 'authorized.tokens');
    if (!tokens || tokens.length === 0) return Promise.resolve();
    const aggregation = {
      filters: { or: { terms: tokens.map(token => ({
        f: {
          field: 'authorized.tenants',
          operator: '=',
          value: `"${token.tenantId}"`,
        },
      })) } },
      aggregations: {
        systemsPerToken: {
          nested: { path: 'authorized.tokens' },
          aggregations: { tokens: { terms: { field: 'authorized.tokens.tenantId' } } },
        },
      },
    };
    // The SYSTEM_DETAILS_AGGREGATION_LOAD_SUCCESS action can be used to store
    // additional aggregation data on the systemDetails state object.
    return postSystemsQuery(category, aggregation).then(result => dispatch({
      type: SYSTEM_DETAILS_AGGREGATION_LOAD_SUCCESS,
      result: result.body,
      category,
      systemId,
    })).catch(error => dispatch({
      type: SYSTEM_DETAILS_AGGREGATION_LOAD_FAILURE, error, category, systemId,
    }));
  };
}

// loadDynamicSystemDetails calls any dynamicActionCreators that we have specified for columns
// in our config. If no columns with dynamicActionCreators need to be loaded, this is a no-op.
export function loadDynamicSystemDetails(systemObj, category, systemId, config) {
  return (dispatch, getState) => {
    const { session: { roles }, systems: { [category]: { meta } } } = getState();
    const metadata = config(undefined, roles, meta.data).metadata;
    return Promise.all(Object.keys(metadata).reduce((promises, key) => {
      if (metadata[key].dynamicActionCreator) {
        return [
          ...promises,
          dispatch(metadata[key].dynamicActionCreator(systemObj, category, systemId)),
        ];
      }
      return promises;
    }, []));
  };
}

export function loadSystemDetails(category, systemId, config) {
  return (dispatch) => {
    dispatch({ type: SYSTEM_DETAILS_LOAD, category, systemId });
    return Promise.all([
      getSystem(category, systemId),
      dispatch(loadSystemsMeta(category)),
    ]).then((res) => {
      // First we dispatch the system details success action so the page can be rendered,
      // Then we dispatch any dynamic actions we need for further aggregation data.
      dispatch(loadSystemDetailsSuccess(res[0].body, category, systemId));
      return dispatch(loadDynamicSystemDetails(res[0].body, category, systemId, config));
    }).catch(err => dispatch(loadSystemDetailsFailure(err, category, systemId)));
  };
}

export function unloadSystemDetails(category) {
  return { type: SYSTEM_DETAILS_UNLOAD, category };
}
