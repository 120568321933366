// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

export default {
  'Last Updated': 'Last Updated',
  'Wellness Score': 'Wellness Score',
  'Opportunity Score': 'Opportunity Score',
  'HPE Serial Number': 'HPE Serial Number',
  'Company Name': 'Company Name',
  'System Name': 'System Name',
  Model: 'Model',
  'Family Name': 'Family Name',
  Country: 'Country',
  Region: 'Region',
  Location: 'Location',
  'Install Date': 'Install Date',
  'Supported By Partner': 'Supported By Partner',
  'Registered System': 'Registered System',
  'Total Capacity Predicted Full': 'Total Capacity Predicted Full',
  'Capacity Predicted Full': 'Capacity Predicted Full',
  'Adaptive Optimization': 'Adaptive Optimization',
  'SSD Minimum % Life Left': 'SSD Minimum % Life Left',
  'OS Version': 'OS Version',
  'Free Capacity %': 'Free Capacity %',
  Patches: 'Patches',
  'Dedupe Ratio': 'Dedupe Ratio',
  'Overprovisioning Ratio': 'Overprovisioning Ratio',
  CPGs: 'CPGs',
  Nodes: 'Nodes',
  Cages: 'Cages',
  Vluns: 'Vluns',
  'SmartStart Status': 'SmartStart Status',
  'Entitlement Expiration Date': 'Entitlement Expiration Date',
  'Disks State': 'Disks State',
  'Can Contact Customer': 'Can Contact Customer',
  'Total Disks': 'Total Disks',
  Expansions: 'Expansions',
  'Bandwidth Priority (MB/s)': 'Bandwidth Priority (MB/s)',
  'Space Reclamation Enabled': 'Space Reclamation Enabled',
  'Group Mode': 'Group Mode',
  'Performance Score': 'Performance Score',
  'Oversubscription Score': 'Oversubscription Score',
  'Capacity Score': 'Capacity Score',
  'Software Score': 'Software Score',
  'Device Alerts Score': 'Device Alerts Score',
  'Support Score': 'Support Score',
  'Wellness Types': 'Wellness Types',
  Campaign: 'Campaign',
  'Product SKU': 'Product SKU',
  State: 'State',
  City: 'City',
  'Contact Last Name': 'Contact Last Name',
  'Contact First Name': 'Contact First Name',
  'Contact Company': 'Contact Company',
  'Contact Phone Number': 'Contact Phone Number',
  'Contact Email Address': 'Contact Email Address',
  'Contact Fax Number': 'Contact Fax Number',
  'Contact Street': 'Contact Street',
  'Contact City': 'Contact City',
  'Contact State': 'Contact State',
  'Contact Postal Code': 'Contact Postal Code',
  'Contact Country Code': 'Contact Country Code',
  'Site Status': 'Site Status',
  'Partner Email Addresses': 'Partner Email Addresses',
  'System Group': 'System Group',
  'Total FC Capacity Predicted Full': 'Total FC Capacity Predicted Full',
  'Total NL Capacity Predicted Full': 'Total NL Capacity Predicted Full',
  'Total SSD Capacity Predicted Full': 'Total SSD Capacity Predicted Full',
  'SP ID': 'SP ID',
  'SP Version': 'SP Version',
  'SP Patch': 'SP Patch',
  'SP Install Version': 'SP Install Version',
  'SP Model': 'SP Model',
  'FC Free Capacity %': 'FC Free Capacity %',
  'NL Free Capacity %': 'NL Free Capacity %',
  'SSD Free Capacity %': 'SSD Free Capacity %',
  'Free Capacity': 'Free Capacity',
  'FC Free Capacity': 'FC Free Capacity',
  'NL Free Capacity': 'NL Free Capacity',
  'SSD Free Capacity': 'SSD Free Capacity',
  'Total Capacity': 'Total Capacity',
  'FC Total Capacity': 'FC Total Capacity',
  'NL Total Capacity': 'NL Total Capacity',
  'SSD Total Capacity': 'SSD Total Capacity',
  'Total Allocated Capacity': 'Total Allocated Capacity',
  'Allocated System Capacity': 'Allocated System Capacity',
  'Allocated Volumes Capacity': 'Allocated Volumes Capacity',
  'Other Allocated Capacity': 'Other Allocated Capacity',
  'Failed Capacity': 'Failed Capacity',
  'Compaction Ratio': 'Compaction Ratio',
  'Compression Ratio': 'Compression Ratio',
  'FC RAID Levels': 'FC RAID Levels',
  'NL RAID Levels': 'NL RAID Levels',
  'SSD RAID Levels': 'SSD RAID Levels',
  'Offline Nodes': 'Offline Nodes',
  'Node Time Skew': 'Node Time Skew',
  'Average CPU': 'Average CPU',
  'Median CPU': 'Median CPU',
  'Battery Expiry': 'Battery Expiry',
  Domains: 'Domains',
  Disks: 'Disks',
  'Normal Disks': 'Normal Disks',
  'Degraded Disks': 'Degraded Disks',
  'Failed Disks': 'Failed Disks',
  'SSD Disks': 'SSD Disks',
  'Normal SSD Disks': 'Normal SSD Disks',
  'Degraded SSD Disks': 'Degraded SSD Disks',
  'Failed SSD Disks': 'Failed SSD Disks',
  'FC Disks': 'FC Disks',
  'Normal FC Disks': 'Normal FC Disks',
  'Degraded FC Disks': 'Degraded FC Disks',
  'Failed FC Disks': 'Failed FC Disks',
  'NL Disks': 'NL Disks',
  'Normal NL Disks': 'Normal NL Disks',
  'Degraded NL Disks': 'Degraded NL Disks',
  'Failed NL Disks': 'Failed NL Disks',
  'FC Disk Count Balance by Nodes': 'FC Disk Count Balance by Nodes',
  'NL Disk Count Balance by Nodes': 'NL Disk Count Balance by Nodes',
  'SSD Disk Count Balance by Nodes': 'SSD Disk Count Balance by Nodes',
  'FC Used Balance by Nodes': 'FC Used Balance by Nodes',
  'NL Used Balance by Nodes': 'NL Used Balance by Nodes',
  'SSD Used Balance by Nodes': 'SSD Used Balance by Nodes',
  'FC Capacity Balance by Nodes': 'FC Capacity Balance by Nodes',
  'NL Capacity Balance by Nodes': 'NL Capacity Balance by Nodes',
  'SSD Capacity Balance by Nodes': 'SSD Capacity Balance by Nodes',
  'Disk Types': 'Disk Types',
  'Disk Manufacturers': 'Disk Manufacturers',
  'Disk Models': 'Disk Models',
  'Disk Descriptions': 'Disk Descriptions',
  'Active VLUNs': 'Active VLUNs',
  'Entitlement:': 'Entitlement:',
  'Contract Start': 'Contract Start',
  'Contract Expiry': 'Contract Expiry',
  'Warranty Start': 'Warranty Start',
  'Warranty Expiry': 'Warranty Expiry',
  'Entitlement Level': 'Entitlement Level',
  'Entitlement Code': 'Entitlement Code',
  'Entitlement Description': 'Entitlement Description',
  SAID: 'SAID',
  'Total Initiators': 'Total Initiators',
  'FC Initiators': 'FC Initiators',
  'FCoE Initiators': 'FCoE Initiators',
  'iSCSI Initiators': 'iSCSI Initiators',
  'Initiator Count Balance by Nodes': 'Initiator Count Balance by Nodes',
  'Average Total Bandwidth': 'Average Total Bandwidth',
  'Maximum Total Bandwidth': 'Maximum Total Bandwidth',
  'Average IOPS': 'Average IOPS',
  'Maximum IOPS': 'Maximum IOPS',
  'Maximum Total Service Time': 'Maximum Total Service Time',
  'Average Total Service Time': 'Average Total Service Time',
  'Maximum Read Service Time': 'Maximum Read Service Time',
  'Average Read Service Time': 'Average Read Service Time',
  'Maximum Write Service Time': 'Maximum Write Service Time',
  'Average Write Service Time': 'Average Write Service Time',
  'Low Water FC Disks Percent': 'Low Water FC Disks Percent',
  'High Water FC Disks Percent': 'High Water FC Disks Percent',
  'Low Water NL Disks Percent': 'Low Water NL Disks Percent',
  'High Water NL Disks Percent': 'High Water NL Disks Percent',
  'Del Ack %': 'Del Ack %',
  'NL Read Bandwidth': 'NL Read Bandwidth',
  'FC Read Bandwidth': 'FC Read Bandwidth',
  'SSD Read Bandwidth': 'SSD Read Bandwidth',
  'NL Write Bandwidth': 'NL Write Bandwidth',
  'FC Write Bandwidth': 'FC Write Bandwidth',
  'SSD Write Bandwidth': 'SSD Write Bandwidth',
  'NL Read Service Time': 'NL Read Service Time',
  'FC Read Service Time': 'FC Read Service Time',
  'SSD Read Service Time': 'SSD Read Service Time',
  'NL Write Service Time': 'NL Write Service Time',
  'FC Write Service Time': 'FC Write Service Time',
  'SSD Write Service Time': 'SSD Write Service Time',
  'Recent 16 Second Writes': 'Recent 16 Second Writes',
  'New Fatal Alerts': 'New Fatal Alerts',
  'New Critical Alerts': 'New Critical Alerts',
  'New Major Alerts': 'New Major Alerts',
  'New Minor Alerts': 'New Minor Alerts',
  'New Degraded Alerts': 'New Degraded Alerts',
  'New Informational Alerts': 'New Informational Alerts',
  'Recommended OS Version': 'Recommended OS Version',
  'Recommended Critical Patches': 'Recommended Critical Patches',
  'Recommended Conditional Patches': 'Recommended Conditional Patches',
  'Recommended SP Version': 'Recommended SP Version',
  'Recommended SP Patches': 'Recommended SP Patches',
  'Virtual Volumes': 'Virtual Volumes',
  'TPVV Count': 'TPVV Count',
  'Full Count': 'Full Count',
  'TDVV Count': 'TDVV Count',
  'TDVV Versions': 'TDVV Versions',
  'CPVV Count': 'CPVV Count',
  'TPSD Count': 'TPSD Count',
  'Peer Persist Count': 'Peer Persist Count',
  'Snapshot Count': 'Snapshot Count',
  'Total Virtual Size': 'Total Virtual Size',
  'TPVV Size': 'TPVV Size',
  'Full Size': 'Full Size',
  'TDVV Size': 'TDVV Size',
  'CPVV Size': 'CPVV Size',
  'TPSD Size': 'TPSD Size',
  'Peer Persist Size': 'Peer Persist Size',
  'Snapshot Size': 'Snapshot Size',
  'Remote Copy Target Count': 'Remote Copy Target Count',
  'Remote Copy Group Count': 'Remote Copy Group Count',
  'Remote Copy Status': 'Remote Copy Status',
  'File Services Nodes': 'File Services Nodes',
  'Joined to AD': 'Joined to AD',
  'Joined to LDAP': 'Joined to LDAP',
  RFC2307: 'RFC2307',
  NDMP: 'NDMP',
  'Has FP Replication': 'Has FP Replication',
  'Cross Protocol': 'Cross Protocol',
  'Authentication Order': 'Authentication Order',
  'Anti-virus Vendor': 'Anti-virus Vendor',
  FPGs: 'FPGs',
  VFSs: 'VFSs',
  FStores: 'FStores',
  'SMB Shares': 'SMB Shares',
  'NFS Shares': 'NFS Shares',
  'OBJ Shares': 'OBJ Shares',
  Snaps: 'Snaps',
  Quotas: 'Quotas',
  'Licensed Capacity': 'Licensed Capacity',
  'FPG Capacity': 'FPG Capacity',
  'Available FPG Capacity': 'Available FPG Capacity',
  'Used FPG Capacity': 'Used FPG Capacity',
  'System ID': 'System ID',
  'Free Space': 'Free Space',
  'Capacity Formatted': 'Capacity Formatted',
  'Time Zone': 'Time Zone',
  Locale: 'Locale',
  'User Data Stored': 'User Data Stored',
  'Size on Disk': 'Size on Disk',
  'Protocol for Mgmt': 'Protocol for Mgmt',
  'Protocol for Data': 'Protocol for Data',
  'Conn Speed for Mgmt': 'Conn Speed for Mgmt',
  'Conn Speed for Data': 'Conn Speed for Data',
  'Logical Subnets': 'Logical Subnets',
  Node: 'Node',
  Cluster: 'Cluster',
  System: 'System',
  Vendor: 'Vendor',
};
