// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Layer from 'grommet/components/Layer';
import Box from 'grommet/components/Box';
import Heading from 'grommet/components/Heading';
import Section from 'grommet/components/Section';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Button from 'grommet/components/Button';
import {
  sendVerificationEmail,
  confirmVerifiedEmail,
  logout,
  pollForEmailConfirmation,
  stopPollingForEmailConfirmation,
} from '../actions/actions';
import Loading from './Loading';
import ApiErrorToast from './ApiErrorToast';

export class VerifyEmail extends Component {
  constructor() {
    super();
    this.onResendEmail = this.onResendEmail.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.needToSendEmail = this.needToSendEmail.bind(this);
  }

  componentDidMount() {
    const { email, verifiedEmail, match: { params: { linkId } }, dispatch } = this.props;
    if (email) {
      if (linkId && email !== verifiedEmail) {
        // Don't try to use the linkId if we are already verified and clicked the link again
        dispatch(confirmVerifiedEmail(linkId));
      } else if (this.needToSendEmail()) {
        dispatch(sendVerificationEmail());
      }
      dispatch(pollForEmailConfirmation());
    }
  }

  componentWillUnmount() {
    this.props.dispatch(stopPollingForEmailConfirmation());
  }

  onResendEmail() {
    this.props.dispatch(sendVerificationEmail(true));
  }

  onLogout() {
    const { dispatch } = this.props;
    dispatch(logout());
  }

  onContinue() {
    this.props.history.replace('/');
  }

  needToSendEmail() {
    const { email, verifiedEmail, emailVerificationState, sessionEmailVerification } = this.props;
    return email && !sessionEmailVerification &&
      (email !== verifiedEmail || emailVerificationState.confirmError);
  }

  render() {
    const { email, verifiedEmail, emailVerificationState } = this.props;

    if (!email || emailVerificationState.confirming) {
      return <Loading />;
    }

    const updatePassportMessage = (
      /*
        TODO messages with links like this could be confusing for translators,
        who may not realize the fragmented messages are part of the same sentence.
        we should consider making some "string with links" component in the future.
      */
      <FormattedMessage
        id="If this email address is incorrect, please {updatePassport}"
        values={{
          updatePassport: (
            <a href="https://cf.passport.hpe.com/hppcf/changeemail.do?hpappid=hppecf&lang=en&cc=us&applandingpage=https://infosight.hpe.com">
              <FormattedMessage id="update your HPE Passport account" />
            </a>
          ),
        }}
      />
    );

    const expiredLinkMessage = (
      <Box direction="column" pad={{ between: 'medium' }}>
        <FormattedMessage
          id="Your verification link has expired..."
          values={{ email: (<strong>{email}</strong>) }}
        />
        {updatePassportMessage}
      </Box>
    );

    const changedPassportEmailMessage = (
      <Box direction="column" pad={{ between: 'medium' }}>
        <FormattedMessage
          id="The email address associated with your Passport account appears to have changed from..."
          values={{
            oldEmail: (<strong>{verifiedEmail}</strong>),
            newEmail: (<strong>{email}</strong>),
          }}
        />
        <FormattedMessage
          id="A new verification email has been sent to {email}. Please confirm your new email..."
          values={{ email: (<strong>{email}</strong>) }}
        />
        {updatePassportMessage}
      </Box>
    );

    const sentNewEmailMessage = (
      <Box direction="column" pad={{ between: 'medium' }}>
        <FormattedMessage
          id="A new verification email has been sent to {email}. Please confirm your email..."
          values={{ email: (<strong>{email}</strong>) }}
        />
        {updatePassportMessage}
      </Box>
    );

    const sentEmailMessage = (
      <Box direction="column" pad={{ between: 'medium' }}>
        <FormattedMessage
          id="A verification email has been sent to {email}"
          values={{ email: (<strong>{email}</strong>) }}
        />
        {updatePassportMessage}
      </Box>
    );

    const continueMessage = (
      <Box>
        <FormattedMessage
          id="Thank you! Your email address {email} has been successfully verified."
          values={{ email: (<strong>{email}</strong>) }}
        />
      </Box>
    );

    const resendAndLogoutButtons = (
      <Box direction="row" justify="end" pad={{ between: 'small' }}>
        <Button
          label={<FormattedMessage
            id={emailVerificationState.sending ? 'Sending...' : 'Resend'}
          />}
          onClick={emailVerificationState.sending ? null : this.onResendEmail}
          primary
        />
        <Button
          label={<FormattedMessage id="Log out" />}
          onClick={this.onLogout}
        />
      </Box>
    );

    const continueButton = (
      <Box direction="row" justify="end">
        <Button
          label={<FormattedMessage id="Continue" />}
          onClick={this.onContinue}
          primary
        />
      </Box>
    );

    const message = (() => {
      if (verifiedEmail && email === verifiedEmail) return continueMessage;
      if (emailVerificationState.userResent) return sentNewEmailMessage;
      if (emailVerificationState.confirmError) {
        return expiredLinkMessage;
      }
      if (verifiedEmail && email !== verifiedEmail) return changedPassportEmailMessage;
      return sentEmailMessage;
    })();

    const error = emailVerificationState.sendError || emailVerificationState.confirmError;
    // Exclude 400 bad request errors, because that can mean an expired link.
    const non400error = (error && (!error.error || error.error.status !== 400) ? error : null);

    // Layer renders its contents outside the mounted context, so we can't use it for snapshots.
    const LayerComponent = (process.env.NODE_ENV === 'test' ? Box : Layer);

    return (
      <LayerComponent closer={false} flush={false} align="center">
        {non400error ? <ApiErrorToast apiResponse={non400error} /> : null}
        <Box direction="column" pad={{ between: 'none' }}>
          <Section flex={false}>
            <Heading tag="h2" strong align="center">
              <FormattedMessage id="Verify Email Address" />
            </Heading>
          </Section>
          <Section flex={false}>
            {message}
          </Section>
          <Section flex={false}>
            {email !== verifiedEmail ? resendAndLogoutButtons : continueButton}
          </Section>
        </Box>
      </LayerComponent>
    );
  }
}

VerifyEmail.propTypes = {
  email: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object,
  verifiedEmail: PropTypes.string,
  emailVerificationState: PropTypes.object.isRequired,
  sessionEmailVerification: PropTypes.object,
  history: PropTypes.object.isRequired,
};

VerifyEmail.defaultProps = {
  match: {},
  email: null,
  verifiedEmail: null,
  sessionEmailVerification: null,
};

const select = state => ({
  emailVerificationState: state.emailVerification,
  sessionEmailVerification: state.session.emailVerification,
  email: state.session.email,
  verifiedEmail: state.session.verifiedEmail,
});

export default withRouter(connect(select)(VerifyEmail));
