// (C) Copyright 2017 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Box from 'grommet/components/Box';
import Select from 'grommet/components/Select';
import DateTime from 'grommet/components/DateTime';
import Intl from 'grommet/utils/Intl';
import DebouncedTextInputField from './DebouncedTextInputField';
import FilterUtils from '../utils/FilterUtils';
import AppUtils from '../utils/AppUtils';

const nonDigitsRegex = /[^\d]/g;

export class FilterBuilderDateSelector extends Component {
  static getDateQualifierMap(intl) {
    return {
      beforeRelative: {
        value: Intl.getMessage(intl, 'Before (relative)'),
        operator: '<',
        isSpecific: false,
      },
      afterRelative: {
        value: Intl.getMessage(intl, 'After (relative)'),
        operator: '>',
        isSpecific: false,
      },
      beforeSpecific: {
        value: Intl.getMessage(intl, 'Before (specific)'),
        operator: '<',
        isSpecific: true,
      },
      afterSpecific: {
        value: Intl.getMessage(intl, 'After (specific)'),
        operator: '>',
        isSpecific: true,
      },
    };
  }

  static getRelativeUnitsMap(intl) {
    return {
      days: {
        value: Intl.getMessage(intl, 'Days'),
        operator: 'd',
      },
      months: {
        value: Intl.getMessage(intl, 'Months'),
        operator: 'M',
      },
      years: {
        value: Intl.getMessage(intl, 'Years'),
        operator: 'y',
      },
    };
  }

  static getRelativeTimeSpanMap(intl) {
    return {
      ago: {
        value: Intl.getMessage(intl, 'Ago'),
        operator: '-',
      },
      fromNow: {
        value: Intl.getMessage(intl, 'From Now'),
        operator: '+',
      },
    };
  }

  constructor(props) {
    super(props);
    this.onDateQualifierSelection = this.onDateQualifierSelection.bind(this);
    this.onSpecificInput = this.onSpecificInput.bind(this);
    this.onRelativeInput = this.onRelativeInput.bind(this);
    this.onRelativeUnitSelection = this.onRelativeUnitSelection.bind(this);
    this.onRelativeTimeSpanSelection = this.onRelativeTimeSpanSelection.bind(this);
    this.onNewRelativeDateFilter = this.onNewRelativeDateFilter.bind(this);
    this.onNewSpecificDateFilter = this.onNewSpecificDateFilter.bind(this);
  }

  onDateQualifierSelection(selected) {
    const { filterValue, filterOperator } = this.props;
    const parsedFilter = FilterUtils.parseDateFilter(filterOperator, filterValue);
    const selectedDateQualifier = selected.option.key;
    if (selected.option.isSpecific) {
      this.onNewSpecificDateFilter({ ...parsedFilter, selectedDateQualifier });
    } else {
      this.onNewRelativeDateFilter({ ...parsedFilter, selectedDateQualifier });
    }
  }

  onSpecificInput(specificInput) {
    const { filterValue, filterOperator } = this.props;
    const parsedFilter =
      FilterUtils.parseDateFilter(filterOperator, filterValue);
    // Don't allow invalid input to be propagated up to onChange.
    if (FilterUtils.parseSpecificDate(filterOperator, specificInput)) {
      this.onNewSpecificDateFilter({ ...parsedFilter, specificInput });
    }
  }

  onRelativeInput(name, value) {
    let relativeInput = value.replace(nonDigitsRegex, '');
    relativeInput = relativeInput === '' ? '0' : relativeInput;
    // We choose not to update the filter when a user
    // empties the relative text input field, since empty input
    // is invalid and will therefore cause the FilterBuilderRawValue component
    // to be replaced by the FilterBuilderDateSelector. This decision does
    // potentially persist stale input that the user had previously emptied,
    // but we have decided that this decision is the best option because we
    // could not find another convienent solution, and since it is rare that
    // a user would empty the input and not delete the emptied filter branch.
    if (value !== '') {
      const { filterValue, filterOperator } = this.props;
      const parsedFilter = FilterUtils.parseDateFilter(filterOperator, filterValue);
      this.onNewRelativeDateFilter({ ...parsedFilter, relativeInput });
    }
  }

  onRelativeUnitSelection(selected) {
    const { filterValue, filterOperator } = this.props;
    const parsedFilter = FilterUtils.parseDateFilter(filterOperator, filterValue);
    const selectedRelativeUnits = selected.option.key;
    this.onNewRelativeDateFilter({ ...parsedFilter, selectedRelativeUnits });
  }

  onRelativeTimeSpanSelection(selected) {
    const selectedRelativeTimeSpan = selected.option.key;
    const { filterValue, filterOperator } = this.props;
    const parsedFilter = FilterUtils.parseDateFilter(filterOperator, filterValue);
    this.onNewRelativeDateFilter({ ...parsedFilter, selectedRelativeTimeSpan });
  }

  onNewSpecificDateFilter(newFilter) {
    const { onChange, intl } = this.props;
    const { selectedDateQualifier, specificInput } = newFilter;

    const selectedDateQualifierOption =
        FilterBuilderDateSelector.getDateQualifierMap(intl)[selectedDateQualifier];
    const operator = selectedDateQualifierOption.operator;

    onChange(operator, specificInput);
  }

  onNewRelativeDateFilter(newFilter) {
    const { onChange, intl } = this.props;
    const {
      selectedDateQualifier,
      relativeInput,
      selectedRelativeTimeSpan,
      selectedRelativeUnits,
    } = newFilter;

    const selectedDateQualifierOption =
        FilterBuilderDateSelector.getDateQualifierMap(intl)[selectedDateQualifier];
    const selectedRelativeTimeSpanOption =
        FilterBuilderDateSelector.getRelativeTimeSpanMap(intl)[selectedRelativeTimeSpan];
    const selectedRelativeUnitsOption =
        FilterBuilderDateSelector.getRelativeUnitsMap(intl)[selectedRelativeUnits];

    const operator = selectedDateQualifierOption.operator;
    const timespan = selectedRelativeTimeSpanOption.operator;
    const unit = selectedRelativeUnitsOption.operator;

    onChange(operator, `now${timespan}${relativeInput}${unit}`);
  }

  render() {
    const { disabled, filterOperator, filterValue, intl } = this.props;
    const {
      selectedDateQualifier,
      specificInput,
      relativeInput,
      selectedRelativeUnits,
      selectedRelativeTimeSpan,
    } = FilterUtils.parseDateFilter(filterOperator, filterValue);

    const dateQualifierMap = FilterBuilderDateSelector.getDateQualifierMap(intl);
    const selectedDateQualifierOption = dateQualifierMap[selectedDateQualifier];
    const dateQualifierOptions = AppUtils.objectToArrayWithKeys(dateQualifierMap);

    const relativeUnitsMap = FilterBuilderDateSelector.getRelativeUnitsMap(intl);
    const selectedRelativeUnitsOption = relativeUnitsMap[selectedRelativeUnits];
    const relativeUnitsOptions = AppUtils.objectToArrayWithKeys(relativeUnitsMap);

    const relativeTimeSpanMap = FilterBuilderDateSelector.getRelativeTimeSpanMap(intl);
    const selectedRelativeTimeSpanOption = relativeTimeSpanMap[selectedRelativeTimeSpan];
    const relativeTimeSpanOptions = AppUtils.objectToArrayWithKeys(relativeTimeSpanMap);

    const specificDateComponent = (
      <DateTime
        format="YYYY-MM-DD"
        value={specificInput}
        onChange={this.onSpecificInput}
      />
    );

    const relativeDateComponent = (
      <Box direction="row" pad={{ between: 'small' }}>
        <Box size="xsmall">
          <DebouncedTextInputField
            name="relativeDateInput"
            value={relativeInput}
            onChange={this.onRelativeInput}
            disabled={disabled}
          />
        </Box>
        <Box size={{ width: { max: 'small' } }}>
          <Select
            options={relativeUnitsOptions}
            value={selectedRelativeUnitsOption}
            onChange={this.onRelativeUnitSelection}
          />
        </Box>
        <Box size={{ width: { max: 'small' } }}>
          <Select
            options={relativeTimeSpanOptions}
            value={selectedRelativeTimeSpanOption}
            onChange={this.onRelativeTimeSpanSelection}
          />
        </Box>
      </Box>
    );

    const dateComponent = !selectedDateQualifierOption.isSpecific ?
      relativeDateComponent : specificDateComponent;

    return (
      <Box direction="row" pad={{ between: 'small' }}>
        <Box size="small">
          <Select
            options={dateQualifierOptions}
            value={selectedDateQualifierOption}
            onChange={this.onDateQualifierSelection}
          />
        </Box>
        <Box>
          {dateComponent}
        </Box>
      </Box>
    );
  }
}

FilterBuilderDateSelector.propTypes = {
  filterOperator: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  intl: intlShape.isRequired,
};

FilterBuilderDateSelector.defaultProps = {
  disabled: false,
  filterValue: '',
  filterOperator: '',
};

export default injectIntl(FilterBuilderDateSelector);
