// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import { getCurrentLocale, getLocaleData } from 'grommet/utils/Locale';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';

import englishMessages from '../messages/en-US';

let locale = getCurrentLocale();
addLocaleData(en);

let messages;
try {
  messages = require(`../messages/${locale}`).default; // eslint-disable-line global-require, import/no-dynamic-require
} catch (e) {
  messages = englishMessages;
  locale = 'en-US';
}

export default getLocaleData(messages, locale);
