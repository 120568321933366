import messages from './en-US/messages';
import fieldNames from './en-US/fieldNames';
import statusHelp from './en-US/statusHelp';
import termsOfUseHelp from './en-US/termsOfUseHelp';

export default {
  ...messages,
  ...fieldNames,
  ...statusHelp,
  ...termsOfUseHelp,
};
