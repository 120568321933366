// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import Box from 'grommet/components/Box';
import FormattedMessage from 'grommet/components/FormattedMessage';
import Heading from 'grommet/components/Heading';
import charts from '../config/charts';
import ConfigUtils from '../utils/ConfigUtils';
import MDChart from './MDChart';

export class CorrelatedCharts extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = debounce(this.clickHandler.bind(this), 100);
    this.state = { clickedHotSpot: undefined };
  }

  clickHandler(index) {
    if (typeof index === 'number') {
      this.setState({ ...this.state, clickedIndex: index });
    }
  }

  render() {
    const { chartIds, data, small, roles } = this.props;
    if (!data) {
      return (
        <Box pad="large">
          <Heading tag="h2">
            <FormattedMessage id="Loading..." />
          </Heading>
        </Box>
      );
    }
    const chartComponents = chartIds.map((chartId) => {
      const chartConfig = charts[chartId];
      const dataSets = data.error ? null : chartConfig.series.reduce(
        (props, seriesObj) => {
          if (ConfigUtils.doRolesOverlap(roles, seriesObj.roles)) {
            props.push(data[seriesObj.dataProp]);
          }
          return props;
        }, [],
      );
      return (
        <MDChart
          key={chartId}
          chartConfig={chartConfig}
          dataSets={dataSets}
          clickHandler={this.clickHandler}
          clickedIndex={this.state.clickedIndex}
          small={small}
          roles={roles}
        />
      );
    });

    return (
      <div>
        {chartComponents}
      </div>
    );
  }
}

CorrelatedCharts.propTypes = {
  chartIds: PropTypes.array.isRequired,
  data: PropTypes.object,
  small: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
};

CorrelatedCharts.defaultProps = {
  data: null,
};

export default CorrelatedCharts;
