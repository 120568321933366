// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import debounce from 'lodash.debounce';
import Box from 'grommet/components/Box';
import FormField from 'grommet/components/FormField';
import SearchInput from 'grommet/components/SearchInput';
import FormattedMessage from 'grommet/components/FormattedMessage';
import ConfigUtils from '../utils/ConfigUtils';

export class FilterBuilderFieldPicker extends PureComponent {
  constructor(props) {
    const { dataProp } = props;
    super();
    // We keep the current value of the SearchInput in local state so it can update as you type,
    // without having to bubble the onChange call up to redux on every keystroke.
    // The debounced value (updated when the user stops typing) is used immediately to re-render
    // the suggestions and determine what field is selected, so we don't have to wait for
    // a full tree re-render to do these things.
    this.state = { debouncedInputValue: dataProp, currentInputValue: dataProp };
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputSelect = this.onInputSelect.bind(this);
    this.callOnChange = debounce(this.callOnChange.bind(this), 300);
  }

  componentWillReceiveProps(nextProps) {
    // When a new value for this field does come down from redux state, that trumps local state.
    const { dataProp } = nextProps;
    if (dataProp !== this.props.dataProp) {
      this.setState({ debouncedInputValue: dataProp, currentInputValue: dataProp });
    }
  }

  // Called on each keystroke as you type in the SearchInput, calls debounced onChange
  onInputChange(event) {
    const { value } = event.target;
    const { disabled } = this.props;
    if (!disabled) {
      this.setState({ currentInputValue: value });
      this.callOnChange(value);
    }
  }

  // Called when you click on a suggestion, updates everything synchronously
  onInputSelect(event) {
    const { value } = event.suggestion;
    const { disabled } = this.props;
    if (!disabled) {
      this.setState({ debouncedInputValue: value, currentInputValue: value });
      this.callOnChange(value);
      this.callOnChange.flush(); // Don't bother debouncing when we're not typing
    }
  }

  // NOTE: DEBOUNCED so onChange isn't called on every keystroke
  callOnChange(dataProp) {
    const { onChange } = this.props;
    // Update local state so we can show suggestions without waiting for the full tree to re-render
    this.setState({ debouncedInputValue: dataProp });
    // Call onChange to bubble these changes up the filter tree
    onChange(dataProp);
  }

  render() {
    const { id, columns, meta, intl } = this.props;
    const { debouncedInputValue, currentInputValue } = this.state;

    // selectedColumn is used to show the column name in the field instead of the dataProp.
    // Don't consider the column as selected until you stop typing (debounced === current)
    // This way, you can type e.g. status.scoreByCategory... without status.score taking precedence
    let selectedColumn = null;
    if (debouncedInputValue === currentInputValue) {
      selectedColumn = ConfigUtils.getFieldByDataProp(columns, debouncedInputValue);
    }

    const allSuggestions = Object.keys(columns).map((columnId) => {
      const column = columns[columnId];
      return {
        value: column.dataProp,
        label: (
          <Box
            direction="row"
            justify="between"
            pad={{ between: 'small' }}
            className="filter-builder-field-suggestion"
          >
            <FormattedMessage id={column.title} />
            <span className="secondary hidden-unless-hover">({column.dataProp})</span>
          </Box>
        ),
        labelString: (intl ? intl.formatMessage({ id: column.title }) : column.title),
      };
    });

    // Suggest fields if you've typed a substring of the field name, or exactly the dataProp
    const filteredSuggestions = allSuggestions.filter(suggestion =>
      suggestion.value === debouncedInputValue ||
        suggestion.labelString.toLowerCase().indexOf(debouncedInputValue.toLowerCase()) !== -1);

    const currentValueObj = { value: currentInputValue, label: currentInputValue };
    if (selectedColumn) {
      currentValueObj.label = (intl ?
        intl.formatMessage({ id: selectedColumn.title }) :
        selectedColumn.title);
    }

    // Don't show the 'Unknown Field' error if the field is empty, or if you're still typing.
    const unknown = currentInputValue !== '' && currentInputValue === debouncedInputValue &&
        !selectedColumn && !meta.fields[debouncedInputValue];

    return (
      <FormField
        htmlFor={id}
        label={<FormattedMessage id="Field" />}
        error={unknown ? <FormattedMessage id="Unknown Field" /> : undefined}
      >
        <SearchInput
          id={id}
          name="field"
          placeHolder={intl ? intl.formatMessage({ id: 'Find a Field' }) : 'Find a Field'}
          onDOMChange={this.onInputChange}
          onSelect={this.onInputSelect}
          suggestions={filteredSuggestions}
          value={currentValueObj}
        />
      </FormField>
    );
  }
}

FilterBuilderFieldPicker.displayName = 'FilterBuilderFieldPicker';

FilterBuilderFieldPicker.propTypes = {
  id: PropTypes.string.isRequired,
  dataProp: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  columns: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

FilterBuilderFieldPicker.defaultProps = {
  dataProp: '',
  disabled: false,
};

export default injectIntl(FilterBuilderFieldPicker);
