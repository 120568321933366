// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

/*
 * The metadata file containing all the important info about systems view columns
 */
import React from 'react';
import FlagIcon from 'grommet/components/icons/base/Flag';
import ScoreIcon from 'grommet/components/icons/base/Scorecard';

import ConfigUtils from '../utils/ConfigUtils';
import SystemsRenderer from '../utils/SystemsRenderer';
import ValueRenderer from '../utils/ValueRenderer';
import * as actions from '../actions/systemDetails';

export const initialOrder = [
  'wellnessScore',
  'opportunityScore',
  'serialNumber',
  'companyName',
  'systemName',
  'freeCapacity',
];

// These properties will each be applied to one field of the uiMetadata based on the user's roles.
export const roleBasedProps = {
  fatRowPrimary: {
    value: true,
    defaultField: 'companyName',
    roleBased: [{
      roles: ['customer', 'partner'],
      field: 'systemName',
    }],
  },
};

// exported for testing, should never be used directly otherwise
export const uiMetadata = {
  lastUpdated: {
    title: 'Last Updated',
    render: SystemsRenderer.renderDate,
    dataProp: 'updated',
    overviewSubtitle: true,
  },

  wellnessScore: {
    title: 'Wellness Score',
    header: <ScoreIcon colorIndex="#ff8d6d" />,
    render: SystemsRenderer.renderWellnessScoreIcon,
    dataProp: 'status.score',
    relatedData: ['status.level'],
    showInMobile: true,
  },

  wellnessTypes: {
    title: 'Wellness Types',
    dataProp: 'status.statusTypes',
    renderFacetValue: ValueRenderer.renderSingleStatusType,
  },

  opportunityScore: {
    title: 'Opportunity Score',
    header: <FlagIcon />,
    dataProp: 'sales.score',
    relatedData: ['sales.level'],
    render: SystemsRenderer.renderStoreOnceOpportunityScoreIcon,
  },

  serialNumber: {
    title: 'HPE Serial Number',
    dataProp: 'config.serialNumberHP',
    fatRowSecondary: true,

    excludeFromFacets: true,
  },

  // NOTE: companyName is the default recipient of the property fatRowPrimary: true.
  // See roleBasedProps at the top
  companyName: {
    title: 'Company Name',
    dataProp: 'custdata.companyName',
    excludeFromFacets: true,
  },

  // NOTE: systemName is a potential recipient of the property fatRowPrimary: true.
  // See roleBasedProps at the top
  systemName: {
    title: 'System Name',
    dataProp: 'config.systemName',
    excludeFromFacets: true,
  },

  systemID: {
    title: 'System ID',
    dataProp: 'systemId',
    excludeFromFacets: true,
  },

  freeCapacity: {
    title: 'Free Capacity %',
    dataProp: 'config.freeSpacePercent',
    render: SystemsRenderer.renderStoreOnceFreeCapacityPerct,

  },

  freeSpaceTB: {
    title: 'Free Space',
    dataProp: 'config.capacity.freeSpaceTB',
  },

  capacityFormatted: {
    title: 'Capacity Formatted',
    dataProp: 'config.capacity.capacityFormattedTB',
  },

  model: {
    title: 'Model',
    dataProp: 'config.modelNumber',

  },

  osVersion: {
    title: 'OS Version',
    dataProp: 'config.majorReleaseLevel',
  },

  canContactCustomer: {
    title: 'Can Contact Customer',
    dataProp: 'custdata.canContactCustomer',
  },

  // Need to implement Accordion when Supported By Partner is 'YES' to display the email Ids
  supportedByPartner: {
    title: 'Supported By Partner',
    dataProp: 'authorized.hasPartners',
    render: SystemsRenderer.renderBooleanAsYesNo,
  },

  partnersEmailList: {
    title: 'Partner Email Addresses',
    dataProp: 'authorized.partnerEmailIds',
  },

  registeredSystems: {
    title: 'Registered System',
    dataProp: 'authorized.tokenCount',
    relatedData: ['authorized.tokens'],
    render: SystemsRenderer.renderSystemsPerToken,
    dynamicActionCreator: actions.dynamicallyLoadSystemsPerToken,
  },

  tenants: {
    title: 'System Group',
    dataProp: 'authorized.tenants',
    excludeFromFacets: true,
  },

  region: {
    title: 'Region',
    dataProp: 'custdata.location.region',
    relatedData: ['custdata.location.country', 'custdata.location.region'],
    render: SystemsRenderer.renderStoreOnceLocation,
  },

  country: {
    title: 'Country',
    dataProp: 'custdata.location.country',
  },

  timeZone: {
    title: 'Time Zone',
    dataProp: 'config.deviceDetails.localeDetails.timezone',
  },

  locale: {
    title: 'Locale',
    dataProp: 'config.deviceDetails.localeDetails.language',
  },

  // TODO - Write render function to do node formatting for details pane.
  nodes: {
    title: 'Nodes',
    dataProp: 'hardware.serverCount',
  },

  totalDisks: {
    title: 'Total Disks',
    dataProp: 'hardware.deviceDriveCount',
  },

  expansions: {
    title: 'Expansions',
    dataProp: 'config.licenses.capacityLicenseCount',
  },

  fullCapacityPredictedDate: {
    title: 'Total Capacity Predicted Full',
    dataProp: 'config.freeSpacePrediction',
  },

  userDataStored: {
    title: 'User Data Stored',
    dataProp: 'config.capacity.userDataStoredTB',
  },

  sizeOnDisk: {
    title: 'Size on Disk',
    dataProp: 'config.capacity.sizeOnDiskTB',
  },

  svcAgreementID: {
    title: 'SAID',
    dataProp: 'entitlement.svcAgreementID',
    excludeFromFacets: true,
  },

  entitlementEndDate: {
    title: 'Entitlement Expiration Date',
    dataProp: 'entitlement.entitlementEndDate',
  },

  contractStartDate: {
    title: 'Contract Start',
    dataProp: 'entitlement.contractStartDate',
  },

  contractEndDate: {
    title: 'Contract Expiry',
    dataProp: 'entitlement.contractEndDate',
  },

  warrantyStartDate: {
    title: 'Warranty Start',
    dataProp: 'entitlement.warrantyStartDate',
  },

  warrantyEndDate: {
    title: 'Warranty Expiry',
    dataProp: 'entitlement.warrantyEndDate',
  },

  topOfferPriority: {
    title: 'Entitlement Level',
    dataProp: 'entitlement.topOfferPriority',
  },

  topOfferCode: {
    title: 'Entitlement Code',
    dataProp: 'entitlement.topOfferCode',
  },

  topOfferDescription: {
    title: 'Entitlement Description',
    dataProp: 'entitlement.topOfferDescription',
  },

  contactLastName: {
    title: 'Contact Last Name',
    dataProp: 'custdata.contactInfo.lastName',
    excludeFromFacets: true,
  },

  contactFirstName: {
    title: 'Contact First Name',
    dataProp: 'custdata.contactInfo.firstName',
    excludeFromFacets: true,
  },

  contactPhone: {
    title: 'Contact Phone Number',
    dataProp: 'custdata.contactInfo.phone',
    excludeFromFacets: true,
  },

  contactEmail: {
    title: 'Contact Email Address',
    dataProp: 'custdata.contactInfo.email',
    excludeFromFacets: true,
  },

  contactFax: {
    title: 'Contact Fax Number',
    dataProp: 'custdata.contactInfo.fax',
    excludeFromFacets: true,
  },

  contactStreet: {
    title: 'Contact Street',
    dataProp: 'custdata.location.address',
    excludeFromFacets: true,
  },

  contactCity: {
    title: 'Contact City',
    dataProp: 'custdata.location.city',
    excludeFromFacets: true,
  },

  contactState: {
    title: 'Contact State',
    dataProp: 'custdata.location.state',
    excludeFromFacets: true,
  },

  campaign: {
    title: 'Campaign',
    dataProp: 'sales.campaign',
  },

  dedupeRatio: {
    title: 'Dedupe Ratio',
    dataProp: 'config.dedupeRatio',
  },

  nwProtoForMgmt: {
    title: 'Protocol for Mgmt',
    dataProp: 'network.protoForMgmt',
  },

  nwProtoForData: {
    title: 'Protocol for Data',
    dataProp: 'network.protoForData',
  },

  nwSpeedForMgmt: {
    title: 'Conn Speed for Mgmt',
    dataProp: 'network.speedForMgmt',
  },

  nwSpeedForData: {
    title: 'Conn Speed for Data',
    dataProp: 'network.speedForData',
  },

  logicalSubnetNWCount: {
    title: 'Logical Subnets',
    dataProp: 'network.logicalSubnetNWCount',
  },

  keyManagerNode: {
    title: 'Node',
    dataProp: 'config.deviceDetails.deviceKeyManager.node',
  },

  keyManagerCluster: {
    title: 'Cluster',
    dataProp: 'config.deviceDetails.deviceKeyManager.cluster',
  },

  keyManagerSystems: {
    title: 'System',
    dataProp: 'config.deviceDetails.deviceKeyManager.system',
  },

  keyManagerVendor: {
    title: 'Vendor',
    dataProp: 'config.deviceDetails.deviceKeyManager.vendor',
  },

};

function storeOnceSystems(order = [], roles, meta) {
  const restrictedOrder = ConfigUtils.roleRestrictOrder(order, roles, uiMetadata, meta);
  const processedMetadata = ConfigUtils.processMetadata(roles, uiMetadata, meta, roleBasedProps);
  return {
    order: restrictedOrder,
    metadata: processedMetadata,
  };
}

export default storeOnceSystems;
