// (C) Copyright 2014-2017 Hewlett Packard Enterprise Development LP

import { EMAIL_VERIFY_SEND,
  EMAIL_VERIFY_SEND_SUCCESS,
  EMAIL_VERIFY_SEND_FAILURE,
  EMAIL_VERIFY_CONFIRM,
  EMAIL_VERIFY_CONFIRM_SUCCESS,
  EMAIL_VERIFY_CONFIRM_FAILURE } from '../actions/actions';

const initialState = {
  sending: false,
  confirming: false,
  userResent: false,
  sendResponse: {},
  confirmResponse: {},
  sendError: null,
  confirmError: null,
};

const handlers = {

  [EMAIL_VERIFY_SEND]: (state, action) => ({
    ...state,
    sending: true,
    sendError: null,
    userResent: action.resending,
  }),

  [EMAIL_VERIFY_SEND_SUCCESS]: (state, action) => ({
    ...state,
    sendResponse: { ...action.sendResponse },
    sending: false,
  }),

  [EMAIL_VERIFY_SEND_FAILURE]: (state, action) => ({
    ...state,
    sendError: { ...action.error },
    sending: false,
  }),

  [EMAIL_VERIFY_CONFIRM]: state => ({
    ...state,
    confirming: true,
    confirmError: null,
  }),

  [EMAIL_VERIFY_CONFIRM_SUCCESS]: (state, action) => ({
    ...state,
    confirmResponse: { ...action.confirmResponse },
    confirming: false,
  }),

  [EMAIL_VERIFY_CONFIRM_FAILURE]: (state, action) => ({
    ...state,
    confirmError: { ...action.error },
    confirming: false,
  }),

};

export default function verifyEmailReducer(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...handler(state, action) };
}
