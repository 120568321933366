// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import Login from './components/Login';
import VerifyEmail from './components/VerifyEmail';
import StoreServPerformance from './components/StoreServPerformance';
import StoreServFilterBuilder from './components/StoreServFilterBuilder';
import StoreOnceFilterBuilder from './components/StoreOnceFilterBuilder';
import AcceptTermsOfUse from './components/AcceptTermsOfUse';
import ViewTermsOfUse from './components/ViewTermsOfUse';

/*
  To make individual page as standalone, add standalone: true in route metadata.
*/
export default {
  loginPage: {
    path: '/login',
    component: Login,
  },
  verifyemail: {
    path: '/verifyemail',
    component: VerifyEmail,
  },
  confirmemail: {
    path: '/confirmemail/:linkId',
    component: VerifyEmail,
  },
  storeservPerformance: {
    path: '/storeserv/:id/performance',
    component: StoreServPerformance,
    label: 'StoreServ Performance',
    standalone: true,
  },
  storeservFilterNew: {
    path: '/storeserv/filter/new',
    component: StoreServFilterBuilder,
    standalone: true,
  },
  storeonceFilterNew: {
    path: '/storeonce/filter/new',
    component: StoreOnceFilterBuilder,
    standalone: true,
  },
  storeservFilterEdit: {
    path: '/storeserv/filter/edit/:id',
    component: StoreServFilterBuilder,
    standalone: true,
  },
  storeonceFilterEdit: {
    path: '/storeonce/filter/edit/:id',
    component: StoreOnceFilterBuilder,
    standalone: true,
  },
  viewTermsOfUse: {
    path: '/termsofuse/view',
    component: ViewTermsOfUse,
    standalone: true,
  },
  acceptTermsOfUse: {
    path: '/termsofuse',
    component: AcceptTermsOfUse,
  },
};
