// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import history from '../history';
import { getFilter, postCreateFilter, putSaveFilter, delFilter } from './api';
import { updateProfile } from './profile';
import AppUtils from '../utils/AppUtils';

export const FILTER_LOAD = 'FILTER_LOAD';
export const FILTER_LOAD_SUCCESS = 'FILTER_LOAD_SUCCESS';
export const FILTER_LOAD_FAILURE = 'FILTER_LOAD_FAILURE';
export const FILTER_CREATE = 'FILTER_CREATE';
export const FILTER_CREATE_SUCCESS = 'FILTER_CREATE_SUCCESS';
export const FILTER_CREATE_FAILURE = 'FILTER_CREATE_FAILURE';
export const FILTER_EDIT = 'FILTER_EDIT';
export const FILTER_SAVE = 'FILTER_SAVE';
export const FILTER_SAVE_SUCCESS = 'FILTER_SAVE_SUCCESS';
export const FILTER_SAVE_FAILURE = 'FILTER_SAVE_FAILURE';
export const FILTER_DELETE = 'FILTER_DELETE';
export const FILTER_DELETE_SUCCESS = 'FILTER_DELETE_SUCCESS';
export const FILTER_DELETE_FAILURE = 'FILTER_DELETE_FAILURE';
export const FILTERS_UNLOAD = 'FILTERS_UNLOAD';
export const FILTER_CLEAR_ERRORS = 'FILTER_CLEAR_ERRORS';

export const defaultFilterObject = {
  report: {
    reportName: '',
    reportFilter: {
      and: {
        terms: [],
      },
    },
    reportDescription: '',
    promoted: false,
    statusType: null,
    subStatusType: null,
    version: 2,
  },
  isPublic: false,
  tenants: [],
  roles: null,
  isAuthorised: false,
  isDraft: false, // This is not actually used (yet?)
};

export function serializeFilterData(reduxFilterData) {
  return {
    ...reduxFilterData,
    report: {
      ...reduxFilterData.report,
      reportFilter: JSON.stringify(reduxFilterData.report.reportFilter),
    },
  };
}

export function deserializeFilterData(apiFilterData) {
  return {
    ...apiFilterData,
    report: {
      ...apiFilterData.report,
      reportFilter: JSON.parse(apiFilterData.report.reportFilter),
    },
  };
}

// Adds the given filter ID to the Piano recent list, if it's not in the Piano favorites list.
// NOTE: If/when we introduce concepts of "recent" and "favorite" filters in grommet,
//       we probably don't want to store them under these legacy profile properties.
export function addFilterToLegacyRecent(category, filterId) {
  const recentKey = `${AppUtils.getLegacyPianoCategory(category)}RecentFilters`;
  const favoritesKey = `${AppUtils.getLegacyPianoCategory(category)}FavoriteFilters`;
  return updateProfile((oldProfile) => {
    const oldRecentFilters = oldProfile[recentKey] || [];
    const oldFavoriteFilters = oldProfile[favoritesKey] || [];
    // To be consistent with Piano, don't add existing favorites to recent.
    if (oldFavoriteFilters.includes(filterId)) return oldProfile;
    const otherRecentFilters = oldRecentFilters.filter(id => id !== filterId);
    return {
      ...oldProfile,
      [recentKey]: [filterId, ...otherRecentFilters].slice(0, 5),
    };
  });
}

// Adds the given filter ID to the Piano favorites list, and removes it from the Piano recent list.
// NOTE: If/when we introduce concepts of "recent" and "favorite" filters in grommet,
//       we probably don't want to store them under these legacy profile properties.
export function addFilterToLegacyFavorites(category, filterId) {
  const recentKey = `${AppUtils.getLegacyPianoCategory(category)}RecentFilters`;
  const favoritesKey = `${AppUtils.getLegacyPianoCategory(category)}FavoriteFilters`;
  return updateProfile((oldProfile) => {
    const oldRecentFilters = oldProfile[recentKey] || [];
    const oldFavoriteFilters = oldProfile[favoritesKey] || [];
    const otherRecentFilters = oldRecentFilters.filter(id => id !== filterId);
    const otherFavoriteFilters = oldFavoriteFilters.filter(id => id !== filterId);
    return {
      ...oldProfile,
      [recentKey]: otherRecentFilters, // To be consistent with Piano, remove favorites from recent
      [favoritesKey]: [...otherFavoriteFilters, filterId],
    };
  });
}

export function loadFilterSuccess(category, filterId, filterData) {
  return { type: FILTER_LOAD_SUCCESS, category, filterId, filterData };
}

export function loadFilterFailure(category, filterId, error) {
  return { type: FILTER_LOAD_FAILURE, category, filterId, error };
}

export function loadFilter(category, filterId) {
  return (dispatch) => {
    dispatch({ type: FILTER_LOAD, category, filterId });
    return getFilter(category, filterId)
      .then((res) => {
        const reduxFilterData = deserializeFilterData(res.body.reportData);
        return dispatch(loadFilterSuccess(category, filterId, reduxFilterData));
      })
      .catch(err => dispatch(loadFilterFailure(category, filterId, err)));
  };
}

export function createFilterSuccess(category, filterId, filterData) {
  return { type: FILTER_CREATE_SUCCESS, category, filterId, filterData };
}

export function createFilterFailure(category, error) {
  return { type: FILTER_CREATE_FAILURE, category, error };
}

export function createFilter(category, filterData) {
  return (dispatch) => {
    dispatch({ type: FILTER_CREATE, category, filterData });
    const apiFilterData = serializeFilterData(filterData);
    return postCreateFilter(category, apiFilterData)
      .then((res) => {
        const newFilterId = res.body.id;
        const reduxFilterData = deserializeFilterData(res.body.reportData);
        return Promise.all([
          dispatch(addFilterToLegacyFavorites(category, newFilterId)),
          dispatch(createFilterSuccess(category, newFilterId, reduxFilterData)),
        ]).then(() => history.push(`/${category}/filter/edit/${newFilterId}`));
      })
      .catch(err => dispatch(createFilterFailure(category, err)));
  };
}

export function createDefaultFilter(category, reportName, reportDescription) {
  const filterData = {
    ...defaultFilterObject,
    report: {
      ...defaultFilterObject.report,
      reportName,
      reportDescription,
    },
  };
  return createFilter(category, filterData);
}

export function editFilter(category, filterId, filterData) {
  return { type: FILTER_EDIT, category, filterId, filterData };
}

export function saveFilterSuccess(category, filterId, filterData) {
  return { type: FILTER_SAVE_SUCCESS, category, filterId, filterData };
}

export function saveFilterFailure(category, filterId, error) {
  return { type: FILTER_SAVE_FAILURE, category, filterId, error };
}

export function saveFilter(category, filterId, filterData) {
  return (dispatch) => {
    dispatch({ type: FILTER_SAVE, category, filterId, filterData });
    const apiFilterData = serializeFilterData(filterData);
    return putSaveFilter(category, filterId, apiFilterData)
      .then((res) => {
        const reduxFilterData = deserializeFilterData(res.body.reportData);
        return Promise.all([
          dispatch(addFilterToLegacyRecent(category, filterId)),
          dispatch(saveFilterSuccess(category, filterId, reduxFilterData)),
        ]);
      })
      .catch(err => dispatch(saveFilterFailure(category, filterId, err)));
  };
}

export function deleteFilterSuccess(category, filterId) {
  return { type: FILTER_DELETE_SUCCESS, category, filterId };
}

export function deleteFilterFailure(category, filterId, error) {
  return { type: FILTER_DELETE_FAILURE, category, filterId, error };
}

export function deleteFilter(category, filterId) {
  return (dispatch) => {
    dispatch({ type: FILTER_DELETE, category, filterId });
    return delFilter(category, filterId)
      .then(() => dispatch(deleteFilterSuccess(category, filterId)))
      .catch(err => dispatch(deleteFilterFailure(category, filterId, err)));
  };
}

export function unloadFilters(category) {
  return { type: FILTERS_UNLOAD, category };
}

export function clearErrors(category, filterId) {
  return { type: FILTER_CLEAR_ERRORS, category, filterId };
}
