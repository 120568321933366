// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import { postAcceptTermsOfUse } from './api';
import { termsOfUseVersion as requiredTermsOfUseVersion } from '../components/BaseTermsOfUse';

export const TOU_UPDATE = 'TOU_UPDATE';
export const TOU_UPDATE_SUCCESS = 'TOU_UPDATE_SUCCESS';
export const TOU_UPDATE_FAILURE = 'TOU_UPDATE_FAILURE';

export function acceptTermsOfUse() {
  return (dispatch) => {
    dispatch({ type: TOU_UPDATE });
    return postAcceptTermsOfUse().then(res => dispatch({
      type: TOU_UPDATE_SUCCESS,
      termsOfUseVersion: res.body.data && res.body.data.acceptEula ? requiredTermsOfUseVersion : -1,
    })).catch(error => dispatch({ type: TOU_UPDATE_FAILURE, error }));
  };
}
