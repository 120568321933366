// (C) Copyright 2017 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import Toast from 'grommet/components/Toast';
import FormattedMessage from 'grommet/components/FormattedMessage';

export const ApiErrorToast = (props) => {
  const { apiResponse, onClose } = props;

  let status = null;
  let header = null;
  let message = null;

  if (apiResponse.error && apiResponse.error.status === 423) {
    status = 'warning';
    header = (<FormattedMessage id="User data cannot be saved at this time." />);
    message = (<FormattedMessage id="We are currently backing up our database. Please try to save your data again after about 20 minutes." />);
  } else {
    status = 'critical';
    header = (<FormattedMessage id="There was an error in completing your request. Please try again." />);
    message = (apiResponse.error && apiResponse.error.message ? apiResponse.error.message : null);
  }

  return (
    <Toast status={status} onClose={onClose}>
      <strong>{header}</strong>
      {message ? (<span><br />{message}</span>) : null}
    </Toast>
  );
};

ApiErrorToast.displayName = 'ApiErrorToast';

ApiErrorToast.propTypes = {
  apiResponse: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

ApiErrorToast.defaultProps = {
  onClose: null,
};

export default ApiErrorToast;
